<template>
  <el-dialog
    ref="brickyViewerDialog"
    :title="title"
    :visible.sync="config.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="!isDesktop"
    :show-close="false"
    :top="marginTop"
    custom-class="pop_page large headless brickyviewer"
  >
    <div class="header" style="background:none;" :class="{'padding-top12 padding-left4 padding-right8': isDesktop, 'padding-top8': !isDesktop}">
      <div v-if="data.type !== 'workViewer' && data.canback" class="left">
        <el-button class="tap-btn" @click="back()">
          <i class="iconfont my-back size24" />
        </el-button>
      </div>
      <div class="right">
        <el-button class="tap-btn" @click="close()">
          <i class="iconfont my-close size24" />
        </el-button>
      </div>
    </div>
    <div
      v-loading="false"
      element-loading-text="AI渲染中，请耐心等待..."
      shadow="never"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      class="previewArea"
      :style="previewAreaStyle"
      @wheel="(e) => opts.d3 ? {} : onWheel(e)"
      @dblclick="(e) => (isDesktop && !opts.d3) ? doubleTap(e) : {}"
    >
      <vue-draggable-resizable
        v-show="!opts.d3"
        :x="opts.x"
        :y="opts.y"
        :z="0"
        :w="1"
        :h="1"
        :parent="false"
        :draggable="isDesktop"
        :resizable="false"
        :disable-user-select="true"
        @dragstop="onDragStop"
      >
        <img
          id="image"
          ref="image"
          v-finger:multipoint-start="multipointStart"
          v-finger:multipoint-end="multipointEnd"
          v-finger:pinch="pinch"
          v-finger:rotate="rotate"
          v-finger:press-move="pressMove"
          v-finger:double-tap="doubleTap"
          :src="blankImage"
        >
      </vue-draggable-resizable>
      <voxel-viewer
        v-if="opts.d3 && config.show"
        ref="voxelViewer"
        :state="state"
        :width="width"
        :height="height"
        :fixpos="0"
        @receive="(event) => emit('handle', event)"
      />
    </div>
    <div v-if="loadPreviewNums >= maxPreviewNums && data.type !== 'workViewer'" style="position: absolute;text-align: center;z-index: 99;" :style="{'top': isDesktop ? '15px' : '4px', height: '50px', 'left': '60px', width: ' calc(100% - 120px)'}">
      <el-row class="margin-bottom4 margin-top4" style="overflow-x: auto;">
        <!-- <el-tooltip v-model="tooltipState.colorfy" :manual="true" :disabled="!tooltipState.colorfy" class="item" effect="dark" placement="bottom">
          <div slot="content"><div @click="closeToolTip('colorfy')">选择配色</div></div> -->
        <el-button-group>
          <el-button v-show="data.type === 'workViewer'" size="small" class="opacity-bg" style="height:32px;" :type="!opts.colorfyId ? 'light' : ''" @click="setBrickModel('', true, true)">多彩</el-button>
          <el-button v-for="(item, k) in colorfyDt" v-show="!(data.type === 'workViewer' && k === 'colors')" :key="k" size="small" class="opacity-bg" style="height:32px;" :type="opts.colorfyId === k ? 'light' : ''" @click="setBrickModel(k, true, true)">{{ item.name }}</el-button>
        </el-button-group>
        <!-- </el-tooltip> -->
      </el-row>
    </div>
    <div v-if="loadPreviewNums >= maxPreviewNums" style="position: absolute;text-align: center;" :style="{'top': '60px', height: '50px', 'left': '60px', width: ' calc(100% - 120px)'}">
      <el-button v-if="canPlay && !playing" :disabled="playing" class="box32 opacity-bg padding4 margin-left4" @click="() => pre()">
        <i class="iconfont my-pre size20" title="往后" />
      </el-button>
      <el-button v-if="canPlay && !playing" :disabled="playing" class="box32 opacity-bg padding4 margin-left4" @click="() => next()">
        <i class="iconfont my-next size20" title="往前" />
      </el-button>
    </div>
    <div v-if="loadPreviewNums >= maxPreviewNums" class="right-top" :style="{'right': isDesktop ? '8px' : ''}">
      <el-dropdown v-show="data.type !== 'workViewer' && opts.ratioid === '1-1'" trigger="click" placement="bottom" @command="setBricksize">
        <!-- <el-tooltip v-model="tooltipState.bricksize" :manual="true" :disabled="!(tooltipState.bricksize && data.type !== 'workViewer' && opts.ratioid === '1-1')" class="item" effect="dark" placement="left">
          <div slot="content"><div @click="closeToolTip('bricksize')">选择画面大小</div></div> -->
        <el-button class="iconfont my-size tap-btn opacity-bg margin-bottom12" :disabled="opts.paletteId === 'originImage' || showOriginImage" title="画面大小" />
        <!-- </el-tooltip> -->
        <el-dropdown-menu slot="dropdown" class="my-dropdown">
          <el-dropdown-item v-for="(sizeOpt, i) in brickSizeOpts" v-show="showSizeOpt(sizeOpt.cols, sizeOpt.rows)" :key="i" :title="sizeOpt.cols + 'x' + sizeOpt.rows" :command="sizeOpt.cols + '-' + sizeOpt.rows"> {{ sizeOpt.cols + 'x' + sizeOpt.rows }} <span class="float-right align-right" :class="opts.brickSizeId === sizeOpt.cols + '-' + sizeOpt.rows ? 'iconfont my-check' : ''" /></el-dropdown-item>
          <el-dropdown-item v-for="(sizeOpt, i) in additionalBrickSizeOpts" :key="'a_' + i" :title="sizeOpt.cols + 'x' + sizeOpt.rows" :command="sizeOpt.cols + '-' + sizeOpt.rows"> {{ sizeOpt.cols + 'x' + sizeOpt.rows }} <span class="float-right align-right" :class="opts.brickSizeId === sizeOpt.cols + '-' + sizeOpt.rows ? 'iconfont my-check' : ''" /></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown v-if="false" v-show="data.type === 'workViewer'" trigger="click" placement="bottom" @command="setBrickBg">
        <el-button class="iconfont my-background tap-btn opacity-bg margin-bottom12" :disabled="opts.paletteId === 'originImage' || showOriginImage" title="设置背景" />
        <el-dropdown-menu slot="dropdown" class="my-dropdown">
          <el-dropdown-item title="默认" command="">默认 <span class="float-right align-right" :class="!opts.bgId ? 'iconfont my-check' : ''" /></el-dropdown-item>
          <el-dropdown-item v-for="(item, k) in bgDt" :key="k" :title="item.name" :command="k"> {{ item.name }} <span class="float-right align-right" :class="opts.bgId === k ? 'iconfont my-check' : ''" /></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown v-if="false" trigger="click" placement="bottom" @command="setBrickFrame">
        <el-button class="iconfont my-border tap-btn opacity-bg margin-bottom12" :disabled="opts.paletteId === 'originImage' || showOriginImage" title="边框/底板" />
        <el-dropdown-menu slot="dropdown" class="my-dropdown">
          <el-dropdown-item v-for="(item, k) in frameDt" v-show="(k.substr(-4, 4) !== 'Easy' || (k.substr(-4, 4) === 'Easy' && showEasy))" :key="k" :title="item.name" :command="k"> {{ item.name }} <span class="float-right align-right" :class="opts.frameId === k ? 'iconfont my-check' : ''" /></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-tooltip v-if="loginUserId > 0 && loginUserId < 10000" v-model="tooltipState.filter" :manual="true" :disabled="!tooltipState.filter" class="item" effect="dark" placement="left">
        <div slot="content"><div @click="closeToolTip('filter')">设置色相/饱和度</div></div> -->
      <el-button class="iconfont my-colorfilter tap-btn opacity-bg margin-bottom12" :disabled="opts.paletteId === 'originImage' || showOriginImage" title="色相/饱和度" @click="openColorFilter()" />
      <!-- </el-tooltip> -->
      <el-popover v-model="mngColorShow" placement="bottom" offset="4" popper-class="padding4" width="260" trigger="click">
        <div class="my-title-bar padding-left8 padding-bottom4" style="border-bottom: 1px solid var(--color-22);">
          对象颜色 <span v-if="colorsCount">{{ getBrickCount() }}</span>
          <el-button :type="opts.colorOrder === 'desc' ? 'light' : ''" class="iconfont my-sort-desc padding4 float-right" title="倒序" @click="toggleColorOrder" />
          <el-button class="iconfont my-denoise padding4 margin-right4 float-right" :disabled="opts.paletteId === 'originImage' || showOriginImage" title="降噪" @click="openDenoise()" />
        </div>
        <div class="my-colors">
          <div :style="{ width: '100%', 'max-height': '210px', 'overflow-x': 'hidden', 'overflow-y': 'auto'}">
            <el-row v-for="(color, idx) in colors" :key="'in_' + idx">
              <el-col :span="3"><div class="box24 border-33 round align-center" :title="'#' + color[2]" :style="{ background: color[0], 'line-height': '24px' }" /></el-col>
              <el-col :span="4" class="padding-top8" :title="'#' + color[2]" :class="{ 'red': opts.limitColorNums && opts.limitColorNums < color[1] }">#{{ color[2] }}</el-col>
              <el-col :span="5" class="padding-top8" :title="'数量' + (opts.limitColorNums && opts.limitColorNums < color[1] ? '大于' + opts.limitColorNums : '')" :class="{ 'red': opts.limitColorNums && opts.limitColorNums < color[1] }">x{{ color[1] }}</el-col>
              <el-col :span="4" title="更换" class="align-right">
                <el-button class="iconfont padding4 margin0 my-change" @click="(event) => toggleColorPicker(event, 'bricky-change-color', [color[0].toUpperCase()])" />
              </el-col>
              <el-col :span="4" title="隐藏" class="align-right">
                <el-button class="iconfont padding4 margin0" :class="{'my-eye-close': opts.hideColors.indexOf(color[0]) >= 0, 'my-eye-open': opts.hideColors.indexOf(color[0]) < 0 }" :title="opts.hideColors.indexOf(color[0]) >= 0 ? '显示' : '隐藏'" @click="hideObjColor(color[0])" />
              </el-col>
              <el-col :span="4" title="去除" class="align-right">
                <el-button :disabled="colorsCount <= 2" class="iconfont my-delete padding4 margin0" title="删除" @click="deleteObjColor(color[0])" />
              </el-col>
            </el-row>
          </div>
        </div>
        <el-button slot="reference" class="iconfont my-color-off tap-btn opacity-bg margin-bottom12" :disabled="opts.paletteId === 'originImage' || showOriginImage" title="颜色管理" />
      </el-popover>
      <el-dropdown trigger="click" placement="bottom" @command="setBrickShape">
        <el-button class="iconfont tap-btn opacity-bg margin-bottom12" :class="opts.roundTile ? 'my-circle-shape' : 'my-square-shape'" title="拼图颗粒" />
        <el-dropdown-menu slot="dropdown" class="my-dropdown">
          <el-dropdown-item title="圆形" :command="true">圆形 <span class="float-right align-right" :class="opts.roundTile ? 'iconfont my-check' : ''" /></el-dropdown-item>
          <el-dropdown-item title="方形" :command="false">方形 <span class="float-right align-right" :class="!opts.roundTile ? 'iconfont my-check' : ''" /></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-tooltip v-model="tooltipState.design" :manual="true" :disabled="!tooltipState.design" class="item" effect="dark" placement="left">
        <div slot="content"><div @click="closeToolTip('design')">进入高级创作模式</div></div> -->
      <el-popconfirm :title="'确定进入高级创作模式吗？'" cancel-button-type="Primary" placement="top" @confirm="submit()">
        <el-button slot="reference" class="iconfont my-design tap-btn opacity-bg" title="高级创作模式" />
      </el-popconfirm>
      <!-- </el-tooltip> -->
    </div>
    <div id="brickyviewer-footer" slot="footer" class="hide-overflow" :style="{'width': (isDesktop ? width - 15 : width) + 'px', 'overflow-x': 'auto', 'overflow-y': 'hidden', 'margin': isDesktop ? '0 2px' : ''}">
      <div id="preview-scroll" :style="previewImgsStyle()">
        <div v-for="(item, i) in previewImgs" v-show="(onlyShowRecommends && item.recommend) || !onlyShowRecommends" :key="i" height="62" class="float-left" @click="(e) => useStyle(item.k, item.img, item.work, item.recommend, e, false)">
          <!-- <img v-if="item.recommend" :src="item.img" height="62" :style="imgStyle(item.k)" class="round4 margin2"> -->
          <img :src="item.img" height="62" :style="imgStyle(item.k)" class="round4 margin2">
        </div>
      </div>
    </div>
    <div :style="{'width': (isDesktop ? width - 5 : width) + 'px', 'margin': isDesktop ? '0 2px' : ''}" style="position: absolute;height:50px;z-index:99;bottom:5px;text-align:center;display: flex;">
      <div v-if="!config.loading && loadPreviewNums >= maxPreviewNums" style="width:46px;float:left;padding: 8px 0 0 10px;">
        <!-- <el-tooltip v-model="tooltipState.toWall" :manual="true" :disabled="!tooltipState.toWall" class="item" effect="dark" placement="top">
          <div slot="content"><div @click="closeToolTip('toWall')">预览</div></div> -->
        <el-dropdown trigger="click" placement="top-start" @command="(typeid) => attatchToWall(typeid, '', null, true, true)">
          <el-button class="iconfont my-wall size20 padding8 opacity-bg" title="预览" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item v-for="(typeid, k) in [1, 2, 3, 4, 5]" :key="typeid" :title="'效果' + (k + 1)" :command="typeid"> {{ '效果' + (k + 1) }} <span class="float-right align-right" :class="opts.typeid === typeid ? 'iconfont my-check' : ''" /></el-dropdown-item>
            <el-dropdown-item v-show="opts.typeid || opts.wallId" title="取消预览" command=""> 取消预览</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- </el-tooltip> -->
      </div>
      <div v-if="loadPreviewNums >= maxPreviewNums" style="width:280px;margin: 0 auto;margin-top:6px;">
        <el-col :span="12">
          <el-button type="yellow" style="width:120px;height:40px;padding: 3px 8px;line-height: 2;margin-right: 2px;" class="btn-ok bricky-shadow round8" @click="openPaperViewer"><i class="iconfont my-orders float-left" style="width: 26px;" /><div class="float-left padding-left4" style="padding-top: 2px;">获取图纸</div></el-button>
        </el-col>
        <el-col :span="12">
          <!-- <el-button type="light" style="width:134px;height:40px;padding: 3px 8px;line-height: 1;margin-left: 2px;" class="btn-ok bricky-shadow round8" @click="orderIt"><i class="iconfont my-pay float-left" style="width: 28px;line-height: 2;" /><div class="float-left padding-left4 align-left" style="font-size: 12px;line-height: 1.3;">我要购买<br>¥166.00</div></el-button> -->
          <el-button type="light" style="width:120px;height:40px;padding: 3px 8px;margin-left: 2px;" class="btn-ok bricky-shadow round8" @click="orderIt"><i class="iconfont my-cart float-left" style="width: 28px;line-height: 2;" />
            <div class="float-left padding-left4 align-left" style="padding-top: 2px;line-height: 2;">
              我要购买
            </div>
          </el-button>
        </el-col>
      </div>
      <div v-if="loadPreviewNums < maxPreviewNums" style="line-height: 40px;margin: 0 auto;">AI渲染中，请稍等...</div>
      <!-- <div v-if="!config.loading && loadPreviewNums >= maxPreviewNums" style="line-height: 40px;margin: 0 auto;">请更换照片或调整头像大小试试！</div> -->
      <div v-if="!config.loading && loadPreviewNums >= maxPreviewNums" style="width:46px;float:right;padding: 8px 10px 0 0;">
        <!-- <el-tooltip v-model="tooltipState.export" :manual="true" :disabled="!tooltipState.export" class="item" effect="dark" placement="top">
          <div slot="content"><div @click="closeToolTip('export')">导出</div></div> -->
        <el-dropdown trigger="click" placement="top-start" @command="handle">
          <el-button class="iconfont my-out size20 padding8 opacity-bg" title="更多" />
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item v-if="opts.typeid && loginUserId && loginUserId < 10000" command="exportMainAssets"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出主图</div></el-dropdown-item>
            <el-dropdown-item v-if="opts.typeid" command="exportAssets"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出预览图</div></el-dropdown-item>
            <el-dropdown-item command="exportPixel"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出像素画</div></el-dropdown-item>
            <el-dropdown-item command="exportGridy"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出百格画</div></el-dropdown-item>
            <el-dropdown-item command="exportBrick"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />导出拼图...</div></el-dropdown-item>
            <el-dropdown-item command="exportAdvance"> <div class="padding-top4 padding-bottom4"><i class="iconfont my-export-out" />高级导出...</div></el-dropdown-item>
            <el-dropdown-item command="publishWork" divided> <i class="iconfont my-zone" /> 发布...</el-dropdown-item>
            <el-dropdown-item command="pop"> <i class="iconfont my-pop" /> 生成分享图</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- </el-tooltip> -->
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { mixins } from '@/mixins/common'
import utils from '@/js/utils'
import service from '@/js/service'
import conf from '@/js/conf/conf'
import GRIDY from '@/js/sdk/GridySDK'
import voxelViewer from '@/components/viewer/voxelViewer'
import blankImage from '@/assets/blank.png'
export default {
  components: {
    voxelViewer
  },
  mixins: [mixins],
  props: {},
  data() {
    let width, height
    if (this.state.platform.type === 'desktop') {
      width = 632
      height = 632
    } else {
      width = utils.width()
      height = utils.height()
    }
    const palette = utils.deepClone(conf.palette)
    const colorfyDt = utils.deepClone(conf.colorfyDt)
    const frameDt = utils.deepClone(conf.frameDt)
    const bgDt = utils.deepClone(conf.bgDt)
    const defaultOpts = {
      x: 0,
      y: 0,
      initScale: 1,
      defaultGridSize: 8,
      gridSize: 8,
      filter: {
        bgId: '',
        // 色调
        hue: 0,
        // 饱和度
        saturation: 0,
        // 色明度
        value: 0,
        // 亮度
        brightness: 0,
        // 对比度
        contrast: 0
      },
      denoiseFactor: 0,
      // 是否限制颜色数量
      limitColorNums: 0,
      typeid: 0,
      wallId: '',
      bgId: '',
      frameId: 'blackBlack',
      colorfyId: '',
      paletteId: 'brickfyGray',
      workTypeid: 2,
      shapeIdx: 0,
      brickSizeId: '',
      ratioid: '',
      preFillShape: '',
      fillShape: 'circle',
      colorOrder: 'asc',
      brickfy: false,
      roundTile: false,
      d3: true,
      canvasBgColor: '',
      gridColor: '',
      showGrid: true,
      diy: false
    }
    return {
      mainHost: conf.hosts().mainHost,
      hashParams: utils.getHashParams(),
      sizeOpts: conf.sizeOpts,
      inited: false,
      showOriginImage: false,
      sceneIdx: 0,
      playing: false,
      count: 0,
      sto: null,
      imgEl: null,
      voxelViewerEl: null,
      GRIDYXLS: null,
      GRIDYLXF: null,
      GRIDYPAPER: null,
      GRIDYBOM: null,
      GRIDY: null,
      touchEndLock: false,
      mngColorShow: false,
      defaultOpts: defaultOpts,
      opts: utils.deepClone(defaultOpts),
      additionalBrickSizeOpts: [],
      width: width,
      height: height,
      blankImage: blankImage,
      palette: palette,
      colorfyDt: colorfyDt,
      frameDt: frameDt,
      bgDt: bgDt,
      rawImg: null,
      hasRecommend: false,
      recommendCurrent: true,
      onlyShowRecommends: false,
      workDataTmp: '',
      loadPreviewNums: 0,
      previewFilterArr: [],
      maxPreviewNums: 1,
      previewImgs: [],
      colorfyObj: {},
      colorfyWork: null,
      colors: [],
      colorsCount: 0,
      brickColorCount: 0,
      brickCount: 0,
      editColor: {
        color: '',
        newColor: '',
        idx: -1
      },
      // 作品选项
      workOpts: {},
      selectPopoverShow: false,
      // 套装模式会限制并自动调整拼图块用色
      // suiteMod: false,
      tooltipState: {
        colorfy: false,
        bricksize: false,
        filter: false,
        design: false,
        toWall: false,
        export: false
      }
    }
  },
  computed: {
    showEasy() {
      const arr = this.opts.brickSizeId.split('-')
      return arr[0] === arr[1] && arr[1] <= 48
    },
    showPreviewNums() {
      return (this.data.type === 'workViewer') ? 8 : 8
    },
    brickSizeOpts() {
      return this.opts.ratioid ? (this.sizeOpts[this.opts.ratioid] || []) : []
    },
    config() {
      return this.view.brickyViewer
    },
    data() {
      return this.config.data
    },
    skuId() {
      return (this.config.data.skuId || '') + ''
    },
    title() {
      let tit
      if (this.data.preview) {
        tit = '导出预览'
      } else {
        if (this.opts.diy || this.opts.paletteId === 'brickfy') {
          tit = '定制拼图'
        }
      }
      return tit
    },
    previewAreaStyle() {
      return { 'width': this.width + 'px', 'height': this.height + 'px', 'background': '#000000', 'overflow': 'hidden', 'text-align': 'center' }
    },
    scenes() {
      return (this.data.work && this.data.work.canvas) ? this.data.work.canvas.scenes : []
    },
    isViewer() {
      return this.data.type === 'workViewer' || this.data.type === 'imagesViewer'
    },
    canPlay() {
      return this.isViewer && (this.scenes.length > 1 || (this.data.images && this.data.images.length > 1))
    }
  },
  watch: {
    'mngColorShow': {
      handler() {
        if (this.mngColorShow) {
          this.emit('traceEvent', ['mngColorShow_bricky'])
          this.getObjColors()
        }
      }
    }
  },
  mounted() {
    if (this.skuId === '10001') this.onlyShowRecommends = true
    if (this.skuId === '10002') {
      this.defaultOpts.roundTile = true
      this.defaultOpts.bgId = 'whiteWhite'
    } else {
      this.defaultOpts.roundTile = false
      this.defaultOpts.bgId = 'blackBlack'
    }
    setTimeout(() => {
      service.loginStore()
      this.view.myPublish.fn = null
      if (this.config.show) {
        this.loading(true)
        setTimeout(() => {
          this.init(() => {
            const run = () => {
              this.initToolTips()
              // eslint-disable-next-line
              if (!this.imgEl.scaleX) Transform(this.imgEl)
              // this.suiteMod = !!this.data.suiteMod
              this.opts = utils.deepClone(this.defaultOpts)
              if (typeof this.data.fillShape !== 'undefined') this.opts.fillShape = this.data.fillShape
              if (typeof this.data.canback === 'undefined') this.data.canback = true
              delete this.data.originWork
              this.GRIDY = new GRIDY()
              // console.error('data0', utils.deepClone(this.data), this.data.diyType, this.opts)
              if (this.data.type === 'workViewer') {
                this.opts.darkMatch = false
                this.opts.colorfyId = this.data.colorfyId || ''
                this.opts.paletteId = this.data.colorfyId ? 'brickfyGray' : (this.data.work.paletteId || '')
                this.data.diyType = this.data.colorfyId ? 'brick' : (this.data.diyType || '')
                this.opts.fillShape = this.data.work.fillShape
                this.data.ratioid = this.data.work.canvas.aspectFactor
                this.opts.ratioid = this.data.ratioid
                this.opts.brickSizeId = this.data.brickSizeId || (this.data.work.canvas.cols + '-' + this.data.work.canvas.rows)
                this.data.originWork = utils.deepClone(this.data.work)
                this.data.name = this.data.work.name
                this.calcAdditionalBrickSizeOpts(this.data.originWork.canvas)
                // console.error('data1', utils.deepClone(this.data), this.opts)
                if (this.data.type === 'workViewer' && !this.data.preview && this.data.work && this.data.work.type === 1 && this.data.work.opts && (this.data.work.opts.isAi || this.data.work.opts.segmentImg) && this.scenes.length === 1 && this.scenes[0].objs && this.scenes[0].objs.length === 1 && (this.scenes[0].objs[0].originImage || this.scenes[0].objs[0].thumbImage)) {
                  // console.error('data2', utils.deepClone(this.data.work))
                  // 单场景、单对象时使用源图或缩略图
                  this.data.img = this.scenes[0].objs[0].originImage || this.scenes[0].objs[0].thumbImage
                  this.data.type = ''
                  this.opts.darkMatch = true
                  // 还原参数
                  if (this.data.work.opts) {
                    this.opts.isAi = true
                    this.data.work.opts.isAi = true
                    const opts = utils.deepClone(this.data.work.opts)
                    this.workOpts = opts
                    this.data.segmentImg = opts.segmentImg || this.data.img
                    if (typeof opts.filter !== 'undefined') this.opts.filter = utils.deepClone(opts.filter)
                    if (typeof opts.colorfyId !== 'undefined') this.opts.colorfyId = opts.colorfyId
                    if (typeof opts.paletteId !== 'undefined') this.opts.paletteId = opts.paletteId
                    if (typeof opts.haveFace !== 'undefined') this.data.haveFace = opts.haveFace
                    if (typeof opts.faceInfos !== 'undefined') this.data.faceInfos = utils.deepClone(opts.faceInfos)
                    if (typeof opts.imageHeight !== 'undefined') this.data.imageHeight = opts.imageHeight
                    if (typeof opts.imageWidth !== 'undefined') this.data.imageWidth = opts.imageWidth
                    this.data.colorfyId = this.opts.colorfyId
                  } else {
                    this.data.segmentImg = this.data.img
                    this.data.haveFace = false
                  }
                  this.workOpts.catalogid = this.data.work.catalogid
                  this.workOpts.tags = this.data.work.tags
                  this.workOpts.info = this.data.work.info
                  this.workOpts.workid = this.data.work.workid
                } else {
                  this.GRIDY.setFile(this.data.work)
                  this.setBrickModel()
                  return
                }
              } else {
                this.opts.ratioid = this.data.ratioid
                this.opts.brickSizeId = this.data.brickSizeId || this.calcBrickSizeId(this.opts.ratioid)
              }
              this.opts.colorfyId = this.data.colorfyId || 'dance'
              this.opts.paletteId = this.data.colorfyId ? 'brickfyGray' : (this.data.paletteId || '')
              this.data.diyType = this.data.colorfyId ? 'brick' : (this.data.diyType || '')
              if (this.data.type === 'imagesViewer') {
                this.sceneIdx = this.data.idx || 0
                const names = this.data.names || []
                for (const idx in this.data.images) {
                  this.importImages(idx, this.data.name || names[idx] || '')
                }
                this.data.work = this.GRIDY.getFile()
                this.data.originWork = utils.deepClone(this.data.work)
                if (names[0] || this.data.name) this.data.work.name = names[0] || this.data.name
                this.data.work.loop = 0
                this.data.work.rate = 0.5
              } else {
                const name = utils.getFileName(this.data.name)
                this.GRIDY.setFileName(name)
                this.GRIDY.importImage(this.sceneIdx, this.data.img || this.data.file, name, (status) => {
                  if (status) {
                    const obj = this.GRIDY.getObj(this.sceneIdx, 0)
                    this.calcAdditionalBrickSizeOpts(obj)
                    this.GRIDY.autoCanvasSize()
                    this.setDefaultGridSize(obj.cols, obj.rows)
                    this.setBrickBg('bg-1')
                  }
                }, { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, ratioid: this.opts.ratioid, compress: true })
              }
            }
            if (this.data.workid) {
              this.getWorkData(this.data.workid, this.data.userid, (work) => {
                if (work) {
                  if (work.workid) this.emit('workCount', [work.workid, 'diy'])
                  this.data.colorfyId = ''
                  this.data.work = work
                  this.data.type = 'workViewer'
                  this.data.diyType = 'brick'
                  this.data.fillShape = ''
                  run()
                } else {
                  this.closePop(this.config)
                }
              })
            } else {
              run()
            }
          })
        }, 50)
      } else {
        if (this.imgEl) {
          this.imgEl.onload = null
          this.imgEl.onerror = null
        }
        this.inited = false
      }
    }, 50)
  },
  methods: {
    async initToolTips() {
      for (const k in this.tooltipState) {
        const ret = await service.getCount(this.loginUserId, 'tooltip_' + k)
        this.tooltipState[k] = utils.empty(ret)
      }
    },
    closeToolTip(k) {
      service.incCount(this.loginUserId, 'tooltip_' + k)
      this.tooltipState[k] = false
    },
    previewImgsStyle() {
      let arr = [64, 64]
      if (this.data.type === 'workViewer') {
        if (this.colorfyObj) arr = [this.colorfyObj.cols, this.colorfyObj.rows]
      } else {
        if (this.opts.brickSizeId) arr = this.opts.brickSizeId.split('-')
      }
      const w = Math.floor(arr[0] * 62 / arr[1])
      const style = { margin: '0 auto', height: '66px', 'overflow-y': 'hidden' }
      style.width = (this.previewNums() * (w + 4)) + 12 + 'px'
      return style
    },
    calcLimitColorNums() {
      // if (!this.suiteMod) return 0
      if (this.opts.colorfyId === 'classic' || this.opts.colorfyId === 'golden' || this.opts.colorfyId === 'dance') {
        let arr = [64, 64]
        if (this.data.type === 'workViewer') {
          if (this.colorfyObj) arr = [this.colorfyObj.cols, this.colorfyObj.rows]
        } else {
          if (this.opts.brickSizeId) arr = this.opts.brickSizeId.split('-')
        }
        return this.GRIDY.calcLimitColorNums(this.skuId, arr[0], arr[1])
      } else {
        return 0
      }
    },
    calcDenoiseFactor() {
      // 百格画作品不降噪
      return this.data.type === 'workViewer' ? 0 : 40
    },
    previewNums() {
      let n = 0
      for (const i in this.previewImgs) {
        if (this.onlyShowRecommends) {
          if (this.previewImgs[i] && this.previewImgs[i].recommend) n++
        } else {
          if (this.previewImgs[i]) n++
        }
      }
      return n
    },
    calcAdditionalBrickSizeOpts(obj) {
      const opts = []
      const cols = obj.cols
      const rows = obj.rows
      const ratioid = this.calcRatioId(cols, rows)
      const maxSize = 160
      if (ratioid) {
        this.opts.ratioid = ratioid
      } else {
        if (cols / 8 >= 16 && rows / 8 >= 16) opts.push({ cols: Math.round(cols / 8), rows: Math.round(rows / 8) })
        if (cols / 4 >= 16 && rows / 4 >= 16) opts.push({ cols: Math.round(cols / 4), rows: Math.round(rows / 4) })
        if (cols / 2 >= 16 && rows / 2 >= 16) opts.push({ cols: Math.round(cols / 2), rows: Math.round(rows / 2) })
        opts.push({ cols: cols, rows: rows })
        if (cols * 1.5 <= maxSize && rows * 1.5 <= maxSize) opts.push({ cols: Math.round(cols * 1.5), rows: Math.round(rows * 1.5) })
        if (cols * 2 <= maxSize && rows * 2 <= maxSize) opts.push({ cols: cols * 2, rows: rows * 2 })
        if (cols * 3 <= maxSize && rows * 3 <= maxSize) opts.push({ cols: cols * 3, rows: rows * 3 })
        if (cols * 4 <= maxSize && rows * 4 <= maxSize) opts.push({ cols: cols * 4, rows: rows * 4 })
        this.opts.brickSizeId = cols + '-' + rows
      }
      this.additionalBrickSizeOpts = opts
    },
    calcRatioId(cols, rows) {
      for (const i in conf.canvasRatio) {
        if (conf.canvasRatio[i].cols / conf.canvasRatio[i].rows === cols / rows) {
          return conf.canvasRatio[i].id
        }
      }
      return ''
    },
    showSizeOpt(cols, rows) {
      if (this.data.type === 'workViewer') {
        return cols <= this.data.originWork.canvas.cols && rows <= this.data.originWork.canvas.rows
      } else {
        if (this.skuId === '10001') {
          return (cols === 48 && rows === 48) || (cols === 64 && rows === 64) || (cols === 80 && rows === 80)
        } else if (this.skuId === '10002') {
          return (cols === 48 && rows === 48) || (cols === 64 && rows === 64) || (cols === 96 && rows === 96)
        } else {
          return cols >= 48 && cols <= 96 && rows >= 48 && rows <= 96
        }
      }
    },
    calcBrickSizeId(ratioid) {
      for (const i in conf.canvasRatio) {
        if (conf.canvasRatio[i].id === ratioid) {
          return conf.canvasRatio[i].cols + '-' + conf.canvasRatio[i].rows
        }
      }
      return ''
    },
    importImages(idx, name) {
      idx = parseInt(idx)
      const imagesCount = this.data.images.length
      if (idx > 0) this.GRIDY.addScene()
      this.GRIDY.importImage(idx, this.data.images[idx], utils.getFileName(name || this.data.name), (status) => {
        if (imagesCount - idx === 1) {
          const obj = this.GRIDY.getObj(idx, 0)
          this.opts.brickSizeId = obj.cols + '-' + obj.rows
          this.calcAdditionalBrickSizeOpts(obj)
          this.setBrickBg('bg-1')
        }
      }, { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, compress: true })
    },
    // 网络加载作品数据
    getWorkData(workid, userid, cb) {
      if (this.loginUserId !== parseInt(userid)) userid = ''
      service.get('work_data', workid, (ret, type) => {
        if (type === 'success' && ret.data && ret.data.data) {
          const data = ret.data
          const work = utils.deepClone(data.data)
          work.workid = data.workid
          work.userid = data.userid
          work.threadid = data.threadid || 0
          work.postid = data.postid || 0
          work.publish = true
          work.public = data.public || false
          work.best = data.best || false
          work.check = data.check || false
          work.flag = data.flag || false
          work.hot = data.hot || false
          work.original = data.original || false
          work.updateTime = work.updateTime || data.public_at * 1000
          work.publishid = data.publishid
          cb && cb(work)
        } else {
          cb && cb()
        }
      }, false, { userid: userid }, false)
    },
    init(cb) {
      if (this.inited) {
        cb && cb()
        return
      }
      this.imgEl = this.$refs.image
      this.voxelViewerEl = this.$refs.voxelViewer
      if (!this.imgEl || (!this.voxelViewerEl && this.opts.d3)) {
        setTimeout(() => {
          this.init(cb)
        }, 50)
        return
      }
      const load = () => {
        this.setImageStyle()
        if (this.opts.d3) {
          this.voxelViewerEl.setWork(this.GRIDY.getFile())
          if (this.opts.paletteId === 'originImage' || this.showOriginImage) {
            this.voxelViewerEl.setDiyMod('originImage')
          } else {
            this.voxelViewerEl.setDiyMod(this.opts.workTypeid === 2 ? 'brick' : '', { frameId: this.opts.frameId, color: this.opts.diyColor, size: this.opts.diySize, side: this.opts.diySide })
          }
          this.voxelViewerEl.borderToggle(!this.opts.wallId)
          this.voxelViewerEl.launch(() => {
            this.voxelViewerEl.initCubesPos()
            this.voxelViewerEl.draw(this.imgEl, () => {
              this.loading(false)
            })
          })
        } else {
          this.loading(false)
        }
      }
      this.imgEl.onload = load
      this.imgEl.onerror = load
      this.inited = true
      cb && cb()
    },
    back() {
      if (this.playing) this.stop()
      this.closePop(this.config)
    },
    close() {
      this.view.cropImage.show = false
      setTimeout(this.back, 100)
    },
    loading(show, tip) {
      this.config.loading = show
      this.emit('loading', [!!show, tip || 'AI渲染中，请耐心等待...'])
    },
    toggleColorOrder() {
      if (this.opts.colorOrder === 'asc') {
        this.opts.colorOrder = 'desc'
      } else {
        this.opts.colorOrder = 'asc'
      }
      this.getObjColors(this.opts.colorOrder)
    },
    // 获取对象颜色表
    getObjColors(orderby = 'asc') {
      const colorsObj = this.GRIDY.calcObjColors(this.sceneIdx, 0, -1, '#FFFFFF', this.opts.colorfyId)
      let colors = []
      if (colorsObj) {
        for (let color in colorsObj) {
          if (color) {
            color = color.toUpperCase()
            colors.push([color, colorsObj[color], conf.brickMap[color] || conf.brickDt[color]])
          }
        }
      }
      colors = utils.sortArr(colors, 1)
      if (orderby === 'asc') {
        colors = colors.reverse()
      }
      this.colorsCount = colors.length
      this.colors = colors.slice(0, 128)
    },
    // 获取拼图颗粒统计
    getBrickCount() {
      let brickColorCount = 0
      let brickCount = 0
      for (const i in this.colors) {
        if (this.colors[i][0] && this.opts.hideColors.indexOf(this.colors[i][0]) < 0) {
          brickColorCount = brickColorCount + 1
          brickCount = brickCount + this.colors[i][1]
        }
      }
      this.brickColorCount = brickColorCount
      this.brickCount = brickCount
      return (brickColorCount && brickCount) ? '(' + brickColorCount + '色' + brickCount + '颗)' : ''
    },
    // 打开降噪面板
    openDenoise() {
      const fn = () => {
        this.loading(true)
        setTimeout(() => {
          this.GRIDY.objDenoise(this.sceneIdx, 0, -1, this.state.denoiseFactor)
          this.GRIDY.mergeObjColor(this.sceneIdx, 0, -1, this.state.mergerFactor)
          this.GRIDY.quantizeObjColor(this.sceneIdx, 0, -1, this.state.quantizeFactor)
          this.state.quantizeFactor = 0
          this.draw()
        }, 50)
      }
      this.emit('openPop', [{ title: '降噪', type: 'denoise', fn: fn }])
    },
    openColorFilter() {
      const fn = () => {
        this.loading(true)
        setTimeout(() => {
          for (const k in this.state.colorFilter) {
            this.opts.filter[k] = this.state.colorFilter[k]
          }
          this.setFilter()
        }, 50)
      }
      this.emit('openPop', [{ title: '色相/饱和度', type: 'colorFilter', fn: fn }])
      this.closeToolTip('filter')
    },
    // 打开调色板取色
    toggleColorPicker(event, pickId, params) {
      const file = this.GRIDY.getFile()
      this.emit('toggleColorPicker', [event, pickId, params, file.type])
    },
    // 更换颜色
    changeObjColor(color, newColor) {
      if (color || newColor) {
        this.opts.wallId = ''
        this.opts.typeid = 0
        this.GRIDY.changeObjColor(this.sceneIdx, 0, -1, color, newColor)
        setTimeout(() => {
          this.getObjColors()
        }, 50)
        this.draw()
      }
    },
    // 删除颜色
    deleteObjColor(color) {
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.loading(true)
      this.emit('traceEvent', ['deleteObjColor_bricky'])
      this.opts.hideColors = []
      const fn = (img) => {
        const k = this.calcFilterKey(this.opts.filter)
        const work = this.GRIDY.getFile()
        this.previewImgs.unshift({ k: k, img: img, recommend: true, score: 0, colors: {}, work: work })
        this.useStyle(k, img, work, true)
        this.loadPreviewNums++
        this.loading(false)
        this.update()
      }
      setTimeout(() => {
        this.GRIDY.deleteObjColor(this.sceneIdx, 0, -1, color)
        this.getObjColors()
        this.draw(fn, null, true, true)
      }, 50)
    },
    // 隐藏颜色
    hideObjColor(color) {
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.loading(true)
      this.emit('traceEvent', ['hideObjColor_bricky'])
      setTimeout(() => {
        if (this.opts.hideColors.indexOf(color) >= 0) {
          this.opts.hideColors.splice(this.opts.hideColors.indexOf(color), 1)
        } else {
          this.opts.hideColors.push(color)
        }
        this.draw(() => {}, null, true, true)
      }, 50)
    },
    // 恢复作品
    restoreWork() {
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.opts.paletteId = 'originPalette'
      this.opts.brickSizeId = ''
      this.data.work = utils.deepClone(this.data.originWork)
      this.GRIDY.setFile(this.data.work)
      this.draw()
    },
    // 还原
    restore() {
      if (this.data.type === 'workViewer' && this.data.originWork) {
        this.restoreWork()
        return
      }
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.loading(true)
      setTimeout(() => {
        this.GRIDY.reloadImage(this.sceneIdx, 0, -1, 'origin', () => {
          const d3 = this.opts.d3
          this.opts = utils.deepClone(this.defaultOpts)
          this.opts.d3 = d3
          const obj = this.GRIDY.getObj(this.sceneIdx, 0)
          this.GRIDY.autoCanvasSize()
          this.setDefaultGridSize(obj.cols, obj.rows)
          this.setPalette('64')
          this.imgEl.scaleX = 1
          this.imgEl.scaleY = 1
          this.imgEl.translateX = 0
          this.imgEl.translateY = 0
        }, { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId })
      }, 50)
    },
    setDefaultGridSize(cols, rows) {
      if (!cols || !rows) return
      let gridSize = 4
      if (cols / rows > this.width / this.height) {
        gridSize = Math.floor(Math.floor(this.width / cols) / 2) * 2
      } else {
        gridSize = Math.floor(Math.floor(this.height / rows) / 2) * 2
      }
      this.opts.gridSize = Math.max(gridSize, 4)
      this.opts.defaultGridSize = this.opts.gridSize
    },
    setImageStyle() {
      if (!this.imgEl || !this.imgEl.width || !this.imgEl.height) return
      const left = (this.width - this.imgEl.width) / 2
      const top = (this.height - this.imgEl.height) / 2
      this.imgEl.style.left = left + 'px'
      this.imgEl.style.top = top + 'px'
      this.imgEl.style.position = 'relative'
    },
    // 设置色相饱和度
    setFilter() {
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.emit('traceEvent', ['setFilter_bricky'])
      const fn = (img, colorFilter, recommend, colorsScore, work) => {
        const k = this.calcFilterKey(colorFilter)
        this.previewImgs.unshift({ k: k, img: img, recommend: recommend, score: colorsScore.score, colors: colorsScore.colors, work: work })
        this.useStyle(k, img, work, recommend)
        this.loadPreviewNums++
        this.loading(false)
        this.update()
      }
      this.setPalette('', false, true, '', fn)
    },
    // 设置网格大小：越小越清晰
    setBricksize(sizeId) {
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.opts.brickSizeId = sizeId
      if (sizeId) {
        const obj = this.GRIDY.getObj(this.sceneIdx, 0)
        const size = sizeId.split('-')
        obj.cols = size[0]
        obj.rows = size[1]
        this.GRIDY.setCanvasSize(obj.cols, obj.rows)
      }
      this.emit('traceEvent', ['setBricksize_bricky', '', { sizeId: sizeId }])
      this.setBrickModel(this.opts.colorfyId, false)
      this.closeToolTip('bricksize')
    },
    // 预览效果
    attatchToWall(typeid, wallId, base64, trace = true, useRawImg = false) {
      this.closeToolTip('toWall')
      this.opts.typeid = typeid
      this.opts.wallId = wallId
      if (!typeid) {
        // 取消预览
        if (this.rawImg) {
          this.imgEl.src = this.rawImg
        } else {
          this.setPalette()
        }
        this.rawImg = ''
        return
      }
      let imgElm
      const toWall = () => {
        if (this.opts.typeid) {
          this.GRIDY.attatchToWall(imgElm, this.opts.typeid, this.opts.wallId, this.opts.frameId, (canvas) => {
            if (canvas) {
              this.imgEl.src = this.GRIDY.getBase64(canvas)
            }
            setTimeout(() => {
              if (this.voxelViewerEl) this.voxelViewerEl.zoomIn()
            }, 500)
            this.loading(false)
          })
        }
      }
      // 使用原始图像
      if (useRawImg && this.rawImg) base64 = this.rawImg
      if (base64) {
        this.loading(true)
        this.rawImg = base64
        imgElm = new Image()
        imgElm.src = base64
        imgElm.onload = toWall
      } else {
        this.rawImg = this.imgEl.src
        imgElm = this.imgEl
        toWall()
      }
      if (trace) this.emit('traceEvent', ['attatchToWall_bricky', '', { wallId: wallId }])
    },
    // 设置边框底板
    setBrickFrame(frameId, trace = true) {
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.opts.frameId = frameId
      this.opts.hideColors = []
      if (trace) this.emit('traceEvent', ['setBrickFrame_bricky', '', { frameId: frameId }])
      this.setPalette()
    },
    // 设置拼图颗粒
    setBrickShape(roundTile) {
      this.opts.roundTile = roundTile
      // this.setBrickFrame(roundTile ? 'whiteWhite' : 'blackBlack')
      this.setBrickModel()
    },
    // 设置背景
    setBrickBg(bgId, trace = true) {
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.opts.bgId = bgId
      this.opts.hideColors = []
      if (trace) this.emit('traceEvent', ['setBrickBg_bricky', '', { bgId: bgId }])
      if (bgId && this.data.type !== 'workViewer') {
        this.removeBg()
      } else {
        this.data.aiImg = null
        this.data.aiImgOrigin = null
        this.data.faceBox = {}
        this.setBrickModel(this.opts.colorfyId, false)
      }
    },
    removeBg() {
      const run = () => {
        this.GRIDY.getAiImage(this.data.segmentImg, this.data.img, this.opts.bgId, true, this.data.imageWidth, this.data.imageHeight, this.data.faceInfos, (base64, faceBox) => {
          this.data.aiImg = base64 || null
          this.data.faceBox = faceBox || {}
          this.setBrickModel(this.opts.colorfyId, false)
        })
        this.GRIDY.getAiImage(this.data.segmentImg, this.data.img, 'origin', true, this.data.imageWidth, this.data.imageHeight, this.data.faceInfos, (base64, faceBox) => {
          this.data.aiImgOrigin = base64 || null
        })
      }
      if (this.data.segmentImg) {
        run()
        return
      }
      const data = {
        RspImgType: 'base64'
      }
      if (this.data.img) {
        data.image = this.data.img
      } else if (this.data.imageUrl) {
        data.url = this.data.imageUrl
      } else {
        return
      }
      // if (!this.loginStatus) {
      //   this.opts.bgId = 'bg'
      //   this.message('请先登录，再设置背景！', 'login')
      //   this.loading(false)
      //   return
      // }
      const alert = () => {
        this.data.segmentImg = ''
        this.data.haveFace = false
        this.data.faceInfos = []
        this.data.imageWidth = 0
        this.data.imageHeight = 0
        // this.alert('使用清晰的个人照片，效果会更好哦')
      }
      this.loading(true)
      service.post('remove_bg', data, (dt, type) => {
        if (type === 'success' && dt.data && (dt.data.ResultImageUrl || dt.data.ResultImage)) {
          if (dt.data.FaceInfos && dt.data.FaceInfos.length && dt.data.FaceInfos[0] && dt.data.FaceInfos[0].X >= 0 || dt.data.FaceInfos[0].Y >= 0) {
            this.data.segmentImg = 'data:image/png;base64,' + dt.data.ResultImage
            this.data.haveFace = true
            this.data.faceInfos = dt.data.FaceInfos
            this.data.imageWidth = dt.data.ImageWidth
            this.data.imageHeight = dt.data.ImageHeight
          } else {
            alert()
          }
          run()
        } else if (type === 'login') {
          this.message(dt, type)
          this.close()
        } else {
          alert()
          run()
        }
      }, false)
    },
    // 设置作品调色板和网格大小
    setWorkPalette(paletteId, brickSizeId = '', silence = false, showImg, colorFilter, cb) {
      colorFilter = colorFilter || this.opts.filter
      this.emit('traceEvent', ['setWorkPalette_bricky', '', { paletteId: paletteId, brickSizeId: brickSizeId }])
      if (this.data.type !== 'workViewer' || !this.data.originWork) return
      if (!silence) this.loading(true)
      setTimeout(() => {
        this.opts.paletteId = paletteId
        if (typeof brickSizeId !== 'undefined') this.opts.brickSizeId = brickSizeId
        this.data.work = utils.deepClone(this.data.originWork)
        const k = this.calcFilterKey(colorFilter)
        this.GRIDY.setFile(this.data.work)
        const sceneData = this.GRIDY.getSceneData(this.sceneIdx)
        const nextRun = sceneData.imageData.indexOf('') >= 0
        const options = { gridSize: this.opts.gridSize, fillShape: this.opts.fillShape, ratioid: this.opts.ratioid }
        if (this.opts.d3) options.gridSize = 16
        options.fillShape = ''
        this.draw((img, opts) => {
          if (opts.colorfyWork) this.GRIDY.setFile(opts.colorfyWork)
          const colorsScore = this.GRIDY.calcObjColorsAndScore(this.sceneIdx, 0, -1, this.opts.limitColorNums)
          this.useStyle(k, img, opts.colorfyWork, colorsScore.recommend)
          cb && cb(img, colorFilter, colorsScore.recommend, colorsScore, opts.colorfyWork, this.opts.frameId, nextRun)
        }, null, showImg)
      }, 10)
    },
    scrollContainer() {
      return document.getElementById('brickyviewer-footer')
    },
    scrollTo(box) {
      const container = this.scrollContainer()
      const containerBox = container.getBoundingClientRect()
      let left = 0
      if (container.scrollLeft + box.left - containerBox.left + 60 > containerBox.width) left = container.scrollLeft + box.left - containerBox.width + 60
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: left || 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    imgStyle(k) {
      if (this.calcFilterKey(this.opts.filter) === k) {
        return { 'border': '1px solid var(--color-yellow)' }
      } else {
        return { 'border': '1px solid var(--color-00)' }
      }
    },
    useStyle(k, img, work, recommend = true, e = '', offWall = false) {
      if (this.onlyShowRecommends && !recommend) return
      if (offWall) {
        this.opts.wallId = ''
        this.opts.typeid = 0
      }
      if (this.opts.wallId || this.opts.typeid) {
        this.attatchToWall(this.opts.typeid, this.opts.wallId, img, false)
      } else {
        this.imgEl.src = img
      }
      if (!k) return
      this.recommendCurrent = recommend
      const arr = k.split(',')
      if (arr.length !== 4) return
      this.opts.hideColors = []
      this.opts.filter.bgId = arr[0] || ''
      this.opts.filter.hue = parseInt(arr[1])
      this.opts.filter.saturation = parseInt(arr[2])
      this.opts.filter.value = parseInt(arr[3])
      this.state.colorFilter.bgId = this.opts.filter.bgId
      this.state.colorFilter.hue = this.opts.filter.hue
      this.state.colorFilter.saturation = this.opts.filter.saturation
      this.state.colorFilter.value = this.opts.filter.value
      if (e && e.target) this.emit('traceEvent', ['useStyle', '', { bgId: this.opts.filter.bgId, hue: this.opts.filter.hue, saturation: this.opts.filter.saturation, value: this.opts.filter.value }])
      this.setWorkData(work)
      // if (work) {
      //   work.type = 1
      //   if (this.workOpts.workid) work.workid = this.workOpts.workid
      //   if (this.workOpts.catalogid) work.catalogid = this.workOpts.catalogid
      //   if (this.workOpts.info) work.info = this.workOpts.info
      //   if (this.workOpts.tags && this.workOpts.tags.length) work.tags = this.workOpts.tags
      //   this.GRIDY.setFile(utils.deepClone(work))
      //   this.getObjColors()
      // }
      if (e && e.target && this.isDesktop) {
        const box = e.target.getBoundingClientRect()
        this.scrollTo(box)
      }
      this.colorfyWork = null
    },
    setWorkData(work) {
      if (work) {
        work.type = 1
        if (this.workOpts.workid) work.workid = this.workOpts.workid
        if (this.workOpts.catalogid) work.catalogid = this.workOpts.catalogid
        if (this.workOpts.info) work.info = this.workOpts.info
        if (this.workOpts.tags && this.workOpts.tags.length) work.tags = this.workOpts.tags
        this.workDataTmp = utils.deepClone(work)
        this.GRIDY.setFile(utils.deepClone(work))
        this.getObjColors()
      }
    },
    calcFilterKey(filter) {
      return (filter.bgId || '') + ',' + filter.hue + ',' + filter.saturation + ',' + filter.value
    },
    calcPreviewFilterArr() {
      let hueArr, saturationArr, valueArr, bgIds
      if (this.data.type === 'workViewer') {
        bgIds = ['white', 'bg-1']
        hueArr = [0]
        saturationArr = [0]
        valueArr = [-6, 0, 6]
      } else {
        bgIds = ['bg-1', 'origin']
        hueArr = [0]
        saturationArr = [0]
        valueArr = [-6, 0, 6]
        if (this.workOpts && this.workOpts.filter) {
          // 参数还原
          hueArr = [this.workOpts.filter.hue || 0]
          saturationArr = [this.workOpts.filter.saturation || 0]
          valueArr = [this.workOpts.filter.value || 0, -6, 0, 6]
        }
      }
      const arr = []
      for (const b in bgIds) {
        for (const h in hueArr) {
          for (const s in saturationArr) {
            for (const v in valueArr) {
              const filter = utils.deepClone(this.defaultOpts.filter)
              filter.bgId = bgIds[b]
              filter.hue = hueArr[h]
              filter.saturation = saturationArr[s]
              filter.value = valueArr[v]
              arr.push(filter)
            }
          }
        }
      }
      this.previewFilterArr = arr
      this.maxPreviewNums = arr.length
      return arr
    },
    setBrickModel(colorfyId, trace = true, closeTip = false) {
      if (closeTip) this.closeToolTip('colorfy')
      this.rawImg = ''
      this.opts.wallId = ''
      this.opts.typeid = 0
      this.opts.hideColors = []
      if (typeof colorfyId !== 'undefined') this.opts.colorfyId = colorfyId
      if (trace) this.emit('traceEvent', ['setBrickModel', '', { colorfyId: this.opts.colorfyId }])
      if (this.opts.colorfyId === 'colors') {
        this.opts.paletteId = 'brickfy'
      } else if (this.opts.colorfyId) {
        if (this.data.type === 'workViewer') {
          this.opts.paletteId = this.opts.colorfyId
        } else {
          this.opts.paletteId = 'brickfyGray'
        }
      } else {
        this.opts.paletteId = 'brickfy'
      }
      this.opts.brickfy = true
      this.opts.workTypeid = 2
      this.opts.fillShape = 'circle'
      this.opts.denoiseFactor = this.calcDenoiseFactor()
      this.opts.limitColorNums = this.calcLimitColorNums()
      this.loadPreviewNums = 0
      this.previewImgs = []
      // eslint-disable-next-line
      if (this.data.type === 'workViewer' && !this.data.originWork) {
        this.setPalette(this.opts.paletteId)
        this.hasRecommend = true
        this.loadPreviewNums = this.maxPreviewNums
        return
      }
      this.hasRecommend = false
      let recommendNums = 0
      const previewFilterArr = utils.deepClone(this.calcPreviewFilterArr())
      const sameTmp = {}
      const fn = (img, colorFilter, recommend, colorsScore, work, frameId = '', nextRun = true) => {
        const k = this.calcFilterKey(colorFilter)
        if (!sameTmp[JSON.stringify(colorsScore.colors)]) {
          this.previewImgs.push({ k: k, img: img, recommend: recommend, score: colorsScore.score, colors: colorsScore.colors, work: work })
          // if (this.data.type !== 'workViewer') sameTmp[JSON.stringify(colorsScore.colors)] = true
          sameTmp[JSON.stringify(colorsScore.colors)] = true
          this.useStyle(k, img, work, recommend)
        }
        this.loadPreviewNums++
        this.loading(false)
        if (recommend) {
          recommendNums++
          if (!this.hasRecommend) this.hasRecommend = true
        }
        // recommendNums++
        // this.hasRecommend = true
        if (previewFilterArr.length && recommendNums < this.showPreviewNums && (this.data.haveFace || this.data.type === 'workViewer') && nextRun) {
          this.setPalette(this.opts.paletteId, true, false, previewFilterArr.shift(), fn)
        } else {
          this.loadPreviewNums = this.maxPreviewNums
          let idx = 0
          if (this.workOpts.filter) {
            for (const i in this.previewImgs) {
              if (this.previewImgs[i].k === this.calcFilterKey(this.workOpts.filter)) {
                idx = i
              }
            }
          }
          this.useStyle(this.previewImgs[idx].k, this.previewImgs[idx].img, this.previewImgs[idx].work, this.previewImgs[idx].recommend)
          if (!this.previewNums()) {
            this.back()
            this.alert('请选择一张高清照片，尝试调整下截取的脸部大小再试试！')
          }
          // 修正作品数据
          setTimeout(() => {
            this.setWorkData(this.workDataTmp)
          }, 100)
        }
        this.update()
      }
      setTimeout(() => {
        this.setPalette(this.opts.paletteId, true, false, previewFilterArr.shift(), fn)
      }, 100)
    },
    // 设置调色板
    setPalette(paletteId, silence = false, showImg = true, colorFilter, cb) {
      if (!this.config.show) return
      colorFilter = colorFilter || this.opts.filter
      paletteId = paletteId || this.opts.paletteId
      this.opts.bgId = colorFilter.bgId || ''
      if (this.data.type === 'workViewer') {
        this.setWorkPalette(paletteId, this.opts.brickSizeId, silence, showImg, colorFilter, cb)
        return
      }
      this.emit('traceEvent', ['setPalette_bricky', '', { paletteId: paletteId }])
      if (!silence) this.loading(true)
      setTimeout(() => {
        if (paletteId === 'originImage') {
          this.draw(() => {}, paletteId)
        } else {
          this.opts.paletteId = paletteId
          const obj = this.GRIDY.getObj(this.sceneIdx, 0)
          this.opts.brickSizeId = obj.cols + '-' + obj.rows
          this.calcAdditionalBrickSizeOpts(obj)
          const options = { userid: this.loginUserId, brickSizeId: this.opts.brickSizeId, frameId: this.opts.frameId, bgId: this.opts.bgId, paletteId: paletteId, colorFilter: colorFilter, denoiseFactor: this.opts.denoiseFactor, limitColorNums: this.opts.limitColorNums, colorfyId: this.opts.colorfyId }
          const callback = (status) => {
            this.draw((img, opts) => {
              const colorsScore = this.GRIDY.calcObjColorsAndScore(this.sceneIdx, 0, -1, this.opts.limitColorNums)
              cb && cb(img, colorFilter, colorsScore.recommend, colorsScore, opts.colorfyWork, this.opts.frameId)
            }, null, showImg)
          }
          if (this.opts.bgId && this.data.aiImg) {
            this.GRIDY.replaceImage(this.sceneIdx, 0, -1, this.opts.bgId === 'origin' ? (this.data.aiImgOrigin || this.data.aiImg) : this.data.aiImg, null, callback, options)
          } else {
            this.GRIDY.reloadImage(this.sceneIdx, 0, -1, this.opts.brickSizeId ? 'origin' : 'thumb', callback, options)
          }
        }
      }, 50)
    },
    handle(act) {
      this.closeToolTip('export')
      let file = this.colorfyWork || this.GRIDY.getFile()
      if (act === 'exportPixel' || act === 'exportGridy' || act === 'exportBrick' || act === 'exportAdvance') {
        this.emit('showExportSheet', [file, act, this.opts, this.sceneIdx, this.voxelViewerEl])
      } else if (act === 'exportOriginImage') {
        this.emit('exportFile', [file, 'originImage', 'originImage', this.opts, this.sceneIdx])
      } else if (act === 'publishWork') {
        file = utils.deepClone(file)
        file.type = 1
        const opts = {}
        opts.filter = utils.deepClone(this.opts.filter)
        opts.frameId = this.opts.frameId
        opts.colorfyId = this.opts.colorfyId
        opts.paletteId = this.opts.paletteId
        opts.diyType = this.opts.diyType
        opts.segmentImg = this.data.segmentImg
        opts.haveFace = !!this.data.haveFace
        opts.faceInfos = this.data.faceInfos
        opts.imageWidth = this.data.imageWidth
        opts.imageHeight = this.data.imageHeight
        opts.isAi = this.opts.isAi || !!this.data.isAi
        file.opts = opts
        this.emit('publishWork', [file, false, (dt) => {
          file.catalogid = dt.catalogid
          file.name = dt.name
          file.info = dt.info
          file.tags = dt.tags
          file.public = dt.public
          file.public_at = dt.public_at
          file.share = dt.share
          file.threadid = dt.threadid
          file.postid = dt.postid
          file.workid = dt.workid
          this.GRIDY.setFile(file)
        }])
      } else if (act === 'share') {
        this.$refs['share-url'].click()
      } else if (act === 'pop') {
        this.emit('exportFile', [file, 'pop', 'pop', this.opts, this.sceneIdx])
      } else if (act === 'exportAssets' || act === 'exportMainAssets') {
        const opts = utils.deepClone(this.opts)
        opts.brickfy = true
        let b64 = this.imgEl.src
        if (act === 'exportMainAssets') {
          // 截取产品主图
          const canvas = document.createElement('canvas')
          canvas.width = this.imgEl.width
          canvas.height = this.imgEl.width
          const ctx = canvas.getContext('2d')
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(this.imgEl, 0, this.imgEl.height - this.imgEl.width - 60, canvas.width, canvas.width, 0, 0, canvas.width, canvas.width)
          b64 = canvas.toDataURL('image/png')
        }
        this.emit('exportFile', [file, 'assets', 'assets', opts, this.sceneIdx, false, null, false, b64, act === 'exportMainAssets' ? '产品主图' : '预览效果图'])
      }
    },
    // 分享
    shareIt(workid) {
      if (!workid) {
        this.alert('请先发布，再分享', () => {
          this.handle('publishWork')
        })
        return
      }
      // eslint-disable-next-line
      var clipboard = new ClipboardJS('.clipboard')
      clipboard.on('success', (e) => {
        e.clearSelection()
        this.message('链接复制成功，请粘贴分享', 'success')
        clipboard.destroy()
      })
      service.get('work_share', workid)
      service.incCount(this.loginUserId, 'work_share')
      this.emit('traceEvent', ['shareIt_bricky'])
    },
    getInfo(file) {
      if (!file) return ''
      return (file.name || '') + ' ' + this.getWorkTags(file) + ' ' + (file.info || '')
    },
    getWorkTags(work) {
      if (!work.tags) return ''
      let tags = ''
      if (work.tags && work.tags.length) {
        tags = '#' + work.tags.join('# #') + '#'
      }
      return tags
    },
    ease(x) {
      return Math.sqrt(1 - Math.pow(x - 1, 2))
    },
    onDragStop(x, y) {
      this.opts.x = x
      this.opts.y = y
    },
    /* eslint-disable */
    multipointStart() {
      To.stopAll()
      this.opts.initScale = this.imgEl.scaleX
    },
    rotate(evt) {
      this.imgEl.rotateZ += evt.angle
    },
    pinch(evt) {
      this.imgEl.scaleX = this.imgEl.scaleY = this.opts.initScale * evt.zoom
    },
    // 滚轴缩放
    onWheel(event) {
      if (event.deltaY > 0) {
        this.zoom('-')
      } else {
        this.zoom('+')
      }
    },
    // 缩放
    zoom(act) {
      if (act === '+') {
        if (this.opts.gridSize === 1) {
          this.opts.gridSize = 2
        } else if (this.opts.gridSize < 8) {
          this.opts.gridSize = this.opts.gridSize + 2
        } else {
          this.opts.gridSize = this.opts.gridSize + 4
        }
        this.opts.gridSize = Math.min(this.opts.gridSize, 32)
      } else if (act === '-') {
        if (this.opts.gridSize > 8) {
          this.opts.gridSize = this.opts.gridSize - 4
        } else {
          this.opts.gridSize = this.opts.gridSize - 2
        }
        this.opts.gridSize = Math.max(this.opts.gridSize, 1)
      } else {
        if (act < 0.125) {
          act = 0.125
        } else if (act > 2) {
          act = 2
        }
        this.opts.gridSize = Math.round(8 * Math.abs(act))
      }
      this.draw()
      this.opts.initScale = this.opts.gridSize / conf.state.gridSize
    },
    /* eslint-disable */
    multipointEnd() {
      if (this.touchEndLock) return
      To.stopAll()
      if (this.imgEl.scaleX < 1) {
        new To(this.imgEl, 'scaleX', 0.6, 500, this.ease)
        new To(this.imgEl, 'scaleY', 0.6, 500, this.ease)
      } else if (this.imgEl.scaleX > 0.8 && this.imgEl.scaleX < 1.2) {
        new To(this.imgEl, 'scaleX', 1, 500, this.ease)
        new To(this.imgEl, 'scaleY', 1, 500, this.ease)
      } else if (this.imgEl.scaleX > 1.2) {
        new To(this.imgEl, 'scaleX', 1.5, 500, this.ease)
        new To(this.imgEl, 'scaleY', 1.5, 500, this.ease)
      } else if (this.imgEl.scaleX > 2) {
        new To(this.imgEl, 'scaleX', 2, 500, this.ease)
        new To(this.imgEl, 'scaleY', 2, 500, this.ease)
      }
      var rotation = this.imgEl.rotateZ % 360
      if (rotation < 0)rotation = 360 + rotation
      this.imgEl.rotateZ=rotation
      if (rotation > 0 && rotation < 45) {
        new To(this.imgEl, 'rotateZ', 0, 500, this.ease)
      } else if (rotation >= 315) {
        new To(this.imgEl, 'rotateZ', 360, 500, this.ease)
      } else if (rotation >= 45 && rotation < 135) {
        new To(this.imgEl, 'rotateZ', 90, 500, this.ease)
      } else if (rotation >= 135 && rotation < 225) {
        new To(this.imgEl, 'rotateZ', 180, 500, this.ease)
      } else if (rotation >= 225 && rotation < 315) {
        new To(this.imgEl, 'rotateZ', 270, 500, this.ease)
      }
      this.touchEndLock = true
      setTimeout(() => {
        this.touchEndLock = false
      }, 300)
    },
    pressMove(evt) {
      this.imgEl.translateX += evt.deltaX
      this.imgEl.translateY += evt.deltaY
      evt.preventDefault()
    },
    doubleTap(evt) {
      To.stopAll()
      if (this.opts.gridSize > this.opts.defaultGridSize ) {
        this.opts.gridSize = this.opts.defaultGridSize
      } else {
        this.opts.gridSize = this.opts.defaultGridSize * 2
      }
      this.draw()
    },
    draw(cb, paletteId, showImg = true, rawDraw = false) {
      paletteId = paletteId || this.opts.paletteId
      let imageUrl = ''
      const options = { darkMatch: this.opts.darkMatch, gridSize: this.opts.gridSize, fillShape: this.opts.fillShape, brickfy: this.opts.brickfy, paletteId: paletteId, workTypeid: this.opts.workTypeid, limitColorNums: this.opts.limitColorNums, colorfyId: this.opts.colorfyId, frameId: this.opts.frameId, bgId: this.opts.bgId }
      if (this.opts.hideColors) options.hideColors = this.opts.hideColors
      if (this.opts.d3) options.gridSize = 16
      if (rawDraw) {
        delete options.paletteId
        delete options.colorfyId
      }
      options.roundTile = this.opts.roundTile
      options.CLBrick = !this.opts.roundTile
      options.hideGridBorder = !this.opts.roundTile
      // console.error('options', utils.deepClone(options), this.data)
      const canvasElm = document.createElement('canvas')
      options.fn = (obj) => {
        if (this.data.type === 'workViewer') {
          this.colorfyObj = this.GRIDY.getColorfyObj(utils.deepClone(obj), this.opts.colorfyId)
          this.colorfyWork = this.getColorfyWork(this.colorfyObj)
        } else {
          this.colorfyObj = utils.deepClone(this.GRIDY.getObj(this.sceneIdx, 0, -1))
          this.colorfyWork = utils.deepClone(this.GRIDY.getFile())
        }
        options.colorfyWork = this.colorfyWork
        imageUrl = this.GRIDY.getBase64(canvasElm)
        cb && cb(imageUrl, options)
        if (showImg) this.imgEl.src = imageUrl
        this.showOriginImage = paletteId === 'originImage'
      }
      if (this.data.type === 'workViewer') {
        this.GRIDY.drawScene(canvasElm, this.sceneIdx, options)
      } else {
        const obj = this.GRIDY.getObj(this.sceneIdx, 0, -1)
        obj.fillShape = this.opts.fillShape
        if(paletteId === 'originImage') {
          imageUrl = obj.originImage || obj.thumbImage || ''
        }
        if (this.data.type === 'imagesViewer') {
          options.transparent = false
          this.data.work.canvas.cols = obj.cols
          this.data.work.canvas.rows = obj.rows
        }
        options.faceBox = this.data.faceBox || {}
        this.GRIDY.drawObj(canvasElm, this.sceneIdx, 0, -1, options)
      }
    },
    submit() {
      this.closeToolTip('design')
      this.emit('traceEvent', ['submitBrickyViewer'])
      this.view.popPage.show = false
      if (this.playing) this.stop()
      let file
      if (this.data.type === 'imagesViewer' || this.data.type === 'workViewer') {
        file = this.colorfyWork || this.GRIDY.getFile()
        if (file.workid) {
          service.get('work_ref', file.workid, '')
          file.refWorkid = file.workid
          file.origin = 1
          delete file.workid
        }
        file.public = 0
        file.publish = false
        if (this.data.type === 'imagesViewer') {
          file.origin = 2
          const scene = utils.deepClone(file.canvas.scenes[this.sceneIdx])
          file.canvas.scenes = [scene]
        }
        this.emit('saveIt', [file])
        this.emit('importIt', [file, 'fileJson', '', [], 1])
      } else {
        const obj = this.GRIDY.getObj(this.sceneIdx, 0, -1)
        obj.fillShape = this.opts.fillShape
        if (this.data.imageUrl) obj.originUrl = this.data.imageUrl
        // 有作品id的直接打开
        file = this.GRIDY.getFile()
        file.origin = 2
        if (file.workid) {
          this.emit('importIt', [file, 'fileJson', '', [], 1])
        } else {
          this.emit('importIt', [obj, 'objJson', '', [], 1])
        }
      }
      this.close()
    },
    getColorfyWork(colorfyObj, work) {
      work = work || utils.deepClone(this.GRIDY.getFile())
      if (!this.colorfyObj) return work
      this.colorfyObj.name = work.name
      this.colorfyObj.userid = this.loginUserId
      this.colorfyObj.fillShape = ''
      if (work.canvas.scenes[0].objs.length === 1) {
        // 单对象改写数据
        work.canvas.scenes[0].objs[0].x = 0
        work.canvas.scenes[0].objs[0].y = 0
        work.canvas.scenes[0].objs[0].data = colorfyObj.data
        work.canvas.scenes[0].objs[0].cols = colorfyObj.cols
        work.canvas.scenes[0].objs[0].rows = colorfyObj.rows
      } else {
        // 多对象合并掉
        const newObj = this.GRIDY.createObj(this.colorfyObj)
        work.canvas.scenes[0].objs = [newObj]
      }
      work.canvas.cols = colorfyObj.cols
      work.canvas.rows = colorfyObj.rows
      return work
    },
    openPaperViewer() {
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      const work = utils.deepClone(this.colorfyWork || this.GRIDY.getFile())
      const params = { data: { colorfyId: this.opts.colorfyId, frameId: this.opts.frameId, limitColorNums: this.opts.limitColorNums, hideColors: this.opts.hideColors, work: work, imageUrl: this.imgEl.src, name: work.name, roundTile: this.opts.roundTile }}
      // if (this.opts.wallId || this.opts.typeid) {
      //   this.setPalette('', false, false, '', (img) => {
      //     params.data.imageUrl = this.rawImg || img
      //     this.emit('openPaperViewer', [params])
      //   })
      // } else {
      //   this.emit('openPaperViewer', [params])
      // }
      if ((this.opts.wallId || this.opts.typeid) && this.rawImg) {
        params.data.imageUrl = this.rawImg
      }
      if (this.recommendCurrent) {
        this.emit('openPaperViewer', [params])
      } else {
        this.confirm('请慎重使用该图纸，如果颗粒数量不够，请联系客服！', (action) => {
          if (action === 'confirm') this.emit('openPaperViewer', [params])
        })
      }
    },
    // 下单
    orderIt() {
      this.emit('traceEvent', ['orderIt', '', { colorfyId: this.opts.colorfyId, frameId: this.opts.frameId }])
      if (!this.loginStatus) {
        this.message('', 'login')
        return
      }
      const work = utils.deepClone(this.colorfyWork || this.GRIDY.getFile())
      const bgId = (this.opts.filter && this.opts.filter.bgId) ? this.opts.filter.bgId : ''
      const imageUrl = this.rawImg || this.imgEl.src
      const size = this.opts.brickSizeId.split('-')
      // 是否套装模式下单
      // const isSuite = true
      const isSuite = (parseInt(size[0]) === 48 || parseInt(size[0]) === 64) && (parseInt(size[1]) === 48 || parseInt(size[1]) === 64) && this.data.type !== 'workViewer' && (this.opts.colorfyId === 'classic' || this.opts.colorfyId === 'golden' || this.opts.colorfyId === 'dance')
      this.emit('openOrderMng', [{ act: 'create', skuId: this.skuId, data: [{ isSuite: isSuite, addCart: true, type: this.data.type, bgId: bgId, colorfyId: this.opts.colorfyId, frameId: this.opts.frameId, hideColors: this.opts.hideColors, work: work, imageUrl: imageUrl, name: work.name }]}])
    },
    // 开始播放
    start() {
      this.playing = true
      this.count = 0
      this.play()
    },
    // 上一个
    pre() {
      const scenes = this.data.work.canvas.scenes
      if (this.sceneIdx === 0) {
        this.sceneIdx = scenes.length - 1
      } else if (this.sceneIdx) {
        this.sceneIdx = this.sceneIdx - 1
      }
      this.setBrickModel()
    },
    // 下一个
    next() {
      const scenes = this.data.work.canvas.scenes
      if (this.sceneIdx === scenes.length - 1) {
        this.sceneIdx = 0
      } else if (this.sceneIdx < scenes.length - 1) {
        this.sceneIdx = this.sceneIdx + 1
      }
      this.setBrickModel()
    },
    // 播放场景
    play() {
      const scenes = this.data.work.canvas.scenes
      if (scenes.length < 2 || !this.playing) {
        return
      }
      this.count++
      if (this.sceneIdx === scenes.length - 1) {
        this.sceneIdx = 0
      } else if (this.sceneIdx < scenes.length - 1) {
        this.sceneIdx = this.sceneIdx + 1
      }
      this.setPalette(this.opts.paletteId, true)
      this.data.work.rate = this.data.work.rate || 12
      this.data.work.loop = this.data.work.loop || 0
      if (this.data.work.loop && this.count / scenes.length >= this.data.work.loop) {
        this.count = 0
        this.playing = false
        return
      }
      this.sto = setTimeout(this.play, 1000 / this.data.work.rate)
    },
    // 停止播放
    stop() {
      this.playing = false
      this.count = 0
      this.sceneIdx = 0
      this.draw()
      if (this.sto) clearTimeout(this.sto)
    }
  }
}
</script>
