/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2023-3-2
 * @copyright Gridy.Art
 */
import utils from '@/js/utils'
import md5 from 'js-md5'
import conf from '@/js/conf/conf'
import geometry from '@/js/conf/geometry'
import { jsPDF } from 'jspdf'
import download from 'downloadjs'
import quantize from 'quantize'
import JSZip from 'jszip'
import popBg from '@/assets/pop_bg.webp'
import logoImage from '@/assets/logo-l.png'
import logoImageBlack from '@/assets/logo-b.png'

var main = function(options = {}) {
  this.file = this.createFile(options)
  this.addScene(options)
  this.sceneIdx = 0
  this.parentObjIdx = this.objIdx = -1
  this.objCols = this.limitCols = conf.state.limitCols
  this.objRows = this.limitRows = conf.state.limitRows
  this.brickSizeId = ''
  this.ratioid = ''
  this.canvasElm = document.createElement('canvas')
  this.ctx = this.canvasElm.getContext('2d', { willReadFrequently: true })
  this.ctx.imageSmoothingEnabled = false
  this.CLBlendBrickImg = new Image()
  this.CLBlendBrickImg.src = conf.CLBrickImg
  this.CLBlendBrickImg.onerror = () => {
    this.CLBlendBrickImg = ''
  }
  this.blendBrickImg = new Image()
  this.blendBrickImg.src = conf.brickImg
  this.blendBrickImg.onerror = () => {
    this.blendBrickImg = ''
  }
  this.blendDiamondImg = new Image()
  this.blendDiamondImg.src = conf.diamondImg
  this.blendDiamondImg.onerror = () => {
    this.blendDiamondImg = ''
  }
}
main.prototype = {
  createFile: function(options = {}) {
    const file = utils.deepClone(conf.schema.file)
    file.id = utils.uuid()
    file.fileId = file.id
    file.refWorkid = options.refWorkid || 0
    file.refFileId = options.refFileId || ''
    file.type = options.type || 0
    file.name = options.name || '未命名'
    file.info = options.info || ''
    file.catalogid = options.catalogid || 0
    file.userid = options.userid || 0
    file.createTime = utils.date('datetime')
    if (typeof options.paletteId !== 'undefined') file.paletteId = options.paletteId
    if (typeof options.palette !== 'undefined') file.palette = options.palette
    if (typeof options.fillShape !== 'undefined') file.fillShape = options.fillShape
    file.gridSize = options.gridSize || 8
    file.public = options.public || 0
    file.canvas.aspectFactor = options.aspectFactor || file.canvas.aspectFactor
    file.canvas.cols = parseInt(options.cols || file.canvas.cols)
    file.canvas.rows = parseInt(options.rows || file.canvas.rows)
    if (typeof options.showGrid !== 'undefined') file.canvas.showGrid = !!options.showGrid
    file.canvas.gridColor = options.gridColor || file.canvas.gridColor
    if (typeof options.showBg !== 'undefined') file.canvas.showBg = !!options.showBg
    file.canvas.bgColor = options.bgColor || file.canvas.bgColor
    return file
  },
  createScene: function(options = {}) {
    const scene = utils.deepClone(conf.schema.scene)
    scene.id = utils.uuid()
    scene.refSceneId = options.refSceneId || ''
    scene.name = options.name || '未命名'
    scene.info = options.info || ''
    if (typeof options.paletteId !== 'undefined') scene.paletteId = options.paletteId
    if (typeof options.palette !== 'undefined') scene.palette = options.palette
    if (typeof options.fillShape !== 'undefined') scene.fillShape = options.fillShape
    scene.userid = options.userid || 0
    scene.createTime = utils.date('datetime')
    return scene
  },
  addScene: function(options = {}) {
    const scene = this.createScene(options)
    this.scenes().push(scene)
    this.sceneIdx = this.scenes().length - 1
  },
  addBlankObj: function(sceneIdx, options = {}) {
    options.type = ''
    this.addObj(sceneIdx, this.createObj(options))
  },
  createObj: function(options = {}) {
    const obj = utils.deepClone(conf.schema.obj)
    obj.id = utils.uuid()
    obj.userid = options.userid || 0
    obj.name = options.name || '未命名'
    obj.info = options.info || ''
    obj.txt = options.txt || ''
    obj.cols = parseInt(options.cols || this.canvas().cols)
    obj.rows = parseInt(options.rows || this.canvas().rows)
    if (isNaN(obj.cols)) obj.cols = 80
    if (isNaN(obj.rows)) obj.rows = 80
    if (typeof options.paletteId !== 'undefined') obj.paletteId = options.paletteId
    if (typeof options.palette !== 'undefined') obj.palette = options.palette
    if (typeof options.fillShape !== 'undefined') obj.fillShape = options.fillShape
    if (typeof options.gridsfy !== 'undefined') obj.gridsfy = options.gridsfy
    if (typeof options.type !== 'undefined') obj.type = options.type || ''
    obj.data = options.data || new Array(obj.cols * obj.rows).fill('')
    obj.refSceneId = options.refSceneId || ''
    obj.refObjectId = options.refObjectId || ''
    obj.x = options.x || 0
    obj.y = options.y || 0
    obj.z = options.z || 0
    obj.createTime = utils.date('datetime')
    return obj
  },
  addObj: function(sceneIdx, obj) {
    this.sceneIdx = sceneIdx
    const curObjs = this.curObjs()
    curObjs.unshift(obj)
    this.objIdx = curObjs.length - 1
  },
  setFile: function(file) {
    this.file = file
  },
  getFile: function() {
    return this.file
  },
  getSceneIdx: function() {
    return this.sceneIdx
  },
  setSceneIdx: function(sceneIdx) {
    this.sceneIdx = sceneIdx
  },
  getParentObjIdx: function() {
    return this.parentObjIdx
  },
  setParentObjIdx: function(parentObjIdx) {
    this.parentObjIdx = parentObjIdx
  },
  getObjIdx: function() {
    return this.objIdx
  },
  setObjIdx: function(objIdx) {
    this.objIdx = objIdx
  },
  canvas: function() {
    return this.file.canvas
  },
  scenes: function() {
    return this.file.canvas.scenes
  },
  curScene: function() {
    return this.file.canvas.scenes[this.sceneIdx]
  },
  curObjs: function() {
    return this.file.canvas.scenes[this.sceneIdx].objs
  },
  parentObj: function() {
    return this.file.canvas.scenes[this.sceneIdx].objs[this.parentObjIdx]
  },
  // 子对象
  subObjs() {
    return this.parentObj() ? this.parentObj().objs : ''
  },
  curObj: function() {
    if (this.file.canvas.scenes[this.sceneIdx].objs[this.parentObjIdx]) {
      return this.file.canvas.scenes[this.sceneIdx].objs[this.parentObjIdx].objs[this.objIdx]
    } else {
      return this.file.canvas.scenes[this.sceneIdx].objs[this.objIdx]
    }
  },
  // 获取AI自动背景图像
  getAiImage: function(segmentImage, originImage = '', bgId = 'origin', glow = true, imageWidth, imageHeight, faceInfos, cb) {
    if (!imageWidth || !imageHeight || !faceInfos) return cb && cb()
    if (segmentImage && segmentImage.indexOf('data:image/png;base64,') < 0) segmentImage = 'data:image/png;base64,' + segmentImage
    if (originImage && originImage.indexOf('data:image/png;base64,') < 0) originImage = 'data:image/png;base64,' + originImage
    const faceBox = {
      width: imageWidth,
      height: imageHeight
    }
    if (faceInfos && faceInfos[0]) {
      faceBox.x = faceInfos[0].X || 0
      faceBox.y = faceInfos[0].Y || 0
      faceBox.w = faceInfos[0].Width || imageWidth
      faceBox.h = faceInfos[0].Height || imageHeight
    }
    const img = new Image()
    const originImg = new Image()
    const drawImg = (drawOrigin = false) => {
      img.src = segmentImage
      img.onload = () => {
        const box = {}
        box.x = 0
        box.y = 0
        box.w = img.width
        box.h = img.height
        this.canvasElm.width = box.w
        this.canvasElm.height = box.h
        if (drawOrigin) {
          this.ctx.globalAlpha = 0.8
          this.ctx.drawImage(originImg, box.x, box.y, box.w, box.h, 0, 0, box.w, box.h)
        }
        this.ctx.globalAlpha = 1
        if (glow && drawOrigin) {
          this.ctx.shadowColor = '#FFFFFF'
          this.ctx.shadowBlur = Math.ceil(box.w * 1 / (drawOrigin ? 20 : 80))
        }
        this.ctx.drawImage(img, box.x, box.y, box.w, box.h, 0, 0, box.w, box.h)
        cb && cb(this.getBase64(), faceBox)
      }
      img.onerror = () => {
        cb && cb()
      }
    }
    if (bgId === 'origin' && originImage) {
      originImg.src = originImage
      originImg.onload = () => {
        drawImg(true)
      }
      originImg.onerror = () => {
        cb && cb()
      }
    } else {
      drawImg()
    }
  },
  // 获取预览配置
  getWallConf: function(typeid, wallId) {
    let titleId = 1
    if (typeid === 1) {
      // 首图
      titleId = 1
    } else if (typeid === 2) {
      // 二图（描述玩具自身）
      titleId = utils.getRndInt(2, 4)
    } else if (typeid === 3) {
      // 三图（描述多人互动）
      titleId = utils.getRndInt(5, 6)
    } else if (typeid === 4) {
      // 四图（描述送礼佳品）
      titleId = utils.getRndInt(7, 8)
    } else {
      // 五图（描述家居装饰）
      titleId = utils.getRndInt(9, 17)
    }
    const pre = utils.getRndInt(0, 38)
    if (!wallId) {
      if (pre < 3) {
        wallId = 'd' + utils.getRndInt(1, 3)
      } else if (pre <= 6) {
        wallId = 's' + utils.getRndInt(1, 6)
      } else {
        wallId = utils.getRndInt(1, 29)
      }
    }
    return { titleId: titleId, wallId: wallId }
  },
  // 生成预览效果图
  attatchToWall: function(rawCanvas, typeid, wallId, frameId, cb) {
    const wallConf = this.getWallConf(typeid, wallId)
    const titleId = wallConf.titleId
    wallId = wallConf.wallId
    if (rawCanvas && typeid && wallId) {
      const rate = 1
      const wallWidth = 1080 * rate
      const wallHeight = 1529 * rate
      const boxSize = 500 * rate
      let pictureWidth = Math.round(rawCanvas.width * rate / 2.5)
      let pictureHeight = Math.round(rawCanvas.height * rate / 2.5)
      const size = utils.calcImageSize(pictureWidth, pictureHeight, boxSize, boxSize)
      pictureWidth = size.width
      pictureHeight = size.height
      const posX = Math.ceil((wallWidth - pictureWidth) / 2)
      const posY = (boxSize - pictureHeight) / 2 + 400 * rate
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = wallWidth
      canvas.height = wallHeight
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      this.imageLoader(conf.hosts().cdnHost + 'walls/' + wallId + '.jpg?v=0.2', (base64) => {
        if (!base64) {
          cb && cb(rawCanvas)
        } else {
          const img = new Image()
          img.src = base64
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            const frameConf = this.getFrameConf(frameId)
            if (frameConf.border) {
              ctx.fillStyle = frameConf.border
              ctx.fillRect(posX - 6 * rate, posY - 6 * rate, pictureWidth + 12 * rate, pictureHeight + 12 * rate)
            }
            ctx.drawImage(rawCanvas, 0, 0, rawCanvas.width, rawCanvas.height, posX, posY, pictureWidth, pictureHeight)
            if (titleId) {
              this.imageLoader(conf.hosts().cdnHost + 'titles/' + titleId + '.png?v=0.2', (b64) => {
                if (b64) {
                  const imge = new Image()
                  imge.src = b64
                  imge.onload = () => {
                    const titleHeight = imge.height * wallWidth / imge.width
                    ctx.drawImage(imge, 0, 0, canvas.width, titleHeight)
                    cb && cb(canvas)
                  }
                } else {
                  cb && cb(canvas)
                }
              })
            } else {
              cb && cb(canvas)
            }
          }
          img.onerror = () => {
            cb && cb(rawCanvas)
          }
        }
      })
    } else {
      cb && cb(rawCanvas)
    }
  },
  // 获取对象图纸（全部）
  exportPaper: function(sceneIdx, objIdx, parentObjIdx = -1, options = {}, cb) {
    let paperNum = 0
    const pages = []
    const partObj = this.getObjPartPaper(sceneIdx, objIdx, parentObjIdx, paperNum)
    pages.push(partObj)
    for (let i = 1; i < partObj.parts; i++) {
      paperNum++
      pages.push(this.getObjPartPaper(sceneIdx, objIdx, parentObjIdx, paperNum))
    }
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    const paper = {
      name: obj.name,
      cols: obj.cols,
      rows: obj.rows,
      colorfyId: obj.colorfyId || '',
      pages: pages
    }
    let addCount = 0
    const a4Width = 595.28
    const a4Height = 841.89
    // eslint-disable-next-line
    const pdf = new jsPDF('p', 'pt', 'a4')
    const addPage = (canvas, isCover) => {
      const limitWidth = isCover ? a4Width * 0.6 : a4Width * 0.8
      const limitHeight = a4Height * 0.8
      let canvasWidth = canvas.width
      let canvasHeight = canvas.height
      if (canvas.width / canvas.height > limitWidth / limitHeight) {
        if (canvas.width > limitWidth) {
          canvasWidth = limitWidth
          canvasHeight = canvasWidth * canvas.height / canvas.width
        }
      } else {
        if (canvas.height > limitHeight) {
          canvasHeight = limitHeight
          canvasWidth = canvasHeight * canvas.width / canvas.height
        }
      }
      const fixLeft = (a4Width - canvasWidth) / 2
      let fixTop = fixLeft
      // const fixTop = (a4Height - canvasHeight) / 2
      if (isCover) {
        pdf.addImage(logoImageBlack, 'JPEG', (a4Width - 150) / 2, 770, 150, 30)
        fixTop = fixTop / 2
      } else {
        pdf.addPage('a4', 'p')
        pdf.addImage(logoImageBlack, 'JPEG', (a4Width - 150) / 2, 20 + fixTop + canvasHeight, 150, 30)
      }
      pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', fixLeft, fixTop, canvasWidth, canvasHeight)
      addCount++
      if (addCount >= pages.length + 1) {
        const params = [pdf.output('blob'), paper.name, 'application/pdf']
        if (options.download) {
          download(...params)
          cb && cb()
        } else {
          if (options.raw) {
            cb && cb(paper, 'json')
          } else {
            cb && cb([params], 'pdf')
          }
        }
      }
    }
    const pageCanvas = document.createElement('canvas')
    const pageCtx = pageCanvas.getContext('2d', { willReadFrequently: true })
    const opts = {}
    opts.isBrick = true
    opts.roundTile = options.roundTile
    opts.CLBrick = !options.roundTile
    opts.hideGridBorder = !options.roundTile
    // circle square
    opts.fillShape = options.roundTile ? 'circle' : 'square'
    opts.gridSize = 32
    opts.paperMod = false
    opts.frameId = options.frameId
    opts.hideColors = options.hideColors || []
    // 绘制封面
    const gap = opts.gridSize / 4
    pageCanvas.width = partObj.partCols * partObj.cols * opts.gridSize + gap * (partObj.partCols + 1)
    const mainHeight = partObj.partRows * partObj.rows * opts.gridSize + gap * (partObj.partRows + 1)
    const titleHeight = partObj.rows * opts.gridSize
    pageCanvas.height = mainHeight + titleHeight
    this.drawRect(pageCtx, 0, 0, pageCanvas.width, titleHeight, '#FFFFFF')
    // 绘制名称
    let nameLeft = 0
    if (partObj.partCols % 2 === 0) {
      nameLeft = (partObj.partCols / 2 * partObj.cols - partObj.cols / 2) * opts.gridSize
    } else {
      nameLeft = Math.floor(partObj.partCols / 2) * partObj.cols * opts.gridSize
    }
    this.drawTxt(pageCtx, nameLeft, 0, partObj.rows * opts.gridSize, '#000000', obj.name, 'fill', 5)
    this.drawRect(pageCtx, 0, titleHeight, pageCanvas.width, pageCanvas.height, '#000000')
    // 绘制总图
    for (const i in pages) {
      this.drawObj(this.canvasElm, 0, 0, -1, opts, pages[i])
      const x = pages[i].x * opts.gridSize + (pages[i].x / partObj.cols) * gap + gap
      const y = pages[i].y * opts.gridSize + (pages[i].y / partObj.rows) * gap + gap + titleHeight
      pageCtx.drawImage(this.canvasElm, 0, 0, this.canvasElm.width, this.canvasElm.height, x, y, this.canvasElm.width, this.canvasElm.height)
      this.drawTxt(pageCtx, x + Math.round(pages[i].cols * opts.gridSize / 2), y + Math.round(pages[i].rows * opts.gridSize / 2), opts.gridSize * 8, '#000000', parseInt(i) + 1, 'fill', 1.5, 10)
    }
    addPage(pageCanvas, true)
    opts.paperMod = true
    // 绘制图纸页
    const titleRows = 2
    const gapRows = 1
    for (const i in pages) {
      this.drawObj(this.canvasElm, 0, 0, -1, opts, pages[i])
      pageCanvas.width = this.canvasElm.width + opts.gridSize
      pageCanvas.height = this.canvasElm.height + opts.gridSize + 9 * opts.gridSize
      this.drawRect(pageCtx, 0, 0, pageCanvas.width, pageCanvas.height, '#FFFFFF')
      // 绘制页码
      this.drawGrid(pageCtx, partObj.cols / titleRows / 2, 0, '#E8E8E8', opts.gridSize * titleRows, opts.fillShape, false, false, false)
      this.drawLocalNum(pageCtx, partObj.cols / titleRows / 2, 0, opts.gridSize * titleRows, '#000000', parseInt(i) + 1, 'fill', 2)
      // 绘制标尺
      for (let c = 0; c <= pages[i].cols; c++) {
        if (c % 2 === 0 && c) this.drawLocalNum(pageCtx, c, titleRows + gapRows, opts.gridSize, '#000000', utils.padding(c, 2))
      }
      for (let r = 0; r <= pages[i].rows; r++) {
        if (r % 2 === 0) this.drawLocalNum(pageCtx, 0, r + titleRows + gapRows, opts.gridSize, '#000000', r ? utils.padding(r, 2) : r)
      }
      // 绘制图纸
      pageCtx.drawImage(this.canvasElm, 0, 0, this.canvasElm.width, this.canvasElm.height, opts.gridSize, opts.gridSize + (titleRows + gapRows) * opts.gridSize, this.canvasElm.width, this.canvasElm.height)
      // 绘制当前页所需拼图块
      let x = 0
      for (const k in pages[i].bricks) {
        if (opts.hideColors.indexOf(pages[i].bricks[k].color) < 0) {
          const y = pages[i].rows + Math.ceil((x + 1) / pages[i].cols) + 1
          // this.drawGridBorder(pageCtx, 0 + x % pages[i].cols + 1, y + titleRows + gapRows, opts.gridSize, '#EFEFEF', '#E8E8E8')
          // this.drawGrid(pageCtx, 0 + x % pages[i].cols + 1, y + titleRows + gapRows, pages[i].bricks[k].color, opts.gridSize, opts.fillShape, false, opts.isBrick, true, false, {}, opts.roundTile, opts.CLBrick, opts.hideGridBorder)
          this.drawGrid(pageCtx, 0 + x % pages[i].cols + 1, y + titleRows + gapRows, pages[i].bricks[k].color, opts.gridSize, opts.fillShape, false, false, true)
          x++
        }
      }
      addPage(pageCanvas)
    }
  },
  // 检查图纸是否适用
  checkPaper: function(sceneIdx, objIdx, parentObjIdx = -1, limitColorNums = 0) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    for (const c in obj.colors) {
      if (obj.colors[c] > limitColorNums && limitColorNums) {
        return false
      }
    }
    return true
  },
  // 获取对象图纸(分页)
  getObjPartPaper: function(sceneIdx, objIdx, parentObjIdx = -1, paperNum = 0) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (!obj || !obj.data || !obj.data.length) {
      return
    }
    const box = { x: 0, y: 0, w: 16, h: 16 }
    const partCols = Math.ceil(obj.cols / 16)
    const partRows = Math.ceil(obj.rows / 16)
    if (paperNum < 0 || paperNum > partCols * partRows - 1) return
    box.x = (paperNum % partCols) * 16
    box.y = Math.floor(paperNum / partCols) * 16
    const partObj = this.getPartObj(sceneIdx, objIdx, parentObjIdx = -1, box)
    const colors = {}
    for (const i in partObj.data) {
      if (colors[partObj.data[i]]) {
        colors[partObj.data[i]]++
      } else {
        colors[partObj.data[i]] = 1
      }
    }
    const bricks = {}
    for (const c in colors) {
      const num = conf.brickMap[c.toUpperCase()] || conf.brickDt[c.toUpperCase()] || ''
      if (num) bricks[num] = { color: c, count: colors[c] }
    }
    partObj.partCols = partCols
    partObj.partRows = partRows
    partObj.parts = partCols * partRows
    partObj.colors = colors
    partObj.bricks = bricks
    return partObj
  },
  // 获取对象部分数据
  getPartObj: function(sceneIdx, objIdx, parentObjIdx = -1, box, obj, mod) {
    if (!box) return
    obj = obj || this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (!obj || !obj.data || !obj.data.length) {
      return
    }
    // 剪切部分选取的对象
    box.x = box.x - (obj.x || 0)
    box.y = box.y - (obj.y || 0)
    const partObj = { refObjectId: obj.id, x: box.x + obj.x, y: box.y + obj.y, cols: box.w, rows: box.h, fillShape: obj.fillShape, paletteId: obj.paletteId, data: [] }
    partObj.data = new Array(partObj.cols * partObj.rows)
    let x = 0
    let y = 0
    Object.keys(obj.data).map((i) => {
      y = Math.floor(i / obj.cols)
      x = i - y * obj.cols
      if (x >= box.x && x < box.x + box.w && y >= box.y && y < box.y + box.h) {
        partObj.data[(x - box.x) + (y - box.y) * partObj.cols] = obj.data[i] || ''
        if (mod === 'partCut' || mod === 'partDel') {
          obj.data[i] = ''
        }
      }
    })
    return partObj
  },
  getObj: function(sceneIdx, objIdx, parentObjIdx = -1) {
    if (this.file.canvas.scenes[sceneIdx] && this.file.canvas.scenes[sceneIdx].objs[parentObjIdx]) {
      return this.file.canvas.scenes[sceneIdx].objs[parentObjIdx].objs[objIdx] || {}
    } else if (this.file.canvas.scenes[sceneIdx] && this.file.canvas.scenes[sceneIdx].objs) {
      return this.file.canvas.scenes[sceneIdx].objs[objIdx] || {}
    }
  },
  setObj: function(sceneIdx, objIdx, parentObjIdx = -1, obj = {}) {
    if (this.file.canvas.scenes[sceneIdx] && this.file.canvas.scenes[sceneIdx].objs[parentObjIdx]) {
      this.file.canvas.scenes[sceneIdx].objs[parentObjIdx].objs[objIdx] = obj
    } else {
      this.file.canvas.scenes[sceneIdx].objs[objIdx] = obj
    }
  },
  // 设置文件名
  setFileName: function(name) {
    this.file.name = name
  },
  // 设置文件类型
  setFileType: function(type = 0) {
    this.file.type = type
  },
  // 设置场景属性
  setScenesAttrs: function(sceneIdx, data = {}) {
    if (!data) return
    if (this.file.canvas.scenes[sceneIdx]) {
      for (const k in data) {
        this.file.canvas.scenes[sceneIdx][k] = data[k]
      }
    }
  },
  // 设置对象属性
  setObjAttrs: function(sceneIdx, objIdx, parentObjIdx = -1, data = {}) {
    if (!data) return
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    for (const k in data) {
      obj[k] = data[k]
    }
  },
  // 添加文本对象
  addTxtObj: function(sceneIdx, options = {}, cb) {
    options.content = utils.trim(options.content, true)
    if (!options.content) return
    this.sceneIdx = sceneIdx
    const fontSize = 12
    options.name = options.content || '新文本'
    options.cols = fontSize * 12
    options.rows = fontSize + 6
    options.type = 'txt'
    options.txt = {
      content: options.content || '请双击编辑...',
      fontColor: options.color || '#000000',
      fontWeight: 'normal',
      fontSize: fontSize,
      fontFamily: 'pixel',
      textAlign: 'left',
      textBaseline: 'top'
    }
    this.addObj(sceneIdx, this.createObj(options))
  },
  loadRemoteImage: function(image, cb) {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', image, true)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function() {
      if (this.status === 200) {
        const uInt8Array = new Uint8Array(this.response)
        const len = uInt8Array.length
        const binaryString = new Array(len)
        for (let i = 0; i < uInt8Array.length; i++) {
          binaryString[i] = String.fromCharCode(uInt8Array[i])
        }
        const data = binaryString.join('')
        const base64 = window.btoa(data)
        cb && cb('data:image/png;base64,' + base64)
      }
    }
    xhr.onerror = function() {
      cb && cb()
    }
    xhr.send()
  },
  // 图片加载器（支持跨域） 返回 base64
  imageLoader: function(image, cb) {
    if (!image) return cb && cb('')
    // 支持 图片网址 url、 图片blob URL、 图片base64、 canvas Element、 image Element、 file
    if (utils.isFile(image)) {
      utils.fileToBase64(image, cb)
    } else if (utils.isImage(image)) {
      this.drawImage(this.canvasElm, image, image.width, image.height)
      cb && cb(this.getBase64())
    } else if (utils.isCanvas(image)) {
      cb && cb(this.getBase64(image))
    } else if (utils.isBase64(image)) {
      cb && cb(image)
    } else if (utils.isUrl(image) || utils.isBlobUrl(image)) {
      this.loadRemoteImage(image, cb)
    } else {
      cb && cb('')
    }
  },
  // 导入图片
  importImage: function(sceneIdx, image, name, cb, options = {}) {
    options.calcSize = true
    this.replaceImage(sceneIdx, -1, -1, image, name, cb, options)
  },
  // 重载（还原）
  reloadImage: function(sceneIdx, objIdx, parentObjIdx, type, cb, options = {}) {
    if (type === 'origin') {
      this.reloadOriginImage(sceneIdx, objIdx, parentObjIdx, cb, options)
    } else {
      this.reloadThumbImage(sceneIdx, objIdx, parentObjIdx, cb, options)
    }
  },
  // 重载原图（还原）
  reloadOriginImage: function(sceneIdx, objIdx, parentObjIdx, cb, options = {}) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (!obj.originImage || !obj.thumbImage) {
      cb && cb(false)
    } else {
      if (typeof options.calcSize === 'undefined') options.calcSize = true
      this.replaceImage(sceneIdx, objIdx, parentObjIdx, obj.originImage || obj.thumbImage, obj.name, cb, options)
    }
  },
  // 重载缩略图（还原）
  reloadThumbImage: function(sceneIdx, objIdx, parentObjIdx, cb, options = {}) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (!obj.thumbImage || !obj.originImage) {
      cb && cb(false)
    } else {
      if (typeof options.calcSize === 'undefined') options.calcSize = true
      this.replaceImage(sceneIdx, objIdx, parentObjIdx, obj.thumbImage || obj.originImage, obj.name, cb, options)
    }
  },
  // 替换图片
  replaceImage: function(sceneIdx, objIdx, parentObjIdx, image, name, cb, options = {}) {
    this.sceneIdx = sceneIdx
    this.objIdx = objIdx
    this.parentObjIdx = parentObjIdx
    const replaceColor = options.replaceColor || ''
    const handle = (originImage, originImageBase64) => {
      let obj = {}
      let newObj = true
      if (objIdx >= 0) {
        // 重新加载（还原） 或 图片替换
        obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
        newObj = false
      }
      if (!obj.id) {
        // 插入图片
        obj = this.createObj()
        newObj = true
        options.calcSize = true
        obj.x = options.x || 0
        obj.y = options.y || 0
      }
      if (typeof options.type !== 'undefined') {
        obj.type = options.type
      } else {
        obj.type = 'image'
      }
      if (options.calcSize) {
        if (typeof options.brickSizeId === 'undefined') {
          this.brickSizeId = ''
        } else {
          this.brickSizeId = options.brickSizeId
        }
        this.ratioid = options.ratioid || ''
        this.autoSize(originImage.width, originImage.height)
        obj.cols = this.objCols
        obj.rows = this.objRows
      } else if (options.cols && options.rows) {
        obj.cols = options.cols
        obj.rows = options.rows
      }
      if (typeof name !== 'undefined') obj.name = name || '未命名'
      obj.originUrl = utils.isUrl(image) ? image : ''
      this.drawImage(this.canvasElm, originImage, obj.cols, obj.rows)
      const imageData = this.getPixelData('', { replaceColor: replaceColor, colorFilter: options.colorFilter })
      obj.colors = imageData.colors
      obj.data = imageData.data
      // 新对象或者图片替换时，保存原图和缩略图
      if (newObj || options.repalce) {
        obj.originImage = originImageBase64
        obj.thumbImage = this.getBase64()
      }
      // console.error('replaceImage.obj', options, obj, obj.cols, obj.rows, originImage, { originImage: originImage.src })
      if (newObj) {
        this.addObj(sceneIdx, obj)
        if (typeof options.paletteId !== 'undefined') this.setObjPalette(sceneIdx, 0, -1, options.paletteId, options.denoiseFactor, options.limitColorNums)
      } else {
        this.setObj(sceneIdx, objIdx, parentObjIdx, obj)
        if (typeof options.paletteId !== 'undefined') this.setObjPalette(sceneIdx, objIdx, parentObjIdx, options.paletteId, options.denoiseFactor, options.limitColorNums)
      }
      cb && cb(true)
    }
    const loadImage = (base64) => {
      const load = () => {
        const img = new Image()
        img.src = base64
        img.onload = () => {
          handle(img, base64)
        }
        img.onerror = () => {
          cb && cb(false)
        }
      }
      if (options.compress) {
        this.compressImage(name, base64, (data) => {
          base64 = data.base64
          load()
        })
      } else {
        load()
      }
    }
    if (utils.isImage(image)) {
      handle(image, image.src)
    } else {
      this.imageLoader(image, (base64) => {
        if (!base64) {
          cb && cb(false)
        } else {
          loadImage(base64)
        }
      })
    }
    // if (utils.isImage(image)) {
    //   handle(image, image.src)
    // } else if (utils.isCanvas(image)) {
    //   handle(image)
    // } else if (utils.isBlobUrl(image) || utils.isBase64(image)) {
    //   loadImage(image)
    // } else {
    //   this.imageLoader(image, (originImage) => {
    //     if (!originImage) {
    //       cb && cb(false)
    //     } else {
    //       loadImage(originImage)
    //     }
    //   })
    // }
  },
  // 清空画布
  clearCanvas(canvas) {
    canvas = canvas || this.canvasElm
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  },
  // 绘制图片
  drawImageBase64(canvas, base64, width, height, cb) {
    const image = new Image()
    image.src = base64
    image.onload = () => {
      canvas = canvas || this.canvasElm
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = false
      ctx.clearRect(0, 0, width, height)
      ctx.drawImage(image, 0, 0, width, height)
      cb && cb(true)
    }
    image.onerror = () => {
      cb && cb(false)
    }
  },
  // 绘制图片
  drawImage(canvas, image, width, height) {
    canvas = canvas || this.canvasElm
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = false
    ctx.clearRect(0, 0, width, height)
    ctx.drawImage(image, 0, 0, width, height)
  },
  // 获取图片base64数据
  getBase64(canvas) {
    canvas = canvas || this.canvasElm
    return canvas.toDataURL('image/png')
  },
  // 获取图片像素数据
  getPixelData(canvas, options = {}) {
    let ctx
    if (canvas) {
      ctx = canvas.getContext('2d')
    } else {
      canvas = this.canvasElm
      ctx = this.ctx
    }
    const replaceColor = options.replaceColor || ''
    const imageDt = ctx.getImageData(0, 0, canvas.width, canvas.height)
    let pixelArr = imageDt.data
    if (options.colorFilter) pixelArr = this.applyColorFilter(pixelArr, options.colorFilter)
    const imageData = {
      width: imageDt.width,
      height: imageDt.height,
      colors: {},
      data: []
    }
    let i = 0
    const len = imageDt.width * imageDt.height
    for (i = 0; i < len; i++) {
      if (!pixelArr[i * 4 + 3]) {
        imageData.data.push('')
      } else {
        const color = replaceColor || utils.rgb2hex([pixelArr[i * 4], pixelArr[i * 4 + 1], pixelArr[i * 4 + 2]])
        imageData.data.push(color)
        if (imageData.colors[color]) {
          imageData.colors[color]++
        } else {
          imageData.colors[color] = 1
        }
      }
    }
    return imageData
  },
  // 应用滤镜
  applyColorFilter: function(pixelArr, filter) {
    if (pixelArr) {
      // eslint-disable-next-line
      if (typeof filter.hue === 'number' && typeof filter.saturation === 'number' && typeof filter.value === 'number') pixelArr = applyHSVAdjustment(pixelArr, filter.hue, filter.saturation / 100, filter.value / 100)
      // eslint-disable-next-line
      if (typeof filter.brightness === 'number') pixelArr = applyBrightnessAdjustment(pixelArr, filter.brightness)
      // eslint-disable-next-line
      if (typeof filter.contrast === 'contrast') pixelArr = applyContrastAdjustment(pixelArr, filter.contrast)
    }
    return pixelArr
  },
  // 获取对象像素数据 ???
  getObjPixelData: function(sceneIdx, objIdx, parentObjIdx = -1) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    return { cols: obj.cols, rows: obj.rows, data: obj.data }
  },
  // 获取对象原图 Base64
  getObjOriginImage: function(sceneIdx, objIdx, parentObjIdx = -1) {
    return this.getObj(sceneIdx, objIdx, parentObjIdx).originImage
  },
  // 获取对象缩略图 Base64
  getObjThumbImage: function(sceneIdx, objIdx, parentObjIdx = -1) {
    return this.getObj(sceneIdx, objIdx, parentObjIdx).thumbImage
  },
  // 获取对象颜色表
  getObjColors: function(sceneIdx, objIdx, parentObjIdx = -1) {
    return this.getObj(sceneIdx, objIdx, parentObjIdx).colors
  },
  // 获取作品帧数据
  getWorkData: function() {
    const workData = []
    for (var idx = 0; idx < this.file.canvas.scenes.length; idx++) {
      workData[idx] = this.getSceneData(idx)
    }
    return workData
  },
  // 获取作品帧图片
  getWorkImages: function(options = {}) {
    const workImage = []
    for (var idx = 0; idx < this.file.canvas.scenes.length; idx++) {
      workImage[idx] = this.getSceneImage(idx, options)
    }
    return workImage
  },
  // 获取调色板数据
  getPaletteArr: function(paletteId) {
    const palettes = conf.palette.data
    let paletteArr = []
    if (paletteId && palettes[paletteId]) {
      paletteArr = palettes[paletteId]
    } else if (conf.colorfyDt && conf.colorfyDt[paletteId]) {
      paletteArr = Object.values(conf.colorfyDt[paletteId].palette)
    }
    return paletteArr
  },
  // 上色
  colorfy: function(data, colorfyId = '') {
    const colorfyPaletteObj = this.getColorfyPaletteObj(colorfyId)
    if (colorfyPaletteObj) {
      for (const k in data) {
        if (data[k]) {
          data[k] = colorfyPaletteObj[data[k]] || data[k]
        }
      }
    }
    return data
  },
  // 获取替换的调色板数据
  getColorfyPaletteObj: function(colorfyId) {
    return (colorfyId && conf.colorfyDt[colorfyId]) ? (conf.colorfyDt[colorfyId].palette || '') : ''
  },
  // 获取场景数据
  getSceneData: function(sceneIdx, options = {}) {
    const canvas = this.canvas()
    const scene = canvas.scenes[sceneIdx]
    let gridColor = (canvas.showGrid && canvas.gridColor) ? canvas.gridColor : ''
    let bgColor = (canvas.showBg && canvas.bgColor) ? canvas.bgColor : ''
    if (gridColor) gridColor = gridColor.toUpperCase()
    if (bgColor) bgColor = bgColor.toUpperCase()
    let paletteArr = []
    if (options.applyPalette && options.paletteId) paletteArr = this.getPaletteArr(options.paletteId)
    const imageData = new Array(canvas.rows * canvas.cols).fill('')
    const colors = {}
    const palette = {}
    let colorNums = 0
    // 获取对象数据
    const getData = (obj, offsetX, offsetY) => {
      if (!obj.show) {
        return
      }
      const matchMap = {}
      for (const i in obj.data) {
        if (obj.data[i]) {
          if (paletteArr.length) {
            // 使用调色板
            const color = obj.data[i]
            let matchColor
            if (matchMap[color]) {
              matchColor = matchMap[color]
            } else {
              matchColor = utils.bestMatch(paletteArr, obj.data[i]).toUpperCase()
              matchMap[color] = matchColor
            }
            obj.data[i] = matchColor
          }
          let objY = Math.floor(i / obj.cols)
          let objX = i - objY * obj.cols
          objX = objX + obj.x + offsetX
          objY = objY + obj.y + offsetY
          if (objY < canvas.rows && objX < canvas.cols && objX >= 0 && objY >= 0) {
            const color = obj.data[i].toUpperCase()
            imageData[objY * canvas.cols + objX] = color
            if (colors[color]) {
              colors[color] = colors[color] + 1
            } else {
              colorNums++
              colors[color] = 1
              palette[color] = true
            }
          }
        }
      }
    }
    const objs = scene.objs
    Object.keys(objs).reverse().map((idx) => {
      const obj = objs[idx]
      if (obj.show) {
        if (obj.type === 'group') {
          Object.keys(obj.objs).reverse().map((i) => (getData(obj.objs[i], obj.x, obj.y)))
        } else {
          getData(obj, 0, 0)
        }
      }
    })
    return { name: scene.name, bgColor: bgColor, gridColor: gridColor, gridSize: this.file.gridSize, fillShape: scene.fillShape || this.file.fillShape || '', imageData: imageData, colorNums: colorNums, colors: colors, palette: palette, cols: canvas.cols, rows: canvas.rows }
  },
  // 获取作品来源类型 来源；0=原生创作; 1=二次创作; 2=导图创作;
  getOriginType: function(file) {
    file = file || this.file
    const types = []
    if (file.refFileId || file.refWorkid) types.push(1)
    for (var sceneIdx = 0; sceneIdx < file.canvas.scenes.length; sceneIdx++) {
      if (file.canvas.scenes[sceneIdx].refSceneId) types.push(1)
      const objs = file.canvas.scenes[sceneIdx].objs
      Object.keys(objs).map((idx) => {
        const obj = objs[idx]
        if (obj.type === 'group') {
          Object.keys(obj.objs).map((i) => {
            if (obj.objs[i].originUrl || obj.objs[i].thumbImage) {
              types.push(2)
            }
          })
        } else {
          if (obj.originUrl || obj.thumbImage) {
            types.push(2)
          }
        }
      })
    }
    return Math.max(Math.max(...types), 0)
  },
  // 创建矩形盒子
  createBox: function(cols, rows, color = '', x = 0, y = 0) {
    const box = {}
    box.x = x
    box.y = y
    box.w = cols
    box.h = rows
    box.data = new Array(cols * rows).fill(color)
    return box
  },
  // 创建包豪斯图案
  createBHSObj: function(cols, rows, paletteArr = []) {
    if (!paletteArr.length) paletteArr = paletteArr.concat(conf.defaultBgColor)
    const border = '#111111'
    const box = this.createBox(cols, rows, border)
    let x = 0
    let y = 0
    let w = Math.floor(cols * 0.15)
    let h = Math.floor(rows * 0.4)
    let shape = this.createBox(w, h, paletteArr[0], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    y = h + 2
    shape = this.createBox(w, h, paletteArr[1], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    y = 2 * h + 4
    h = rows - y
    shape = this.createBox(w, h, paletteArr[2], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    x = Math.floor(cols * 0.15) + 2
    y = 0
    w = Math.floor(cols * 0.5)
    h = Math.floor(rows * 0.2)
    shape = this.createBox(w, h, paletteArr[0], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    y = h + 2
    w = Math.floor(cols * 0.5)
    h = Math.floor(rows * 0.6)
    shape = this.createBox(w, h, paletteArr[3], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    y = y + h + 2
    w = Math.floor(cols * 0.7)
    h = rows - y
    shape = this.createBox(w, h, paletteArr[0], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    x = Math.floor(cols * 0.15) + 2 + Math.floor(cols * 0.5) + 2
    y = 0
    w = cols - x
    h = Math.floor(rows * 0.2)
    shape = this.createBox(w, h, paletteArr[1], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    y = h + 2
    w = Math.floor(cols * 0.15)
    h = Math.floor(rows * 0.4)
    shape = this.createBox(w, h, paletteArr[0], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    x = x + w + 2
    w = cols - x
    h = Math.floor(rows * 0.4)
    shape = this.createBox(w, h, border, x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    const lastY = Math.floor(rows * 0.4) * 2 + 4
    x = Math.floor(cols * 0.15) + 2 + Math.floor(cols * 0.5) + 2
    y = y + h + 2
    w = cols - x
    h = rows - y - 2 - (rows - lastY)
    shape = this.createBox(w, h, paletteArr[2], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)

    x = Math.floor(cols * 0.15) + 2 + Math.floor(cols * 0.7) + 2
    y = lastY
    w = cols - x
    h = rows - lastY
    shape = this.createBox(w, h, paletteArr[3], x, y)
    this.parseData(box, shape.x, shape.y, shape.w, shape.h, shape.data)
    box.cols = box.w
    box.rows = box.h
    return box
  },
  // 创建随机几何图案
  createGeoObj: function(cols, rows, bgIdx, rotate = false) {
    if (bgIdx === null || isNaN(bgIdx) || typeof bgIdx === 'undefined') {
      if (utils.getRndInt(0, 10) < 2) {
        return this.createBHSObj(cols, rows)
      }
      bgIdx = utils.getRndInt(0, 4)
    }
    bgIdx = bgIdx || 0
    cols = Math.ceil(cols / 16) * 16
    rows = Math.ceil(rows / 16) * 16
    const bgSize = 32
    const newCols = Math.ceil(cols / bgSize) * bgSize
    const newRows = Math.ceil(rows / bgSize) * bgSize
    const partCols = Math.ceil(newCols / bgSize)
    const partRows = Math.ceil(newRows / bgSize)
    const geoObjs = geometry.geoObjs
    let box = {}
    box.x = 0
    box.y = 0
    box.w = newCols
    box.h = newRows
    box.cols = newCols
    box.rows = newRows
    box.data = new Array(newCols * newRows).fill('')
    const obj = utils.deepClone(geoObjs[bgIdx] || geoObjs[0])
    if (rotate) this.rotateI(obj)
    // 获取平铺的背景图
    for (let c = 0; c < partCols; c++) {
      for (let r = 0; r < partRows; r++) {
        this.parseData(box, c * bgSize, r * bgSize, obj.cols, obj.rows, obj.data)
      }
    }
    // 截取用到的部分背景图
    box = this.getPartObj(0, 0, -1, { x: 0, y: 0, w: cols, h: rows }, box)
    box.cols = cols
    box.rows = rows
    box.w = cols
    box.h = rows
    return box
  },
  // 加边框
  getBorderBox: function(cols, rows, mainData = []) {
    const box = { data: new Array(cols * rows).fill('#111111') }
    for (const i in mainData) {
      if (mainData[i]) {
        box.data[i] = ''
      }
    }
    for (const i in mainData) {
      box.data[i] = this.getBgColor(cols, rows, i, box.data[i], mainData, {}, '#FEFBF5')
    }
    return box
  },
  // 获取背景颜色（忽略非透明色+边缘填色）
  getBgColor: function(cols, rows, i, color, mainData = [], colorMap = {}, edgeColor = '#FFFFFF') {
    i = parseInt(i)
    if (color && !mainData[i]) {
      const maxI = cols * rows
      if (i + 1 < maxI && mainData[i + 1] && (i + 1) % cols > 0) return edgeColor
      if (i - 1 >= 0 && mainData[i - 1] && i % cols > 0) return edgeColor
      if (i - cols >= 0 && mainData[i - cols]) return edgeColor
      if (i + cols < maxI && mainData[i + cols]) return edgeColor
      return colorMap[color] || color
    }
    return ''
  },
  // 获取背景盒子
  getBgBox: function(cols, rows, bgIdx, allPaletteArr = [], limitColorNums = 0, mainData = {}, mainColors = {}, faceBox = {}, rotate = false, offset = true) {
    const box = this.createGeoObj(cols, rows, bgIdx, rotate)
    if (!allPaletteArr.length) allPaletteArr = allPaletteArr.concat(conf.defaultBgColor)
    // 背景用色数统计
    let colors = {}
    for (const i in box.data) {
      if (box.data[i] && !mainData[i]) {
        if (colors[box.data[i]]) {
          colors[box.data[i]] = colors[box.data[i]] + 1
        } else {
          colors[box.data[i]] = 1
        }
      }
    }
    let pObj = {}
    for (const i in allPaletteArr) {
      pObj[allPaletteArr[i]] = true
    }
    pObj = utils.sortColors(pObj)
    const pArr = Object.keys(pObj)
    // 删除最深色
    pArr.shift()
    // 可用色统计
    const availableColors = {}
    for (const i in pArr) {
      const availableNums = mainColors[pArr[i]] ? (limitColorNums - mainColors[pArr[i]]) : limitColorNums
      if (availableNums > 0 || !limitColorNums) {
        availableColors[pArr[i]] = availableNums
      }
    }
    // 颜色映射
    const colorMap = {}
    // 颜色数多到少排序
    colors = utils.sortObj(colors)
    const colorsArr = Object.keys(colors)
    // 可用颜色数多到少排序
    const aColors = utils.sortObj(availableColors)
    const aColorsArr = Object.keys(aColors)
    let colorIdx = 0
    for (const i in colorsArr) {
      const c = colorsArr[i]
      let ac
      if (aColorsArr[i]) {
        ac = aColorsArr[i]
      } else {
        ac = aColorsArr[colorIdx] || aColorsArr[0]
        colorIdx++
      }
      colorMap[c] = ac
      aColors[ac] = aColors[ac] - colors[c]
    }
    // box.colorMap = colorMap
    // box.colors = colors
    // box.mainColors = mainColors
    // box.aColors = aColors
    // box.pArr = pArr
    box.allPaletteArr = allPaletteArr
    const totalColors = utils.deepClone(mainColors)
    const useColors = {}
    const faceColors = {}
    let x, y
    for (const i in box.data) {
      // 仅填透明色
      box.data[i] = this.getBgColor(cols, rows, i, box.data[i], mainData, colorMap, allPaletteArr[0])
      if (box.data[i]) {
        if (useColors[box.data[i]]) {
          useColors[box.data[i]] = useColors[box.data[i]] + 1
        } else {
          useColors[box.data[i]] = 1
        }
        if (totalColors[box.data[i]]) {
          totalColors[box.data[i]] = totalColors[box.data[i]] + 1
        } else {
          totalColors[box.data[i]] = 1
        }
      }
      y = Math.floor(i / box.cols)
      x = i - y * box.cols
      if (mainData[i] && faceBox.w && faceBox.h && x >= faceBox.x && x < faceBox.x + faceBox.w && y >= faceBox.y && y < faceBox.y + faceBox.h) {
        if (faceColors[mainData[i]]) {
          faceColors[mainData[i]] = faceColors[mainData[i]] + 1
        } else {
          faceColors[mainData[i]] = 1
        }
      }
    }
    // console.error('box.data', utils.deepClone(box.data), colorMap, allPaletteArr)
    // box.useColors = useColors
    // box.totalColors = totalColors
    const deepColorsArr = []
    const lightColorsArr = []
    for (const i in allPaletteArr) {
      if (i > 2) {
        deepColorsArr.push(allPaletteArr[i])
      } else {
        lightColorsArr.push(allPaletteArr[i])
      }
    }
    box.faceBox = faceBox
    // 获取相似色
    const getLikeColor = (c, n) => {
      let bestC = ''
      let bestN = 0
      if (deepColorsArr.indexOf(c) >= 0) {
        bestN = deepColorsArr.indexOf(c)
        if (bestN === 2 || bestN === 0) {
          bestN = 1
        } else if (bestN === 1) {
          bestN = 2
        }
        bestC = deepColorsArr[bestN]
      } else if (lightColorsArr.indexOf(c) >= 0) {
        bestN = lightColorsArr.indexOf(c)
        if (bestN === 2 || bestN === 0) {
          bestN = 1
        } else if (bestN === 1) {
          bestN = 0
        }
        bestC = lightColorsArr[bestN]
      }
      return bestC
    }
    const replaceColors = {}
    if (limitColorNums) {
      for (const c in totalColors) {
        if (totalColors[c] > limitColorNums) {
          const rColor = getLikeColor(c, totalColors[c] - limitColorNums)
          if (rColor) {
            replaceColors[c] = { color: getLikeColor(c, totalColors[c] - limitColorNums), count: 0, replaceCount: 0, rate: Math.ceil((totalColors[c] - limitColorNums) * 100 / (totalColors[c] - (faceColors[c] || 0))), nums: totalColors[c] - limitColorNums }
          }
        }
      }
    }
    box.deepColorsArr = deepColorsArr
    box.lightColorsArr = lightColorsArr
    box.replaceColors = replaceColors
    return box
  },
  // 计算拼图数据：使用规定的比例和大小
  calcBrickData: function(obj, bgColor = '', border = false) {
    // 尺寸是16的倍数
    let cols = obj.cols
    let rows = obj.rows
    // cols = Math.ceil(obj.cols / 16) * 16 + (border ? 2 : 0)
    // rows = Math.ceil(obj.rows / 16) * 16 + (border ? 2 : 0)
    const ratio = this.calcFrameRatio(obj.cols, obj.rows)
    cols = ratio.cols
    rows = ratio.rows
    const box = {}
    box.x = 0
    box.y = 0
    box.w = cols
    box.h = rows
    // box.data = new Array(cols * rows).fill(obj.data[0] || '')
    box.data = new Array(cols * rows).fill(obj.data.indexOf('') >= 0 ? bgColor || '' : (obj.data[0] || bgColor || ''))
    this.parseData(box, Math.floor((cols - obj.cols) / 2), Math.floor((rows - obj.rows) / 2), obj.cols, obj.rows, obj.data)
    return box
  },
  // 计算拼图块数量上限 // goodsSkuId：10001=积木拼图 10002=钻石拼图
  calcLimitColorNums(goodsSkuId, cols, rows, colors = 6) {
    // 6个颜色，积木都给1.4倍的拼图块数量（1.4不宜再调整，钻石给1.8倍）
    // 48*48每个颜色600颗粒积木 共3600颗粒（黑白加100颗，其他四色减100颗，最终3400颗）
    // 48*64每个颜色800颗粒积木 共4800颗粒（黑白加100颗，其他四色减100颗，最终4600颗）
    // 64*64每个颜色1000颗粒积木 共6000颗粒（黑白加100颗，其他四色减100颗，最终5800颗）
    // 80*80每个颜色1600颗粒积木 共9600颗粒（黑白加100颗，其他四色减100颗，最终9400颗）
    const factor = goodsSkuId === '10001' ? 1.4 : 1.8
    // 修正颗粒数，计算时各加200颗粒，实际包装时按需+200颗粒或-100颗粒，如： #1+200 #2-100 3#-100 4#-100 5#-100 6#+200
    return Math.ceil(cols * rows * factor / colors / 100) * 100 + conf.limitColorsConf.addNums
    // return Math.ceil(cols * rows * factor / colors / 100) * 100
  },
  // 匹配商品
  matchGoods: function(goodsSkuId, cols, rows, colors = [], colorfyId = 'dance') {
    let goodsSuite = {}
    let goodsBricks = {}
    if (colorfyId === 'classic' || colorfyId === 'golden' || colorfyId === 'dance') {
      const skuId = 'BA-' + cols + '-' + rows + '-' + (colorfyId === 'dance' ? 3 : (colorfyId === 'classic' ? 1 : 2))
      if (!conf.brickSkus[skuId]) return []
      const goodsId = conf.brickSkus[skuId].id
      const price = conf.brickSkus[skuId].price
      const name = conf.brickSkus[skuId].name
      const info = conf.brickSkus[skuId].info || ''
      let image = ''
      if (conf.brickSkus[skuId].image) {
        image = conf.brickSkus[skuId].image
      }
      const limitColorNums = this.calcLimitColorNums(goodsSkuId, cols, rows)
      const newColors = []
      for (const i in colors) {
        if (colors[i][1] > limitColorNums) {
          newColors.push([colors[i][0], colors[i][1] - limitColorNums, colors[i][2], colors[i][3]])
        }
      }
      goodsSuite = { skuId: skuId, goodsId: goodsId, price: price, name: name, info: info, image: image, cols: cols, rows: rows, num: 1, hide: false }
      goodsBricks = this.getBricks(newColors, goodsSkuId)
    }
    return { goodsSuite: goodsSuite, goodsBricks: goodsBricks }
  },
  // 计算画框比例和大小
  calcFrameRatio: function(cols, rows, frameId = '') {
    let ratioId = ''
    let closeRatioId = ''
    let closeCols = 256
    let closeRows = 256
    let area = closeCols * closeRows
    for (const k in conf.sizeOpts) {
      for (const i in conf.sizeOpts[k]) {
        if (conf.sizeOpts[k][i].cols >= cols && conf.sizeOpts[k][i].rows >= rows) {
          const diff = conf.sizeOpts[k][i].cols * conf.sizeOpts[k][i].rows - cols * rows
          if (diff < area) {
            area = diff
            closeRatioId = k
            closeCols = conf.sizeOpts[k][i].cols
            closeRows = conf.sizeOpts[k][i].rows
          }
        }
      }
    }
    ratioId = closeRatioId
    cols = closeCols
    rows = closeRows
    const frameConf = this.getFrameConf(frameId)
    const num = 1
    let goodsId = 0
    let skuId = ''
    let price = 0
    let name = ''
    if (frameConf.name) {
      skuId = frameConf.skuStart + '-' + Math.min(cols, rows) + '-' + Math.max(cols, rows) + '-' + frameConf.skuEnd
      goodsId = conf.brickSkus[skuId] ? conf.brickSkus[skuId].id : 0
      price = conf.brickSkus[skuId] ? conf.brickSkus[skuId].price * num : 0
      name = cols + 'x' + rows + ' ' + frameConf.name
    }
    return { ratioId: ratioId, cols: cols, rows: rows, goodsId: goodsId, skuId: skuId, num: num, price: price, hide: !frameId || !price, frameId: frameId, name: name }
  },
  // 获取拼图颗粒 // goodsSkuId：10001=积木拼图 10002=钻石拼图
  getBricks(colors, goodsSkuId = '10002') {
    let bricks = []
    for (const i in colors) {
      bricks = bricks.concat(...this.countPacks(colors[i], goodsSkuId))
    }
    return bricks
  },
  // 拼图拼图包数量 // goodsSkuId：10001=积木拼图 10002=钻石拼图
  countPacks(colorDt, goodsSkuId = '10002') {
    let pack5 = 0
    let pack3 = 0
    let pack1 = 0
    let nums = colorDt[1]
    if (goodsSkuId === '10002') {
      // 钻石拼图，500颗粒一包
      pack5 = Math.ceil(nums / 500)
      nums = 0
      pack3 = 0
    } else {
      if (nums >= 500) {
        pack5 = Math.floor(nums / 500)
        nums = nums % 500
        if (nums > 400) {
          pack5++
          nums = 0
        }
      }
      pack3 = Math.ceil(nums / 300)
    }
    pack1 = 0
    // 暂时不支持100颗粒的
    // if (nums >= 300) {
    //   pack3 = Math.floor(nums / 300)
    //   nums = nums % 300
    //   if (nums > 200) {
    //     pack3++
    //     nums = 0
    //   }
    // }
    // pack1 = Math.ceil(nums / 100)
    const packs = []
    let goodsId = 0
    let skuId = ''
    let price = 0
    if (pack5) {
      skuId = 'BD-P500-' + colorDt[2]
      price = conf.brickSkus[skuId] ? conf.brickSkus[skuId].price * pack5 : 0
      goodsId = conf.brickSkus[skuId] ? conf.brickSkus[skuId].id : 0
      packs.push({ color: colorDt[0], brickId: colorDt[2], count: 500, goodsId: goodsId, skuId: skuId, num: pack5, price: price, hide: colorDt[3] || !price })
    }
    if (pack3) {
      skuId = 'BD-P300-' + colorDt[2]
      price = conf.brickSkus[skuId] ? conf.brickSkus[skuId].price * pack3 : 0
      goodsId = conf.brickSkus[skuId] ? conf.brickSkus[skuId].id : 0
      packs.push({ color: colorDt[0], brickId: colorDt[2], count: 300, goodsId: goodsId, skuId: skuId, num: pack3, price: price, hide: colorDt[3] || !price })
    }
    if (pack1) {
      skuId = 'BD-P100-' + colorDt[2]
      price = conf.brickSkus[skuId] ? conf.brickSkus[skuId].price * pack1 : 0
      goodsId = conf.brickSkus[skuId] ? conf.brickSkus[skuId].id : 0
      packs.push({ color: colorDt[0], brickId: colorDt[2], count: 100, goodsId: goodsId, skuId: skuId, num: pack1, price: price, hide: colorDt[3] || !price })
    }
    return packs
  },
  // 获取拼图画框配置
  getFrameConf: function(frameId) {
    if (!frameId) return {}
    return conf.frameDt[frameId] || {}
  },
  // 渲染特定场景
  drawScene: function(canvas, sceneIdx, options = {}) {
    if (utils.empty(options.darkMatch)) {
      options.darkMatch = true
    }
    const sceneData = this.getSceneData(sceneIdx, options)
    let paletteArr = this.getPaletteArr(options.paletteId)
    if ((this.file.type === 1 && options.paletteId === 'brickfy')) {
      // 已经是拼图，不再二次拼图化处理
      paletteArr = []
    }
    if (paletteArr.length) {
      const newData = this.setPalette(sceneData.imageData, paletteArr, options.paletteId, options.denoiseFactor, options.limitColorNums, options.darkMatch)
      sceneData.imageData = newData.data
    }
    const ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = false
    const paperMod = options.paperMod || false
    const gridSize = options.gridSize || sceneData.gridSize
    let fillShape = options.fillShape || sceneData.fillShape
    let sceneBgColor = sceneData.bgColor || ''
    let bgColor = options.bgColor || sceneData.bgColor
    let gridColor = options.gridColor || sceneData.gridColor
    let brickfy
    if (typeof options.brickfy !== 'undefined') {
      brickfy = options.brickfy
    } else {
      brickfy = this.file.brickfy
    }
    const colorfyPaletteObj = this.getColorfyPaletteObj(options.colorfyId)
    // workTypeid = 2 为拼图作品类型
    if (options.workTypeid === 2) {
      brickfy = true
    }
    const matchBrickDt = options.darkMatch ? conf.darkenedBrickDt : conf.brickDt
    const brickfyPaletteArr = Object.keys(matchBrickDt)
    // 颜色替换
    if (colorfyPaletteObj) {
      if (bgColor) bgColor = colorfyPaletteObj[bgColor] || bgColor
      if (sceneBgColor) sceneBgColor = colorfyPaletteObj[sceneBgColor] || sceneBgColor
      if (gridColor) gridColor = colorfyPaletteObj[gridColor] || gridColor
      if (options.baseBgColor) options.baseBgColor = colorfyPaletteObj[options.baseBgColor] || options.baseBgColor
    }
    const frameConf = this.getFrameConf(options.frameId)
    // 图纸模式，绘制网格
    if (paperMod) {
      bgColor = frameConf.bg || '#000000'
      gridColor = frameConf.grid || '#000000'
    }
    // 圆盖效果图模式
    if (brickfy && typeof options.roundTile === 'undefined') options.roundTile = true
    if (brickfy) {
      if (options.roundTile) {
        // bgColor = '#000000'
        // gridColor = '#000000'
        bgColor = '#EEEEEE'
        gridColor = '#FFFFFF'
      } else {
        fillShape = ''
        gridColor = ''
      }
    }
    // 附加背景预处理
    let bgBox = {}
    const bgArr = options.bgId ? options.bgId.split('-') : []
    let bgIdx = -1
    const allPaletteArr = Object.values(colorfyPaletteObj)
    const calcBrickData = () => {
      if (brickfy && !options.isBrick) {
        let defaultBgColor
        if (sceneBgColor) {
          defaultBgColor = utils.bestMatch(allPaletteArr, sceneBgColor).toUpperCase() || sceneBgColor
        } else {
          defaultBgColor = utils.bestMatch(allPaletteArr, bgColor).toUpperCase() || bgColor
        }
        // 补齐拼图数据
        let bg = (bgIdx >= 0 || options.bgId === 'origin') ? '' : defaultBgColor
        if (options.bgId === 'white') bg = '#FEFBF5'
        const objBox = this.calcBrickData({ data: sceneData.imageData, cols: sceneData.cols, rows: sceneData.rows }, bg)
        sceneData.imageData = objBox.data
        sceneData.cols = objBox.w
        sceneData.rows = objBox.h
      }
    }
    if (options.bgId && bgArr.length > 1 && bgArr[1] > 0) {
      bgIdx = parseInt(bgArr[1]) - 1
      calcBrickData()
      bgBox = this.getBgBox(sceneData.cols, sceneData.rows, bgIdx, allPaletteArr, 0, sceneData.imageData)
      // console.error('drawScene.getBgBox', bgIdx, allPaletteArr, sceneData.imageData, bgBox)
    } else {
      calcBrickData()
      if (options.bgId === 'origin') {
        bgBox = this.getBorderBox(sceneData.cols, sceneData.rows, sceneData.imageData)
      }
    }
    canvas.width = sceneData.cols * gridSize
    canvas.height = sceneData.rows * gridSize
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    if (bgColor || options.baseBgColor) {
      this.drawRect(ctx, 0, 0, canvas.width, canvas.height, bgColor || options.baseBgColor)
    }
    let x, y
    const matchMap = {}
    // const brickMap = {}
    const hideColor = utils.isArray(options.hideColors) && options.hideColors.length > 0
    for (const i in sceneData.imageData) {
      y = Math.floor(i / sceneData.cols)
      x = i - y * sceneData.cols
      this.drawGridBorder(ctx, x, y, gridSize, bgColor, gridColor)
      let color = sceneData.imageData[i] ? sceneData.imageData[i].toUpperCase() : ''
      let matchColor = color
      if (!hideColor) {
        if (brickfy && !options.isBrick) {
          if (bgBox.data && bgBox.data[i]) {
            matchColor = bgBox.data[i]
          } else {
            color = color || frameConf.base || '#FFFFFF'
            if (matchMap[color]) {
              matchColor = matchMap[color]
            } else {
              if (!conf.brickDt[matchColor]) {
                if (matchBrickDt[matchColor]) {
                  matchColor = conf.brickfy[matchBrickDt[matchColor]]
                } else {
                  matchColor = utils.bestMatch(brickfyPaletteArr, color).toUpperCase()
                  matchColor = conf.brickfy[matchBrickDt[matchColor]] || matchColor
                }
              }
              matchMap[color] = matchColor
              // brickMap[conf.brickDt[matchColor]] = matchColor
            }
          }
        }
        // 颜色替换
        if (colorfyPaletteObj && matchColor) {
          matchColor = colorfyPaletteObj[matchColor] || matchColor
        }
      }
      sceneData.imageData[i] = matchColor
      let hide = false
      if ((color && hideColor && options.hideColors.indexOf(color.toUpperCase()) >= 0) || (matchColor && hideColor && options.hideColors.indexOf(matchColor.toUpperCase()) >= 0)) {
        // 隐藏颜色
        hide = true
      }
      // this.drawGrid(ctx, x, y, matchColor, gridSize, fillShape, false, brickfy || options.isBrick, paperMod, hide, frameConf, options.roundTile, this.file)
      this.drawGrid(ctx, x, y, matchColor, gridSize, fillShape, false, brickfy || options.isBrick, paperMod, hide, frameConf, options.roundTile, options.CLBrick, options.hideGridBorder)
    }
    // console.error('drawScene.data', options, paletteArr, utils.deepClone(sceneData), options.hideColors, hideColor, colorfyPaletteObj, gridSize, fillShape, brickfy, options.isBrick, paperMod, options.roundTile)
    if (options.fn) options.fn({ data: sceneData.imageData, cols: sceneData.cols, rows: sceneData.rows })
    if (options.addWatermark) this.addWatermark(canvas, ctx)
  },
  // 获取场景渲染后图片 Base64
  getSceneImage: function(sceneIdx, options = {}) {
    this.drawScene(this.canvasElm, sceneIdx, options)
    return this.getBase64()
  },
  // 获取上色后的obj对象
  getColorfyObj(obj, colorfyId) {
    if (!obj || !colorfyId) return obj
    const colorfyPaletteObj = this.getColorfyPaletteObj(colorfyId)
    if (!colorfyPaletteObj || !obj.data) return obj
    for (const i in obj.data) {
      if (obj.data[i]) {
        obj.data[i] = colorfyPaletteObj[obj.data[i]] || obj.data[i]
      }
    }
    return obj
  },
  // 渲染对象
  drawObj: function(canvas, sceneIdx, objIdx, parentObjIdx = -1, options = {}, obj = null) {
    if (utils.empty(options.darkMatch)) {
      options.darkMatch = true
    }
    if (!obj && typeof options.paletteId !== 'undefined') this.setObjPalette(sceneIdx, objIdx, parentObjIdx, options.paletteId, options.denoiseFactor, options.limitColorNums)
    if (typeof options.colorfy === 'undefined') options.colorfy = true
    obj = obj || this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (!options.colorfy) {
      // 不改变原对象颜色时，先拷贝
      obj = utils.deepClone(obj)
    }
    const ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = false
    const paperMod = options.paperMod || false
    const gridSize = options.gridSize || this.file.gridSize || ''
    let fillShape = options.fillShape || obj.fillShape || this.file.fillShape || ''
    // let bgColor = options.bgColor || ''
    // let gridColor = options.gridColor || ''
    let bgColor = options.bgColor || ((this.file.canvas.showBg && this.file.canvas.bgColor) ? this.file.canvas.bgColor : '')
    let gridColor = options.gridColor || ((this.file.canvas.showGrid && this.file.canvas.gridColor) ? this.file.canvas.gridColor : '')
    let brickfy
    if (typeof options.brickfy !== 'undefined') {
      brickfy = options.brickfy
    } else {
      brickfy = this.file.brickfy
    }
    // workTypeid = 2 为拼图作品类型
    if (options.workTypeid === 2) brickfy = true
    const matchBrickDt = options.darkMatch ? conf.darkenedBrickDt : conf.brickDt
    const brickfyPaletteArr = Object.keys(matchBrickDt)
    const colorfyPaletteObj = this.getColorfyPaletteObj(options.colorfyId)
    // 颜色替换
    if (colorfyPaletteObj) {
      if (bgColor) bgColor = colorfyPaletteObj[bgColor] || bgColor
      if (gridColor) gridColor = colorfyPaletteObj[gridColor] || gridColor
      if (options.baseBgColor) options.baseBgColor = colorfyPaletteObj[options.baseBgColor] || options.baseBgColor
    }
    const frameConf = this.getFrameConf(options.frameId)
    // 图纸模式，绘制网格
    if (paperMod) {
      bgColor = frameConf.bg || '#000000'
      gridColor = frameConf.grid || '#000000'
    }
    // 圆盖效果图模式
    if (brickfy && typeof options.roundTile === 'undefined') options.roundTile = true
    if (brickfy) {
      if (options.roundTile) {
        bgColor = '#000000'
        gridColor = '#000000'
      } else {
        fillShape = ''
        gridColor = ''
      }
    }
    let x, y
    const matchMap = {}
    // const brickMap = {}
    const hideColor = utils.isArray(options.hideColors) && options.hideColors.length > 0
    // 附加背景预处理
    let bgBox = {}
    // eslint-disable-next-line
    let replaceColors = {}
    // eslint-disable-next-line
    let deepColors = {}
    const faceBox = {}
    const bgArr = options.bgId ? options.bgId.split('-') : []
    let bgIdx = -1
    const allPaletteArr = Object.values(colorfyPaletteObj)
    const calcBrickData = () => {
      if (brickfy && !options.isBrick) {
        const defaultBgColor = utils.bestMatch(allPaletteArr, bgColor).toUpperCase() || bgColor
        // 补齐拼图数据
        const objBox = this.calcBrickData(obj, bgIdx >= 0 ? '' : defaultBgColor)
        obj.data = objBox.data
        obj.cols = objBox.w
        obj.rows = objBox.h
      }
    }
    if (options.bgId && bgArr.length > 1 && bgArr[1] > 0) {
      const objData = []
      const objColors = {}
      bgIdx = parseInt(bgArr[1]) - 1
      calcBrickData()
      // 主体颜色预处理
      for (const i in obj.data) {
        const color = obj.data[i] ? obj.data[i].toUpperCase() : ''
        let matchColor = color
        if (brickfy && !options.isBrick && color) {
          if (matchMap[color]) {
            matchColor = matchMap[color]
          } else {
            if (!conf.brickDt[matchColor]) {
              if (matchBrickDt[matchColor]) {
                matchColor = conf.brickfy[matchBrickDt[matchColor]]
              } else {
                matchColor = utils.bestMatch(brickfyPaletteArr, color).toUpperCase()
                matchColor = conf.brickfy[matchBrickDt[matchColor]] || matchColor
              }
            }
            matchMap[color] = matchColor
          }
        }
        // 颜色替换
        if (colorfyPaletteObj && matchColor) {
          matchColor = colorfyPaletteObj[matchColor] || matchColor
        }
        if (matchColor) {
          if (objColors[matchColor]) {
            objColors[matchColor]++
          } else {
            objColors[matchColor] = 1
          }
        }
        objData[i] = matchColor
      }
      if (options.faceBox.w && options.faceBox.h) {
        const rate = obj.cols / options.faceBox.width
        faceBox.width = obj.cols
        faceBox.height = obj.rows
        faceBox.w = Math.round(options.faceBox.w * rate)
        faceBox.h = Math.round(options.faceBox.h * rate)
        faceBox.x = Math.round(options.faceBox.x * rate)
        faceBox.y = Math.round(options.faceBox.y * rate)
      }
      bgBox = this.getBgBox(obj.cols, obj.rows, bgIdx, allPaletteArr, options.limitColorNums, objData, objColors, faceBox)
      replaceColors = bgBox.replaceColors || {}
      deepColors = bgBox.deepColors || {}
      // console.error('drawObj.getBgBox', options, bgIdx, options.limitColorNums, allPaletteArr, objData, objColors, bgBox, faceBox, options.faceBox)
    } else {
      calcBrickData()
    }
    canvas.width = obj.cols * gridSize
    canvas.height = obj.rows * gridSize
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    let transparent = true
    if (typeof options.transparent !== 'undefined') transparent = options.transparent
    if (options.baseBgColor) {
      transparent = false
      this.drawRect(ctx, 0, 0, canvas.width, canvas.height, options.baseBgColor)
    }
    for (const i in obj.data) {
      y = Math.floor(i / obj.cols)
      x = i - y * obj.cols
      this.drawGridBorder(ctx, x, y, gridSize, bgColor, gridColor)
      let color = obj.data[i] ? obj.data[i].toUpperCase() : ''
      let matchColor = color
      if (!hideColor) {
        if (brickfy && !options.isBrick) {
          if (bgBox.data && bgBox.data[i]) {
            matchColor = bgBox.data[i]
          } else {
            color = color || frameConf.base || '#FFFFFF'
            if (matchMap[color]) {
              matchColor = matchMap[color]
            } else {
              if (!conf.brickDt[matchColor]) {
                if (matchBrickDt[matchColor]) {
                  matchColor = conf.brickfy[matchBrickDt[matchColor]]
                } else {
                  matchColor = utils.bestMatch(brickfyPaletteArr, color).toUpperCase()
                  matchColor = conf.brickfy[matchBrickDt[matchColor]] || matchColor
                }
              }
              matchMap[color] = matchColor
              // brickMap[conf.brickDt[matchColor]] = matchColor
            }
          }
        }
        // 颜色替换
        if (colorfyPaletteObj && matchColor) {
          matchColor = colorfyPaletteObj[matchColor] || matchColor
        }
      }
      // 以下插值处理效果不理想，咱不开放
      // if (replaceColors[matchColor]) {
      //   // eslint-disable-next-line
      //   const isFaceArea = faceBox.w && faceBox.h && x >= faceBox.x && x <= faceBox.x + faceBox.w && y >= faceBox.y && y <= faceBox.y + faceBox.h
      //   if ((isFaceArea && deepColors[matchColor]) || !isFaceArea) {
      //     // 脸部深色以及非脸部的可以插值处理
      //     replaceColors[matchColor].count = replaceColors[matchColor].count + 1
      //     const factor = Math.max(2, Math.round(100 / replaceColors[matchColor].rate))
      //     if (replaceColors[matchColor].count % factor === 1) {
      //       replaceColors[matchColor].replaceCount = replaceColors[matchColor].replaceCount + 1
      //       matchColor = replaceColors[matchColor].color
      //     }
      //   }
      // }
      obj.data[i] = matchColor
      let hide = false
      if ((color && hideColor && options.hideColors.indexOf(color.toUpperCase()) >= 0) || (matchColor && hideColor && options.hideColors.indexOf(matchColor.toUpperCase()) >= 0)) {
        // 隐藏颜色
        hide = true
      }
      this.drawGrid(ctx, x, y, matchColor, gridSize, fillShape, transparent, brickfy || options.isBrick, paperMod, hide, frameConf, options.roundTile, options.CLBrick, options.hideGridBorder)
    }
    // console.error('drawObj.obj', options, utils.deepClone(obj), colorfyPaletteObj, gridSize, fillShape, transparent, brickfy, options.isBrick, paperMod, options.roundTile)
    if (options.addWatermark) this.addWatermark(canvas, ctx)
    if (options.fn) options.fn(obj, canvas)
  },
  // 获取对象渲染后图片 Base64
  getObjImage: function(sceneIdx, objIdx, parentObjIdx = -1, options = {}) {
    this.drawObj(this.canvasElm, sceneIdx, objIdx, parentObjIdx, options)
    return this.getBase64()
  },
  // 获取文件名
  getFilename: function(name, idx, options = {}) {
    const fillShape = options.fillShape || this.file.fillShape
    let brickfy
    if (typeof options.brickfy !== 'undefined') {
      brickfy = options.brickfy
    } else {
      brickfy = this.file.brickfy
    }
    // workTypeid = 2 为拼图作品类型
    if (options.workTypeid === 2) brickfy = true
    name = name || '未命名'
    if (options.isOriginImage) {
      name = name + '-原图'
    } else {
      if (options.gridSize === 1 || fillShape === '' || fillShape === 'none' || fillShape === 'default') {
        name = name + '-像素画'
      } else {
        name = name + '-' + (brickfy ? '拼图' : '百格画')
      }
    }
    if (typeof idx !== 'undefined') name = name + '_' + idx
    options.formate = options.formate === 'assets' ? 'png' : options.formate
    const formate = options.formate || 'png'
    return name + '_' + utils.date('str') + '.' + formate
  },
  // 导出图片
  exportImage(base64, name, type = 'assets', options = {}, cb) {
    const params = [base64, this.getFilename(name, 0, options), 'image/png']
    if (options.download) download(...params)
    cb && cb([params], type)
  },
  // 导出场景
  exportScene: function(sceneIdx, options = {}, cb) {
    if (options.formate === 'jpg') options.baseBgColor = '#FFFFFF'
    // options.addWatermark = true
    this.drawScene(this.canvasElm, sceneIdx, options)
    options.formate = options.formate || 'png'
    options.mime = options.mime || 'image/png'
    const filename = this.getFilename(this.file.name, this.file.canvas.scenes.length > 1 ? sceneIdx : '', options)
    if (options.formate === 'jpg') {
      options.mime = 'image/jpeg'
    } else if (options.formate === 'svg') {
      options.mime = 'image/svg'
    } else if (options.formate === 'webp') {
      options.mime = 'image/webp'
    } else if (options.formate === 'bmp') {
      options.mime = 'image/bmp'
    } else if (options.formate === 'tiff') {
      options.mime = 'image/tiff'
    // } else if (options.formate === 'pdf') {
    //   this.exportPdf(this.canvasElm, filename, options, cb)
    //   return
    // } else if (options.formate === 'ico') {
    //   this.exportIco(this.canvasElm, filename, options, cb)
    //   return
    }
    const base64 = this.canvasElm.toDataURL(options.mime)
    const params = [base64, filename, options.mime]
    if (options.download) download(...params)
    cb && cb([params], options.formate)
  },
  // 导出对象
  exportObj: function(sceneIdx, objIdx, parentObjIdx = -1, options = {}, cb) {
    if (options.formate === 'originImage') {
      this.exportOriginImage(sceneIdx, objIdx, parentObjIdx, options, cb)
      return
    }
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (options.formate === 'jpg') options.baseBgColor = '#FFFFFF'
    // options.addWatermark = true
    this.drawObj(this.canvasElm, sceneIdx, objIdx, parentObjIdx, options)
    options.formate = options.formate || 'png'
    options.mime = options.mime || 'image/png'
    const filename = this.getFilename(obj.name, '', options)
    if (options.formate === 'jpg') {
      options.mime = 'image/jpeg'
    } else if (options.formate === 'svg') {
      options.mime = 'image/svg'
    } else if (options.formate === 'webp') {
      options.mime = 'image/webp'
    } else if (options.formate === 'bmp') {
      options.mime = 'image/bmp'
    } else if (options.formate === 'tiff') {
      options.mime = 'image/tiff'
    // } else if (options.formate === 'pdf') {
    //   this.exportPdf(this.canvasElm, filename, options, cb)
    //   return
    // } else if (options.formate === 'ico') {
    //   this.exportIco(this.canvasElm, filename, options, cb)
    //   return
    }
    const base64 = this.canvasElm.toDataURL(options.mime)
    const params = [base64, filename, options.mime]
    if (options.download) download(...params)
    cb && cb([params], options.formate)
  },
  // 导出原图
  exportOriginImage(sceneIdx, objIdx, parentObjIdx = -1, options = {}, cb) {
    // 图片预览模式
    options.transparent = false
    options.formate = 'png'
    options.isOriginImage = true
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    let base64 = obj.originImage
    if (!base64) {
      base64 = this.getObjImage(this.sceneIdx, objIdx, parentObjIdx, options)
    }
    const filename = this.getFilename(obj.name, '', options)
    const params = [base64, filename, 'image/png']
    if (options.download) download(...params)
    cb && cb([params], 'originImage')
  },
  // 导出ico文件
  // exportIco: function(canvas, filename, options = {}, cb) {
  //   // eslint-disable-next-line
  //   const ico = new Favicon.Ico(canvas)
  //   const blob = ico.generate([16, 32, 48])
  //   const params = [blob, filename, 'image/x-icon']
  //   if (options.download) download(...params)
  //   cb && cb([params], 'ico')
  // },
  // 导出pdf文件
  exportPdf: function(canvas, filename, options = {}, cb) {
    const a4Width = 595.28 - 20
    const a4Height = 841.89 - 20
    let canvasWidth = canvas.width
    let canvasHeight = canvas.height
    if (canvas.width / canvas.height > a4Width / a4Height) {
      if (canvas.width > a4Width) {
        canvasWidth = a4Width
        canvasHeight = canvasWidth * canvas.height / canvas.width
      }
    } else {
      if (canvas.height > a4Height) {
        canvasHeight = a4Height
        canvasWidth = canvasHeight * canvas.width / canvas.height
      }
    }
    // eslint-disable-next-line
    const pdf = new jsPDF('p', 'pt', 'a4')
    pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 10, 10, canvasWidth, canvasHeight)
    // pdf.save(filename)
    const params = [pdf.output('blob'), filename, 'application/pdf']
    if (options.download) download(...params)
    cb && cb([params], 'pdf')
  },
  // 导出gridy文件
  exportGridy: function(file, options = {}, cb) {
    file = file || this.file
    const zip = new JSZip()
    const content = JSON.stringify(file)
    const filename = (file.name || '未命名') + '_' + utils.date('str') + '.gridy'
    zip.file(filename, content)
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const params = [blob, filename, 'application/zip']
      if (options.download) download(...params)
      cb && cb([params], 'gridy')
    }, (e) => {
      cb && cb('导出失败')
    })
  },
  // 导出GIF文件
  exportGif: function(file, options = {}, cb) {
    file = file || this.file
    if (!file.canvas || !Array.isArray(file.canvas.scenes) || !file.canvas.scenes.length) {
      return
    }
    const gridSize = options.gridSize || file.gridSize || 1
    const opts = {
      repeat: 0,
      quality: 10,
      workers: 2,
      width: file.canvas.cols * gridSize,
      height: file.canvas.rows * gridSize
    }
    const filename = (file.name || '未命名') + '_' + utils.date('str') + '.gif'
    // eslint-disable-next-line
    const gif = new GIF(opts)
    Object.keys(file.canvas.scenes).map((idx) => {
      this.getSceneImage(idx, options)
      gif.addFrame(this.ctx, { copy: true, delay: 1000 / file.rate })
    })
    gif.on('finished', function(blob) {
      const mime = 'image/gif'
      if (options.download) download(blob, filename, mime)
      cb && cb([[window.URL.createObjectURL(utils.blobToFile(blob, filename, mime)), filename, mime]], 'gif')
    })
    gif.render()
  },
  // 生成海报
  createPOP(sceneIdx, options = {}, cb) {
    const canvasPop = document.createElement('canvas')
    const ctx = canvasPop.getContext('2d')
    ctx.imageSmoothingEnabled = false
    canvasPop.width = 1032
    canvasPop.height = 1834
    let width
    let height
    let fixX = 0
    let fixY = 0
    const calcSize = (imgWidth, imgHeight) => {
      if (imgWidth / imgHeight > canvasPop.width / canvasPop.height) {
        width = 800
        height = imgHeight * width / imgWidth
        fixY = (1080 - height) / 2
      } else {
        height = 1080
        width = imgWidth * height / imgHeight
        fixX = (800 - width) / 2
      }
    }
    const drawPop = (popImage) => {
      const createNow = () => {
        // 绘制白色背景
        ctx.fillStyle = 'white'
        ctx.fillRect(116, 116 + fixY, 800, height + 140)
        // 绘制矩形框
        if (ctx.roundRect) {
          ctx.strokeStyle = 'white'
          ctx.beginPath()
          ctx.lineWidth = 32
          ctx.roundRect(101, 101 + fixY, 800 + 31, height + 31 + 130, 1)
          ctx.stroke()
        } else {
          ctx.fillStyle = 'white'
          ctx.fillRect(101, 101 + fixY, 800 + 31, height + 31 + 130)
        }
        ctx.drawImage(popImage, 116 + fixX, 116 + fixY, width, height)
        // 绘制文本
        const txt = {
          content: options.info || '',
          fontColor: '#000000',
          fontWeight: 'normal',
          fontSize: 24,
          fontFamily: 'arial',
          textAlign: 'left',
          textBaseline: 'top'
        }
        // 写入文本
        ctx.font = txt.fontWeight + ' ' + txt.fontSize + 'px ' + txt.fontFamily
        ctx.textAlign = txt.textAlign
        ctx.textBaseline = txt.textBaseline
        ctx.fillStyle = txt.fontColor
        // 写创作者
        if (options.nickname) ctx.fillText('@' + options.nickname, 116 + (options.avatar ? 100 : 0), 116 + height + 28 + fixY)
        let i
        let x = 116 + (options.avatar ? 100 : 0)
        let y = 101 + height + 31 + 56 + fixY
        let row = 1
        const words = txt.content.split('')
        for (i = 0; i < words.length; i++) {
          if (row >= 2 && x > width + 64) {
            ctx.fillText('...', x, y)
            break
          } else {
            ctx.fillText(words[i], x, y)
          }
          x = x + ctx.measureText(words[i]).width
          if (x > 116 + width) {
            row++
            y = y + 32
            x = 116 + (options.avatar ? 100 : 0)
          }
        }
        ctx.fillStyle = '#FFFFFF'
        ctx.fillText(options.qrcode ? '↑ 请扫码体验海量像素画吧 ↑' : '↑ 访问百格画体验海量像素画吧 ↑', 362, height + 31 + 180 + 110 + 120 + 220 + fixY - (options.qrcode ? 0 : 100))
        const callback = () => {
          const params = [this.getBase64(canvasPop), this.file.name + '_海报.png', 'image/png']
          if (options.download) download(...params)
          cb && cb([params], 'pop')
        }
        // 绘制头像
        if (options.avatar) {
          this.loadRemoteImage(options.avatar, (base64) => {
            if (base64) {
              const avatarImg = new Image()
              avatarImg.src = base64
              avatarImg.onload = () => {
                ctx.drawImage(avatarImg, 116, height + 116 + 16 + fixY, 90, 90)
                callback()
              }
            } else {
              callback()
            }
          })
        } else {
          callback()
        }
      }
      // 绘制背景 popBg
      const bgImg = new Image()
      bgImg.src = popBg
      bgImg.onload = () => {
        ctx.drawImage(bgImg, 0, 0, canvasPop.width, canvasPop.height)
        // 绘制Logo
        const logoImg = new Image()
        logoImg.src = logoImage
        logoImg.onload = () => {
          ctx.drawImage(logoImg, 370, height + 31 + 180 + 110 + fixY, logoImg.width, logoImg.height)
          // 绘制二维码
          if (options.qrcode) {
            const qrImg = new Image()
            qrImg.src = options.qrcode
            qrImg.onload = () => {
              // 绘制矩形框
              if (ctx.roundRect) {
                ctx.strokeStyle = 'white'
                ctx.beginPath()
                ctx.lineWidth = 20
                ctx.roundRect(426, height + 31 + 180 + 110 + 120 + fixY, 180, 180, 1)
                ctx.stroke()
              } else {
                ctx.fillStyle = 'white'
                ctx.fillRect(426, height + 31 + 180 + 110 + 120 + fixY, 180, 180)
              }
              ctx.drawImage(qrImg, 426, height + 31 + 180 + 110 + 120 + fixY, 180, 180)
              createNow()
            }
          } else {
            createNow()
          }
        }
      }
    }
    if (options.paletteId === 'originImage') {
      const obj = this.getObj(sceneIdx, 0, -1)
      if (obj.originImage) {
        const originImg = new Image()
        originImg.src = obj.originImage
        originImg.onload = () => {
          calcSize(originImg.width, originImg.height)
          drawPop(originImg)
        }
      }
    } else {
      const canvas = this.canvas()
      const gridSize = Math.ceil(800 / canvas.cols)
      options.gridSize = gridSize
      delete options.paletteId
      options.brickfy = false
      options.roundTile = false
      options.fillShape = ''
      this.canvasElm.width = canvas.cols * gridSize
      this.canvasElm.height = canvas.rows * gridSize
      calcSize(this.canvasElm.width, this.canvasElm.height)
      this.drawScene(this.canvasElm, sceneIdx, options)
      drawPop(this.canvasElm)
    }
  },
  // 加水印
  addWatermark(canvas, ctx, conf = {}) {
    if (canvas && ctx) {
      // 绘制文本
      const txt = {
        content: conf.content || 'Gridy.Art',
        fontColor: conf.fontColor || '#000000',
        fontWeight: 'normal',
        fontSize: conf.fontSize || 10,
        fontFamily: 'arial',
        textAlign: 'left',
        textBaseline: 'top'
      }
      let factor = canvas.width / (txt.fontSize * 32)
      if (factor < 1) return
      factor = Math.round(factor)
      factor = Math.min(factor, 12)
      factor = Math.max(factor, 1)
      const fontSize = ((factor - 1) / 2 + 1) * txt.fontSize
      // ctx.globalAlpha = 0.8
      // 写入文本
      ctx.font = txt.fontWeight + ' ' + (fontSize * 1.2) + 'px ' + txt.fontFamily
      ctx.textAlign = txt.textAlign
      ctx.textBaseline = txt.textBaseline
      ctx.strokeStyle = '#ffffff'
      ctx.shadowColor = '#ffffff'
      ctx.shadowBlur = 2
      ctx.fillStyle = txt.fontColor
      ctx.strokeText('©', canvas.width - fontSize * 6, fontSize * 0.65)
      ctx.fillText('©', canvas.width - fontSize * 6, fontSize * 0.65)
      // 写入文本
      ctx.font = txt.fontWeight + ' ' + fontSize + 'px ' + txt.fontFamily
      ctx.strokeText(txt.content, canvas.width - fontSize * 6 + fontSize * 1.2, fontSize * 0.7)
      ctx.fillText(txt.content, canvas.width - fontSize * 6 + fontSize * 1.2, fontSize * 0.7)
    }
  },
  getMatchColor: function(colors, matchMap, paletteColors, color, limitColorNums = 0) {
    const matchNextColor = (tmpColor) => {
      let tmpMatchColor = tmpColor
      if (matchMap[tmpColor]) {
        tmpMatchColor = matchMap[tmpColor]
        // if (limitColorNums && colors[tmpMatchColor] >= limitColorNums) {
        //   paletteColors = utils.removeValue(paletteColors, tmpMatchColor)
        //   tmpMatchColor = utils.bestMatch(paletteColors, tmpMatchColor)
        //   matchMap[tmpColor] = tmpMatchColor
        // }
      } else {
        if (paletteColors.indexOf(tmpColor) >= 0) {
          tmpMatchColor = tmpColor
        } else {
          tmpMatchColor = utils.bestMatch(paletteColors, tmpColor)
        }
        matchMap[tmpColor] = tmpMatchColor
      }
      return tmpMatchColor
    }
    const matchColor = matchNextColor(color)
    if (colors[matchColor]) {
      colors[matchColor]++
    } else {
      colors[matchColor] = 1
    }
    return matchColor
  },
  // 应用调色板调色
  setPalette: function(originData, paletteArr, paletteId = '', denoiseFactor = 0, limitColorNums = 0, darkMatch = true) {
    if (!originData || !originData.length || !paletteArr || !paletteArr.length) return {}
    let colors = {}
    let data = []
    let paletteColors
    if ((paletteId + '').substr(0, 7) === 'brickfy' && darkMatch) {
      paletteColors = utils.getDarkenedColors(paletteArr)
    } else {
      paletteColors = paletteArr
    }
    // console.error('setPalette.paletteId', paletteId, paletteArr)
    const matchMap = {}
    for (const i in originData) {
      if (originData[i]) {
        data[i] = this.getMatchColor(colors, matchMap, paletteColors, originData[i], limitColorNums)
      } else {
        data[i] = ''
      }
    }
    // 降噪
    if (denoiseFactor) {
      const paletteArr = this.getDenoisePalette(colors, denoiseFactor)
      const paletteObj = {}
      if (paletteArr && paletteArr.length) {
        for (var i in paletteArr) {
          paletteArr[i] = paletteArr[i].toUpperCase()
          paletteObj[paletteArr[i]] = true
        }
      }
      const newData = this.setPalette(data, paletteArr, '', 0, 0, darkMatch)
      if (newData.data) data = newData.data
      if (newData.colors) colors = newData.colors
    }
    return { data: data, colors: colors }
  },
  // 设置对象调色板
  setObjPalette: function(sceneIdx, objIdx, parentObjIdx = -1, paletteId = '', denoiseFactor = 0, limitColorNums = 0) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.setObjPalette(sceneIdx, idx, objIdx, paletteId)
      }
      return
    }
    const palettes = conf.palette.data
    let paletteArr
    if (['128', '64', '32', '16', '8', '4', '2'].indexOf(paletteId.toString()) >= 0) {
      paletteArr = this.getObjPalette(sceneIdx, objIdx, parentObjIdx, parseInt(paletteId))
    } else if (palettes[paletteId]) {
      paletteArr = palettes[paletteId]
    } else {
      paletteId = ''
      paletteArr = ''
    }
    obj.paletteId = paletteId
    const paletteObj = {}
    if (paletteArr && paletteArr.length) {
      for (var i in paletteArr) {
        paletteArr[i] = paletteArr[i].toUpperCase()
        paletteObj[paletteArr[i]] = true
      }
    }
    obj.palette = paletteObj
    const newData = this.setPalette(obj.data, paletteArr, paletteId, denoiseFactor, limitColorNums)
    if (newData.data) obj.data = newData.data
    if (newData.colors) obj.colors = newData.colors
  },
  // 获取量化裁剪调色板
  getQuantizePalette: function(palette, maxColors = 64) {
    if (!palette || !palette.length || !maxColors) return ''
    const rgbColors = []
    for (const i in palette) {
      rgbColors.push(utils.hex2rgbArr(palette[i]))
    }
    const colorMap = quantize(rgbColors, maxColors > 4 ? maxColors + 1 : maxColors)
    const colors = colorMap.palette()
    const paletteArr = []
    for (const i in colors) {
      paletteArr.push(utils.rgb2hex(colors[i]))
    }
    return paletteArr
  },
  // 获取对象调色板
  getObjPalette: function(sceneIdx, objIdx, parentObjIdx = -1, maxColors = 64) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (!obj.colors) return
    return this.getQuantizePalette(Object.keys(obj.colors), maxColors)
  },
  // 计算对象颜色表
  calcObjColors: function(sceneIdx, objIdx, parentObjIdx = -1, defaultColor = '', colorfyId = '') {
    if (defaultColor && colorfyId) {
      const colorfyPaletteObj = this.getColorfyPaletteObj(colorfyId)
      defaultColor = colorfyPaletteObj[defaultColor] || defaultColor
    }
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    const colors = {}
    for (const i in obj.data) {
      obj.data[i] = obj.data[i] || defaultColor
      if (obj.data[i]) {
        if (colors[obj.data[i]]) {
          colors[obj.data[i]] = colors[obj.data[i]] + 1
        } else {
          colors[obj.data[i]] = 1
        }
      }
    }
    obj.colors = colors
    return colors
  },
  // 计算对象颜色表及颜色评分
  calcObjColorsAndScore: function(sceneIdx, objIdx, parentObjIdx = -1, limitColorNums = 0) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    const colors = {}
    for (const i in obj.data) {
      if (obj.data[i]) {
        if (colors[obj.data[i]]) {
          colors[obj.data[i]] = colors[obj.data[i]] + 1
        } else {
          colors[obj.data[i]] = 1
        }
      }
    }
    let recommend = true
    if (limitColorNums) {
      for (const k in colors) {
        const fixNums = conf.limitColorsConf.fixColors.indexOf(k) > -1 ? conf.limitColorsConf.fixNums : 0
        // console.error('fixNums', k, colors[k], limitColorNums - fixNums, limitColorNums, fixNums)
        if (recommend && colors[k] > limitColorNums - fixNums) {
          recommend = false
          break
        }
      }
    }
    const score = recommend ? 100 : 0
    obj.colors = colors
    // console.error('xxx', { colors: colors, score: score, recommend: recommend })
    return { colors: colors, score: score, recommend: recommend }
  },
  // 计算对象数据（应用调色板，反色处理，乐高化，降噪，合并等） ？？？？
  calcObjData: function(sceneIdx, objIdx, parentObjIdx = -1) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    const colors = {}
    const palColors = obj.palette
    const brickColors = utils.getDarkenedColorsObj(conf.brickDt)
    // const brickColors = Object.keys(conf.darkenedBrickDt)
    const data = []
    for (const i in obj.data) {
      data[i] = obj.data[i]
      if (data[i]) {
        // 使用调色板 获取调色板上色差最小的颜色
        if (palColors.length) {
          data[i] = utils.bestMatch(palColors, data[i])
        }
        // 是否反色处理（优先级高于乐高化处理）
        if (obj.anti) {
          data[i] = utils.getAntiHex(data[i])
        }
        // 乐高化处理
        if (obj.brickfy) {
          // 获取乐高调色板上色差最小的颜色
          data[i] = utils.bestMatch(brickColors, data[i])
        }
        data[i] = data[i].toUpperCase()
        if (colors[data[i]]) {
          colors[data[i]] = colors[data[i]] + 1
        } else {
          colors[data[i]] = 1
        }
      }
    }
    obj.colors = colors
    obj.data = data
  },
  // 获取降噪后的调色板
  getDenoisePalette: function(colors, factor) {
    if (!colors || !factor) return []
    const paletteArr = []
    for (const color in colors) {
      if (colors[color] && colors[color] > factor) {
        paletteArr.push(color)
      }
    }
    return paletteArr
  },
  // 降噪（去掉杂色）
  objDenoise: function(sceneIdx, objIdx, parentObjIdx = -1, factor) {
    if (!factor) return
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.objDenoise(sceneIdx, idx, objIdx, factor)
      }
      return
    }
    const paletteArr = this.getDenoisePalette(obj.colors, factor)
    const paletteObj = {}
    if (paletteArr && paletteArr.length) {
      for (var i in paletteArr) {
        paletteArr[i] = paletteArr[i].toUpperCase()
        paletteObj[paletteArr[i]] = true
      }
    }
    obj.palette = paletteObj
    const newData = this.setPalette(obj.data, paletteArr)
    if (newData.data) obj.data = newData.data
    if (newData.colors) obj.colors = newData.colors
  },
  // 调整色相/饱和度
  objColorFilter: function(sceneIdx, objIdx, parentObjIdx = -1, factor) {
    if (!factor) return
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.objColorFilter(sceneIdx, idx, objIdx, factor)
      }
      return
    }
    const len = obj.cols * obj.rows
    let pixelArr = []
    for (let i = 0; i < len; i++) {
      let rgbArr = [0, 0, 0, 0]
      if (obj.data[i]) {
        rgbArr = utils.hex2rgbArr(obj.data[i])
        rgbArr.push(1)
      }
      pixelArr.push(...rgbArr)
    }
    pixelArr = this.applyColorFilter(pixelArr, factor)
    const newObjData = []
    const newObjColors = {}
    for (let i = 0; i < len; i++) {
      if (!pixelArr[i * 4 + 3]) {
        newObjData.push('')
      } else {
        const color = utils.rgb2hex([pixelArr[i * 4], pixelArr[i * 4 + 1], pixelArr[i * 4 + 2]])
        newObjData.push(color)
        if (newObjColors[color]) {
          newObjColors[color]++
        } else {
          newObjColors[color] = 1
        }
      }
    }
    obj.data = newObjData
    obj.colors = newObjColors
  },
  // 获取相似色合并后的调色板
  getMergedPalette: function(colors, factor) {
    if (!colors || !factor) return []
    // 最少保留8个颜色
    const minColorNums = 8
    const colorsArr = Object.keys(colors)
    let totalColors = colorsArr.length
    if (totalColors < minColorNums) return []
    // 合并相似色
    colorsArr.map((a) => {
      if (colors[a]) {
        colorsArr.map((c) => {
          if (a !== c && totalColors > minColorNums) {
            var d = utils.colorDistance(a, c)
            if (d <= factor) {
              totalColors--
              delete colors[c]
            }
          }
        })
      }
    })
    return Object.keys(colors)
  },
  // 合并相似色
  mergeObjColor: function(sceneIdx, objIdx, parentObjIdx = -1, factor) {
    if (!factor) return
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.mergeObjColor(sceneIdx, idx, objIdx, factor)
      }
      return
    }
    const paletteArr = this.getMergedPalette(obj.colors, factor)
    const paletteObj = {}
    if (paletteArr && paletteArr.length) {
      for (var i in paletteArr) {
        paletteArr[i] = paletteArr[i].toUpperCase()
        paletteObj[paletteArr[i]] = true
      }
      obj.palette = paletteObj
      const newData = this.setPalette(obj.data, paletteArr)
      if (newData.data) obj.data = newData.data
      if (newData.colors) obj.colors = newData.colors
    }
  },
  // 量化过滤
  quantizeObjColor: function(sceneIdx, objIdx, parentObjIdx = -1, factor) {
    if (!factor) return
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.quantizeObjColor(sceneIdx, idx, objIdx, factor)
      }
      return
    }
    const colors = Object.keys(obj.colors)
    const quantizeFactor = Math.max(colors.length * (100 - factor) / 100, 8)
    const paletteArr = this.getQuantizePalette(colors, quantizeFactor)
    const paletteObj = {}
    if (paletteArr && paletteArr.length) {
      for (var i in paletteArr) {
        paletteArr[i] = paletteArr[i].toUpperCase()
        paletteObj[paletteArr[i]] = true
      }
    }
    obj.palette = paletteObj
    const newData = this.setPalette(obj.data, paletteArr)
    if (newData.data) obj.data = newData.data
    if (newData.colors) obj.colors = newData.colors
  },
  // 对象/调色板颜色开关 ???
  toggleObjColor: function(sceneIdx, objIdx, parentObjIdx = -1, color) {
    if (!color) return
    color = color.toUpperCase()
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.toggleObjColor(sceneIdx, idx, objIdx, color)
      }
      return
    }
    if (obj.palette) obj.palette[color] = !obj.palette[color]
    const paletteArr = []
    for (const c in obj.palette) {
      if (obj.palette[c]) {
        paletteArr.push(c.toUpperCase())
      }
    }
    const newData = this.setPalette(obj.data, paletteArr)
    if (newData.data) obj.data = newData.data
    if (newData.colors) obj.colors = newData.colors
  },
  // 删除对象/调色板颜色
  deleteObjColor: function(sceneIdx, objIdx, parentObjIdx = -1, color) {
    this.changeObjColor(sceneIdx, objIdx, parentObjIdx, color, '')
  },
  // 更换对象/调色板颜色
  changeObjColor: function(sceneIdx, objIdx, parentObjIdx = -1, color, newColor) {
    if (!color || color === newColor) return
    if (color) color = color.toUpperCase()
    if (newColor) newColor = newColor.toUpperCase()
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.changeObjColor(sceneIdx, idx, objIdx, color, newColor)
      }
      return
    }
    if (newColor) {
      // 替换颜色
      for (const i in obj.data) {
        if (obj.data[i] === color) {
          obj.data[i] = newColor
        }
      }
      const colorCount = obj.colors[color]
      delete obj.colors[color]
      if (colorCount) obj.colors[newColor] = colorCount
    } else {
      // 删除颜色
      const paletteArr = []
      for (const c in obj.colors) {
        if (obj.colors[c] && c.toUpperCase() !== color) {
          paletteArr.push(c)
        }
      }
      const newData = this.setPalette(obj.data, paletteArr)
      if (newData.data) obj.data = newData.data
      if (newData.colors) obj.colors = newData.colors
    }
  },
  setObjSize: function(sceneIdx, objIdx, cols, rows) {
    return
  },
  // 计算对象的包含容器尺寸
  calcBox: function(objs) {
    if (!objs && !objs.length) return
    if (objs.length === 1) {
      return { x: objs[0].x, y: objs[0].y, w: objs[0].cols, h: objs[0].rows }
    }
    const selectObjs = []
    let tl
    let rb
    let i
    for (i in objs) {
      if (!tl) {
        tl = [objs[i].x, objs[i].y]
      }
      if (!rb) {
        rb = [objs[i].x + objs[i].cols, objs[i].y + objs[i].rows]
      }
      selectObjs.push(i)
      tl[0] = objs[i].x < tl[0] ? objs[i].x : tl[0]
      tl[1] = objs[i].y < tl[1] ? objs[i].y : tl[1]
      rb[0] = (objs[i].x + objs[i].cols) > rb[0] ? (objs[i].x + objs[i].cols) : rb[0]
      rb[1] = (objs[i].y + objs[i].rows) > rb[1] ? (objs[i].y + objs[i].rows) : rb[1]
    }
    if (selectObjs.length) {
      return { objs: selectObjs, x: tl[0], y: tl[1], w: rb[0] - tl[0], h: rb[1] - tl[1] }
    }
  },
  // 自动符合画布
  autoCanvasSizeDEL: function(padding = false) {
    const curObjs = this.curObjs()
    const box = this.calcBox(curObjs)
    if (!box) {
      return
    }
    // 重新设置对象坐标
    for (const i in curObjs) {
      curObjs[i].x -= box.x
      curObjs[i].y -= box.y
      if (padding === true) {
        curObjs[i].x += 1
        curObjs[i].y += 1
      }
    }
    if (padding === true) {
      box.w += 2
      box.h += 2
    }
    this.setCanvasSize(box.w, box.h)
    return
  },
  autoCanvasSize: function(padding = false, brickfy = false) {
    const curObjs = this.curObjs()
    const box = this.calcBox(curObjs)
    if (!box) {
      return
    }
    let cols = box.w
    let rows = box.h
    if (brickfy) {
      cols = Math.ceil(box.w / 16) * 16
      rows = Math.ceil(box.h / 16) * 16
    }
    if (padding === true) {
      cols = cols + 2
      rows = rows + 2
    }
    // 重新设置对象坐标
    for (const i in curObjs) {
      curObjs[i].x -= box.x
      curObjs[i].y -= box.y
      curObjs[i].x += Math.round((cols - box.w) / 2)
      curObjs[i].y += Math.round((rows - box.h) / 2)
    }
    this.setCanvasSize(cols, rows)
    return
  },
  // 设置画布大小
  setCanvasSize: function(cols, rows) {
    cols = parseInt(cols)
    rows = parseInt(rows)
    cols = Math.max(cols, 8)
    cols = Math.min(cols, conf.state.maxCols)
    rows = Math.max(rows, 8)
    rows = Math.min(rows, conf.state.maxRows)
    const canvas = this.canvas()
    canvas.cols = cols
    canvas.rows = rows
    let aspectFactor = 'free'
    if (cols / rows === 1) {
      aspectFactor = '1-1'
    } else if (cols / rows === 5 / 4) {
      aspectFactor = '5-4'
    } else if (cols / rows === 4 / 5) {
      aspectFactor = '4-5'
    } else if (cols / rows === 2 / 3) {
      aspectFactor = '2-3'
    } else if (cols / rows === 3 / 2) {
      aspectFactor = '3-2'
    } else if (cols / rows === 9 / 16) {
      aspectFactor = '9-16'
    } else if (cols / rows === 16 / 9) {
      aspectFactor = '16-9'
    } else if (cols / rows === 3 / 4) {
      aspectFactor = '3-4'
    } else if (cols / rows === 4 / 3) {
      aspectFactor = '4-3'
    } else if (cols / rows === 1 / 2) {
      aspectFactor = '1-2'
    } else if (cols / rows === 2 / 1) {
      aspectFactor = '2-1'
    }
    canvas.aspectFactor = aspectFactor
  },
  selectObjs: function(sceneIdx, objIdxs) {
    return
  },
  unselectObjs: function(sceneIdx, objIdxs) {
    return
  },
  getSelectObjs: function() {
    return
  },
  showObj: function(sceneIdx, objIdx) {
    return
  },
  hideObj: function(sceneIdx, objIdx) {
    return
  },
  lockObj: function(sceneIdx, objIdx) {
    return
  },
  unlockObj: function(sceneIdx, objIdx) {
    return
  },
  // 对 selectObjIdxs 进行排序
  sortSelectObjIdxs(selectObjIdxs) {
    const arr = Object.values(selectObjIdxs)
    const sortIdx = function(a, b) {
      return a[0] - b[0]
    }
    const sortParentIdx = function(a, b) {
      return a[1] - b[1]
    }
    return arr.sort(sortIdx).sort(sortParentIdx)
  },
  del: function(sceneIdx, objIdx, parentObjIdx = -1, fake) {
    this.sceneIdx = sceneIdx
    this.objIdx = objIdx
    this.parentObjIdx = parentObjIdx
    const objs = this.subObjs() || this.curObjs() || []
    if (objs[objIdx] && !objs[objIdx].lock) {
      if (fake) {
        // 伪删除
        objs[objIdx].del = true
      } else {
        objs.splice(objIdx, 1)
      }
    }
  },
  // 删除对象
  delete: function(sceneIdx, selectObjIdxs) {
    this.sceneIdx = sceneIdx
    // 伪删除选中的对象
    Object.values(selectObjIdxs).map((arr) => {
      this.del(sceneIdx, arr[0], arr[1], true)
    })
    // 先获取未删除的对象
    const curObjs = this.curObjs()
    const objs = []
    Object.keys(curObjs).map((i) => {
      if (!curObjs[i].del) {
        objs.push(curObjs[i])
      }
    })
    // 剔除已删除的子对象
    let subObjs
    let delBlankGroup = false
    Object.keys(objs).map((i) => {
      if (objs[i].type === 'group') {
        subObjs = []
        Object.keys(objs[i].objs).map((j) => {
          if (!objs[i].objs[j].del) {
            subObjs.push(utils.deepClone(objs[i].objs[j]))
          }
        })
        if (subObjs.length === 0) {
          objs[i].del = true
          delBlankGroup = true
        } else if (subObjs.length === 1) {
          // 只剩下一个子对象，则取消分组
          subObjs[0].x = subObjs[0].x + objs[i].x
          subObjs[0].y = subObjs[0].y + objs[i].y
          objs[i] = subObjs[0]
        } else {
          objs[i].name = '组合:' + subObjs.length + '对象'
          objs[i].objs = subObjs
        }
      }
    })
    if (delBlankGroup) {
      Object.keys(objs).map((i) => {
        objs.splice(i, 1)
      })
    }
    this.curScene().objs = objs
  },
  // 黏贴数据（合并）
  parseData(box, x, y, cols, rows, data) {
    let c
    let r
    for (c = 0; c < cols; c++) {
      for (r = 0; r < rows; r++) {
        box.data[(y - box.y + r) * box.w + x - box.x + c] = data[r * cols + c] || box.data[(y - box.y + r) * box.w + x - box.x + c] || ''
      }
    }
  },
  // 计算合并后对象数据
  calcMergeData(box, objs) {
    // box.data = []
    // const l = box.x * box.y
    // let i
    // for (i = 0; i < l; i++) {
    //   box.data.push('')
    // }
    // box.data = new Array(box.x * box.y).fill('')
    box.data = new Array(box.w * box.h).fill('')
    let x
    let y
    objs = objs || this.curObjs()
    let subObjs
    Object.values(box.objs).map((idx) => {
      if (objs[idx].type === 'group' && objs[idx].objs && objs[idx].objs.length) {
        // 合并组合里对象
        subObjs = objs[idx].objs.reverse()
        Object.keys(subObjs).map((i) => {
          x = subObjs[i].x + objs[idx].x
          y = subObjs[i].y + objs[idx].y
          this.parseData(box, x, y, subObjs[i].cols, subObjs[i].rows, subObjs[i].data)
        })
      } else {
        this.parseData(box, objs[idx].x, objs[idx].y, objs[idx].cols, objs[idx].rows, objs[idx].data)
      }
    })
    return box
  },
  // 合并文件
  merge: function(sceneIdx, selectObjIdxs, selectObjs) {
    this.sceneIdx = sceneIdx
    const box = this.calcBox(selectObjs)
    if (!box) return
    const paletteObj = {}
    let paletteId = ''
    let palette = ''
    let fillShape = ''
    Object.values(selectObjs).map((ob) => {
      if (ob.type === 'group') {
        Object.values(ob.objs).map((o) => {
          paletteObj[o.paletteId] = o.palette
          if (!fillShape) fillShape = o.fillShape
        })
      } else {
        paletteObj[ob.paletteId] = ob.palette
        if (!fillShape) fillShape = ob.fillShape
      }
    })
    const paletteIds = Object.keys(paletteObj)
    if (paletteIds.length === 1) {
      // 选中的对象都使用同一个调色板时，才设置具体调色板颜色
      paletteId = paletteIds[0]
      palette = paletteObj[paletteId]
    }
    this.calcMergeData(box, selectObjs)
    this.delete(sceneIdx, selectObjIdxs)
    const obj = utils.deepClone(conf.schema.obj)
    obj.id = utils.uuid()
    obj.name = '合并的对象'
    obj.fillShape = fillShape
    obj.x = box.x
    obj.y = box.y
    obj.cols = parseInt(box.w)
    obj.rows = parseInt(box.h)
    obj.data = box.data
    obj.paletteId = paletteId
    obj.palette = palette
    // 插入合并后对象
    this.curObjs().unshift(obj)
  },
  group: function(sceneIdx, selectObjIdxs, selectObjs) {
    this.sceneIdx = sceneIdx
    const box = this.calcBox(selectObjs)
    if (!box) return
    const paletteObj = {}
    const obj = utils.deepClone(conf.schema.obj)
    obj.id = utils.uuid()
    obj.x = box.x
    obj.y = box.y
    obj.cols = parseInt(box.w)
    obj.rows = parseInt(box.h)
    obj.type = 'group'
    obj.objs = []
    let subObj
    let objNums = 0
    // 将原对象放入组合中
    Object.keys(selectObjs).map((idx) => {
      if (selectObjs[idx].type === 'group') {
        // 放入缓存
        const cache = utils.deepClone(selectObjs[idx])
        // 将子对象放入画布
        Object.keys(cache.objs.reverse()).map((i) => {
          subObj = utils.deepClone(cache.objs[i])
          subObj.id = utils.uuid()
          subObj.x = subObj.x + cache.x - box.x
          subObj.y = subObj.y + cache.y - box.y
          paletteObj[subObj.paletteId] = subObj.palette
          obj.objs.unshift(subObj)
          objNums++
        })
      } else {
        subObj = utils.deepClone(selectObjs[idx])
        subObj.id = utils.uuid()
        subObj.x = subObj.x - box.x
        subObj.y = subObj.y - box.y
        paletteObj[subObj.paletteId] = subObj.palette
        obj.objs.unshift(subObj)
        objNums++
      }
    })
    // 插入组合后对象
    obj.name = '组合：' + objNums + '对象'
    const paletteIds = Object.keys(paletteObj)
    if (paletteIds.length === 1) {
      // 选中的对象都使用同一个调色板时，才设置具体调色板颜色
      obj.paletteId = paletteIds[0]
      obj.palette = paletteObj[obj.paletteId]
    }
    this.delete(sceneIdx, selectObjIdxs)
    this.addObj(sceneIdx, obj)
  },
  ungroup: function(sceneIdx, selectObjIdxs) {
    this.sceneIdx = sceneIdx
    const ungroupObjs = []
    const ungroupIdxs = {}
    let ungroupObjNums = 0
    let subObj
    let tmpObj
    const objs = this.curObjs()
    Object.values(this.sortSelectObjIdxs(selectObjIdxs).reverse()).map((arr) => {
      if (arr[1] === -1 && objs[arr[0]] && objs[arr[0]].type === 'group' && objs[arr[0]].objs.length) {
        ungroupIdxs[arr[0]] = true
        tmpObj = objs[arr[0]]
        Object.keys(tmpObj.objs.reverse()).map((idx) => {
          subObj = tmpObj.objs[idx]
          subObj.x = subObj.x + tmpObj.x
          subObj.y = subObj.y + tmpObj.y
          ungroupObjs.unshift(subObj)
          ungroupObjNums++
        })
      }
    })
    let i
    for (i in objs) {
      if (!ungroupIdxs[i]) {
        ungroupObjs.push(objs[i])
      }
    }
    this.curScene().objs = ungroupObjs
    return ungroupObjNums
  },
  // 设置层级
  setLayer: function(sceneIdx, objIdx, parentObjIdx = -1, act = 'top') {
    objIdx = parseInt(objIdx)
    this.sceneIdx = sceneIdx
    this.objIdx = objIdx
    this.parentObjIdx = parentObjIdx
    const objs = this.subObjs() || this.curObjs() || []
    const maxIdx = objs.length - 1
    if (maxIdx < 1) return
    let spliced
    if (act === 'top' && objIdx > 0) {
      spliced = utils.deepClone(objs.splice(objIdx, 1)[0])
      objs.unshift(spliced)
      objIdx = 0
    } else if (act === 'bottom' && objIdx < maxIdx) {
      spliced = utils.deepClone(objs.splice(objIdx, 1)[0])
      objs.push(spliced)
      objIdx = maxIdx
    } else if (act === '+' && objIdx > 0) {
      const newObjs = utils.deepClone(objs)
      newObjs[objIdx] = utils.deepClone(objs[objIdx - 1])
      newObjs[objIdx - 1] = utils.deepClone(objs[objIdx])
      if (parentObjIdx >= 0) {
        this.file.canvas.scenes[sceneIdx].objs[parentObjIdx].objs = newObjs
      } else {
        this.file.canvas.scenes[sceneIdx].objs = newObjs
      }
      objIdx = objIdx - 1
    } else if (act === '-' && objIdx < maxIdx) {
      const newObjs = utils.deepClone(objs)
      newObjs[objIdx] = utils.deepClone(objs[objIdx + 1])
      newObjs[objIdx + 1] = utils.deepClone(objs[objIdx])
      if (parentObjIdx >= 0) {
        this.file.canvas.scenes[sceneIdx].objs[parentObjIdx].objs = newObjs
      } else {
        this.file.canvas.scenes[sceneIdx].objs = newObjs
      }
      objIdx = objIdx + 1
    }
    return objIdx
  },
  // 对齐
  align: function(sceneIdx, selectObjIdxs, mod) {
    if (mod === 'spaceH' || mod === 'spaceV') {
      this.alignAverage(sceneIdx, selectObjIdxs, mod)
      return
    }
    this.sceneIdx = sceneIdx
    const objs = this.curObjs()
    if (!objs || !objs.length) return
    const boxObjs = []
    Object.values(selectObjIdxs).map((arr) => {
      if (arr[1] < 0 && objs[arr[0]]) {
        boxObjs.push(objs[arr[0]])
      }
    })
    const box = this.calcBox(boxObjs)
    if (!box) return
    Object.values(selectObjIdxs).map((arr) => {
      if (mod === 'left') {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].x = box.x - objs[arr[1]].x
        } else {
          objs[arr[0]].x = box.x
        }
      } else if (mod === 'right') {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].x = box.x + box.w - objs[arr[1]].objs[arr[0]].cols - objs[arr[1]].x
        } else {
          objs[arr[0]].x = box.x + box.w - objs[arr[0]].cols
        }
      } else if (mod === 'top') {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].y = box.y - objs[arr[1]].y
        } else {
          objs[arr[0]].y = box.y
        }
      } else if (mod === 'bottom') {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].y = box.y + box.h - objs[arr[1]].objs[arr[0]].rows - objs[arr[1]].y
        } else {
          objs[arr[0]].y = box.y + box.h - objs[arr[0]].rows
        }
      } else if (mod === 'middle') {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].x = box.x + box.w / 2 - objs[arr[1]].objs[arr[0]].cols / 2 - objs[arr[1]].x
        } else {
          objs[arr[0]].x = box.x + box.w / 2 - objs[arr[0]].cols / 2
        }
      } else if (mod === 'center') {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].y = box.y + box.h / 2 - objs[arr[1]].objs[arr[0]].rows / 2 - objs[arr[1]].y
        } else {
          objs[arr[0]].y = box.y + box.h / 2 - objs[arr[0]].rows / 2
        }
      }
    })
  },
  // 垂直或水平均分间距
  alignAverage: function(sceneIdx, selectObjIdxs, mod) {
    this.sceneIdx = sceneIdx
    const objs = this.curObjs()
    if (!objs || !objs.length) return
    const boxObjs = []
    Object.values(selectObjIdxs).map((arr) => {
      if (arr[1] < 0 && objs[arr[0]]) {
        boxObjs.push(objs[arr[0]])
      }
    })
    const box = this.calcBox(boxObjs)
    if (!box) return
    let objIdxs = []
    let x
    let y
    let objsCols = 0
    let objsRows = 0
    Object.values(selectObjIdxs).map((arr) => {
      if (arr[1] >= 0) {
        x = objs[arr[1]].objs[arr[0]].x + objs[arr[1]].x
        y = objs[arr[1]].objs[arr[0]].y + objs[arr[1]].y
        objsCols = objsCols + objs[arr[1]].objs[arr[0]].cols
        objsRows = objsRows + objs[arr[1]].objs[arr[0]].rows
      } else {
        x = objs[arr[0]].x
        y = objs[arr[0]].y
        objsCols = objsCols + objs[arr[0]].cols
        objsRows = objsRows + objs[arr[0]].rows
      }
      objIdxs.push([arr[0], arr[1], x - box.x, y - box.y])
    })
    const sortX = function(a, b) {
      return a[2] - b[2]
    }
    const sortY = function(a, b) {
      return a[3] - b[3]
    }
    let space = 0
    let nextX = 0
    let nextY = 0
    const objNums = objIdxs.length
    if (mod === 'spaceV') {
      objIdxs = objIdxs.sort(sortY)
      space = Math.floor((box.h - objsRows) / (objNums - 1))
      nextY = box.y
      Object.values(objIdxs).map((arr) => {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].y = nextY - objs[arr[1]].y
          nextY = nextY + objs[arr[1]].objs[arr[0]].rows + space
        } else {
          objs[arr[0]].y = nextY
          nextY = nextY + objs[arr[0]].rows + space
        }
      })
    } else {
      objIdxs = objIdxs.sort(sortX)
      space = Math.floor((box.w - objsCols) / (objNums - 1))
      nextX = box.x
      Object.values(objIdxs).map((arr) => {
        if (arr[1] >= 0) {
          objs[arr[1]].objs[arr[0]].x = nextX - objs[arr[1]].x
          nextX = nextX + objs[arr[1]].objs[arr[0]].cols + space
        } else {
          objs[arr[0]].x = nextX
          nextX = nextX + objs[arr[0]].cols + space
        }
      })
    }
  },
  deleteScenes: function(sceneIdxs) {
    return
  },
  deleteObjs: function(sceneIdx, objIdxs) {
    return
  },
  parseObj: function(sceneIdx, obj) {
    return
  },
  parseScene: function(sceneIdx, scene) {
    return
  },
  setFillShape: function(sceneIdx, objIdx, shapeId) {
    return
  },
  setGridSize: function(size) {
    return
  },
  antiColors: function(sceneIdx, objIdx) {
    return
  },
  // 旋转
  rotate: function(sceneIdx, objIdx, parentObjIdx = 1, clockwise, inverse) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.rotate(sceneIdx, idx, objIdx, clockwise, inverse)
      }
      return
    }
    if (clockwise) this.rotateW(obj)
    if (inverse) this.rotateI(obj)
  },
  // 顺时针旋转
  rotateW(obj) {
    if (!obj || !obj.data || !obj.cols || !obj.rows) return
    const tmpData = []
    const cols = obj.cols
    const rows = obj.rows
    for (const i in obj.data) {
      const y = Math.floor(i / cols)
      const x = Math.floor(i - y * cols)
      const idx = rows * (1 + x) - 1 - y
      tmpData[idx] = obj.data[i] || ''
    }
    obj.cols = rows
    obj.rows = cols
    obj.data = tmpData
  },
  // 逆时针旋转
  rotateI(obj) {
    if (!obj || !obj.data || !obj.cols || !obj.rows) return
    const tmpData = []
    const cols = obj.cols
    const rows = obj.rows
    for (const i in obj.data) {
      const y = Math.floor(i / cols)
      const x = Math.floor(i - y * cols)
      const idx = rows * (cols - 1 - x) + y
      tmpData[idx] = obj.data[i] || ''
    }
    obj.cols = rows
    obj.rows = cols
    obj.data = tmpData
  },
  // 翻转
  flip: function(sceneIdx, objIdx, parentObjIdx = 1, horizontal, vertical) {
    const obj = this.getObj(sceneIdx, objIdx, parentObjIdx)
    if (obj.lock) return
    if (obj.type === 'group' && obj.objs && obj.objs.length) {
      for (const idx in obj.objs) {
        this.flip(sceneIdx, idx, objIdx, horizontal, vertical)
      }
      return
    }
    if (horizontal) this.flipH(obj)
    if (vertical) this.flipH(obj)
  },
  // 垂直翻转
  flipV(obj) {
    if (!obj || !obj.data || !obj.cols || !obj.rows) return
    let tmpData = []
    const len = obj.cols * obj.rows
    for (let i = 0; i < len; i++) {
      const y = Math.floor(i / obj.cols)
      if (!tmpData[y]) {
        tmpData[y] = []
      }
      tmpData[y].push(obj.data[i] || '')
    }
    tmpData = tmpData.reverse()
    let newData = []
    for (const i in tmpData) {
      newData = newData.concat(tmpData[i])
    }
    obj.data = newData
  },
  // 水平翻转
  flipH(obj) {
    if (!obj || !obj.data || !obj.cols || !obj.rows) return
    const tmpData = []
    const len = obj.cols * obj.rows
    for (let i = 0; i < len; i++) {
      const y = Math.floor(i / obj.cols)
      if (!tmpData[y]) {
        tmpData[y] = []
      }
      tmpData[y].unshift(obj.data[i] || '')
    }
    let newData = []
    for (const i in tmpData) {
      newData = newData.concat(tmpData[i])
    }
    obj.data = newData
  },
  clear: function(sceneIdx, objIdx) {
    return
  },
  restore: function(sceneIdx, objIdx) {
    return
  },
  showBg: function() {
    return
  },
  hideBg: function() {
    return
  },
  showBorder: function() {
    return
  },
  hideBorder: function() {
    return
  },
  drawBg: function(canvas) {
    return
  },
  drawBorder: function(canvas) {
    return
  },
  getShareImage: function() {
    return
  },
  // 压缩图片
  compressImage: function(name, imgUrl, cb, maxWidth = 1024, maxHeight = 1024, type = 'base64') {
    const img = new Image()
    img.src = imgUrl
    img.onload = () => {
      const canvas = this.canvasElm
      const size = utils.calcImageSize(img.width, img.height, maxWidth, maxHeight)
      canvas.width = size.width
      canvas.height = size.height
      this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      let ext = 'png'
      if (name && name.toString().slice('.') >= 0) {
        ext = name.toString().slice(-3).toLowerCase() === 'png' ? 'png' : 'jpeg'
      }
      const mime = 'image/' + ext
      const base64 = canvas.toDataURL(mime)
      const quality = 0.85
      const objData = { name: name, width: canvas.width, height: canvas.height, ext: ext, mime: mime, quality: quality, base64: base64, md5: md5(base64) }
      if (type === 'blob' || type === 'file') {
        canvas.toBlob((blob) => {
          if (type === 'file') {
            objData.file = utils.blobToFile(blob, name, mime)
          } else {
            objData.blob = blob
          }
          cb && cb(objData)
        }, mime, quality)
      } else {
        cb && cb(objData)
      }
    }
    img.onerror = (err) => {
      console.error('err', err)
      cb && cb()
    }
  },
  getImagePalette: function(imageUrl) {
    return
  },
  // 绘制网格边框
  drawGridBorder: function(ctx, x, y, gridSize, bgColor, gridColor) {
    if (gridColor) {
      ctx.fillStyle = gridColor
      ctx.fillRect(x * gridSize, y * gridSize, gridSize + 1, gridSize + 1)
      if (bgColor) {
        ctx.fillStyle = bgColor
        ctx.fillRect(x * gridSize + 1, y * gridSize + 1, gridSize - 1, gridSize - 1)
      } else {
        ctx.clearRect(x * gridSize + 1, y * gridSize + 1, gridSize - 1, gridSize - 1)
      }
    }
  },
  // 绘制网格
  drawGrid: function(ctx, cols, rows, color, gridSize, fillShape = '', transparent = false, brickfy = false, paperMod = false, hide = false, frameConf = {}, roundTile = false, CLBrick = false, hideGridBorder = false) {
    // console.error(ctx, cols, rows, color, gridSize, fillShape, transparent, brickfy, paperMod, hide, frameConf, roundTile, CLBrick)
    if (fillShape === 'none' || fillShape === 'default' || gridSize <= 2) {
      fillShape = ''
    }
    if (roundTile) fillShape = 'circle'
    // 乐高化默认底板颜色 #FFFFFF
    // if ((brickfy || paperMod) && hide) {
    if (hide) {
      color = frameConf.base || '#FFFFFF'
    }
    if (color) {
      if (fillShape === 'circle') {
        this.drawCircle(ctx, cols * gridSize + gridSize / 2, rows * gridSize + gridSize / 2, gridSize / 2 - (paperMod ? 2 : 0), 0, 360, color, 'fill')
      } else if (fillShape === 'triangle') {
        this.drawTriangle(ctx, cols * gridSize + gridSize / 2 + 0.5, rows * gridSize + 1, cols * gridSize, (rows + 1) * gridSize, (cols + 1) * gridSize, (rows + 1) * gridSize, color, 'fill')
      } else if (fillShape === 'star') {
        this.drawStar(ctx, gridSize / 2 - 1, cols * gridSize + gridSize / 2 - 1, rows * gridSize + gridSize / 2 - 1, 0, color, 'fill')
      } else if (fillShape === 'square') {
        this.drawRect(ctx, cols * gridSize + 1, rows * gridSize + 1, gridSize - 1, gridSize - 1, color)
      } else {
        if (fillShape) {
          this.drawRect(ctx, cols * gridSize + 1, rows * gridSize + 1, gridSize - 1, gridSize - 1, color)
        } else {
          this.drawRect(ctx, cols * gridSize, rows * gridSize, gridSize, gridSize, color)
        }
      }
      if (hideGridBorder) this.drawRect(ctx, cols * gridSize, rows * gridSize, gridSize, gridSize, color)
      if (paperMod && !hide) this.drawBrickNum(ctx, cols, rows, gridSize, color, '', CLBrick)
    } else {
      if (transparent) ctx.clearRect(cols * gridSize, rows * gridSize, gridSize, gridSize)
    }
    if (brickfy) {
      if (roundTile) {
        ctx.drawImage(this.blendDiamondImg, 0, 0, this.blendDiamondImg.width, this.blendDiamondImg.height, cols * gridSize, rows * gridSize, gridSize, gridSize)
      } else {
        if (CLBrick) {
          const fixPos = gridSize * 0.1
          ctx.drawImage(this.CLBlendBrickImg, 0, 0, this.CLBlendBrickImg.width, this.CLBlendBrickImg.height, cols * gridSize - fixPos, rows * gridSize - fixPos, gridSize + 2 * fixPos, gridSize + 2 * fixPos)
        } else {
          ctx.drawImage(this.blendBrickImg, 0, 0, this.blendBrickImg.width, this.blendBrickImg.height, cols * gridSize, rows * gridSize, gridSize, gridSize)
        }
      }
    }
  },
  // 绘制拼图块编号
  drawBrickNum: function(ctx, cols, rows, gridSize, color, type, CLBrick = false) {
    color = color || '#000000'
    type = type || 'fill'
    const num = conf.brickMap[color.toUpperCase()] || conf.brickDt[color.toUpperCase()]
    if (!num) return
    const x = cols * gridSize + 1
    const y = rows * gridSize + 1
    type = type || 'fill'
    ctx.lineWidth = 2
    ctx.font = 'bold ' + Math.ceil(gridSize / 2.5) + 'px Arial'
    ctx.textAlign = 'left'
    ctx.textBaseline = 'top'
    ctx[type + 'Style'] = conf.whiteBrickNum.indexOf(num) >= 0 ? '#FFFFFF' : '#000000'
    const offsetX = Math.ceil((gridSize - 1 - ctx.measureText(num).width) / 2 + (CLBrick ? gridSize * -0.05 : 0))
    const offsetY = Math.ceil((gridSize - 2 - Math.ceil(gridSize / 2.5)) / 2)
    ctx[type + 'Text'](num, x + offsetX, y + offsetY)
  },
  // 绘制编号
  drawTxt: function(ctx, x, y, gridSize, color, num, type, fontSizeFactor = 2.5, shadowFactor = 0) {
    color = color || '#000000'
    type = type || 'fill'
    x = x + 1
    y = y + 1
    ctx.lineWidth = 2
    ctx.font = 'bold ' + Math.ceil(gridSize / fontSizeFactor) + 'px Arial'
    ctx.textAlign = 'left'
    ctx.textBaseline = 'top'
    if (shadowFactor) {
      ctx.shadowColor = '#EEEEEE'
      ctx.shadowBlur = shadowFactor
    }
    ctx[type + 'Style'] = '#000000'
    const offsetX = Math.ceil((gridSize - 1 - ctx.measureText(num).width) / 2)
    const offsetY = Math.ceil(((gridSize - 2 - Math.ceil(gridSize / fontSizeFactor)) / 2))
    ctx[type + 'Text'](num, x + offsetX, y + offsetY)
    if (shadowFactor) {
      ctx.shadowColor = ''
      ctx.shadowBlur = 0
    }
  },
  // 绘制位置编号
  drawLocalNum: function(ctx, cols, rows, gridSize, color, num, type, fontSizeFactor = 2.5) {
    this.drawTxt(ctx, cols * gridSize, rows * gridSize, gridSize, color, num, type, fontSizeFactor)
  },
  // 绘制直线
  drawLine(ctx, x1, y1, x2, y2, color, lineWidth) {
    color = color || '#000000'
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.lineWidth = lineWidth || 1
    ctx.strokeStyle = color
    ctx.closePath()
    ctx.stroke()
  },
  // 绘制矩形
  drawRect: function(ctx, x, y, width, height, color, type) {
    color = color || '#000000'
    type = type || 'fill'
    ctx[type + 'Style'] = color
    ctx[type + 'Rect'](x, y, width, height)
  },
  // 绘制椭圆 x,y 是中心点 a 是横半轴 b 是纵半轴
  drawEllipse: function(ctx, x, y, a, b, color, type) {
    color = color || '#000000'
    type = type || 'fill'
    const step = (a > b) ? 1 / a : 1 / b
    ctx.beginPath()
    ctx.moveTo(x + a, y)
    let i
    for (i = 0; i < 2 * Math.PI; i += step) {
      ctx.lineTo(x + a * Math.cos(i), y + b * Math.sin(i))
    }
    ctx[type + 'Style'] = color
    ctx.closePath()
    ctx[type]()
  },
  // 绘制圆形
  drawCircle: function(ctx, x, y, r, start, end, color, type) {
    color = color || '#000000'
    type = type || 'fill'
    const unit = Math.PI / 180
    ctx.beginPath()
    ctx.arc(x, y, r, start * unit, end * unit)
    ctx[type + 'Style'] = color
    ctx.closePath()
    ctx[type]()
  },
  // 绘制三角形
  drawTriangle: function(ctx, x1, y1, x2, y2, x3, y3, color, type) {
    color = color || '#000000'
    type = type || 'fill'
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.lineTo(x3, y3)
    ctx[type + 'Style'] = color
    ctx.closePath()
    ctx[type]()
  },
  // 绘制五角星
  drawStar: function(ctx, r, x, y, rot, color, type) {
    color = color || '#000000'
    type = type || 'fill'
    ctx.save()
    ctx.translate(x, y)
    ctx.rotate(rot / 180 * Math.PI)
    ctx.scale(r, r)
    ctx.beginPath()
    let i
    let x1
    let y1
    let x2
    let y2
    for (i = 0; i < 5; i++) {
      x1 = Math.cos((54 + i * 72) / 180 * Math.PI)
      y1 = Math.sin((54 + i * 72) / 180 * Math.PI)
      x2 = Math.cos((18 + i * 72) / 180 * Math.PI) * 0.5
      y2 = Math.sin((18 + i * 72) / 180 * Math.PI) * 0.5
      ctx.lineTo(x2, y2)
      ctx.lineTo(x1, y1)
    }
    ctx.closePath()
    ctx[type + 'Style'] = color
    ctx[type]()
    ctx.restore()
  },
  // 自适应对象尺寸
  autoSize: function(cols, rows) {
    if (typeof this.brickSizeId === 'string') {
      const sizeArr = this.brickSizeId.split('-')
      if (sizeArr.length === 2) {
        this.objCols = sizeArr[0]
        this.objRows = sizeArr[1]
        return
      }
    }
    if (cols) this.objCols = cols
    if (rows) this.objRows = rows
    const factors = [0.5, 1, 2]
    const limitCols = this.limitCols * (factors[this.brickSizeId] || 1)
    const limitRows = this.limitRows * (factors[this.brickSizeId] || 1)
    // 不知道干哈用 DEL ?
    // if (this.objCols === this.objRows) {
    //   this.objCols = Math.ceil(limitCols * 5 / 6)
    //   this.objRows = Math.ceil(limitRows * 5 / 6)
    // } else
    if ((this.objCols > limitCols && this.objRows > limitRows) || this.objCols > limitCols * 1.5 || this.objRows > limitRows * 1.5) {
      this.objCols = this.objCols / 2
      this.objRows = this.objRows / 2
      this.autoSize()
    } else {
      this.objCols = Math.ceil(this.objCols)
      this.objRows = Math.ceil(this.objRows)
    }
  },
  // 限制对象尺寸 del ??
  limitSize: function(width, height) {
    if (width > this.limitCols || height > this.limitRows) {
      if (width > height) {
        this.objCols = this.limitCols
        this.objRows = Math.round(this.objCols * height / width)
      } else {
        this.objRows = this.limitRows
        this.objCols = Math.round(this.objRows * width / height)
      }
    } else {
      this.objCols = width
      this.objRows = height
    }
  },
  destroy: function() {
    return null
  }
}
export default main
